@import './variables';

.erreur-commande {
  min-height: 60vh;
  display: flex;

  .flex {
    margin: auto;

    &> * {
      margin: $margin-large 0;

      .light {
        display: block;
        margin-top: 12px;
        color: $grey-button;
      }
    }
  }
}