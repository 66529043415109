@import './variables';

.nos-partenaires-details {
  .header {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      max-width: 60%;
      max-height: calc(100% - 120px);
    }
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .offers-basket {
    order: 1;
    position: relative;
    margin-top: -60px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .offers-items {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 100%;

      .languages {
        margin: -24px 20px 0;
        padding: 0;
        border-radius: $radius-default;
        box-shadow: 6px 6px 24px 0px rgba(0,0,0,0.75);
        font-weight: 800;
        font-size: $fs-xsmall;

        .inner {
          padding: 8px;
          margin: 0;
          width: 100%;
          height: 100%;
          border-radius: $radius-default;
          display: flex;
          flex-direction: row;

          text-align: center;

          .warning {
            display: flex;
            align-items: center;
            margin-right: $margin-medium;
          }

          div {
            img {
              margin: 0 2px;
            }
          }
        }
      }

      .flag {
        height: 14px;
      }
      .offer-item {
        margin-bottom:10px;
        text-align: center;
        color: $white;
        width: 100%;
        .name {
          font-size: $fs-huge;
          line-height: 3.5rem;
          padding-top: 3rem;
          padding-left: 10px;
          padding-right: 10px;
          word-break: break-word;
        }
        .duration {
          font-weight: 800;
          font-size: $fs-huge;
          line-height: 3.5rem;
          word-break: break-word;
          &.noPromo {
            margin-bottom: 3rem;
            padding-bottom: 3rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
        .promo {
          margin: 3rem 0 2rem;
          padding:5px;
          background-color: rgba(255,255,255,0.8);
          font-size: $fs-default;
          font-weight: 800;
          display: flex;
          align-items: center;
          justify-content: center;

          svg,
          span {
            margin: 0 $margin-small/2;
          }
        }
        .description {
          margin-bottom: 3rem;
          padding-bottom: 3rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-left: 2rem;
          padding-right: 2rem;

          ul {
            margin-left: 0;
            padding-left: 0;
            list-style: none;

            li {
              margin-bottom: 1.5rem;
            }
          }
        }
        .addToBasket {
          height: 5rem;
        }

        .btn {
          &.disabled {
            cursor: not-allowed;
            opacity: 0.6;
          }
        }
      }
      .price {
        font-size: $fs-huge;
        line-height: 3.5rem;
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .unavailable {
        display: block;
        margin: 3rem;
        position: relative;

        .notify-me {
          display: block;
          position: absolute;
          top: -1.6rem;
          right: -1.6rem;
          width: 24px;
          cursor: pointer;

          &.disabled {
            opacity: 0.3;
          }
        }
      }
      .btn {
        min-width: 60%;
      }
      .firstExpirationDate {
        font-size: small;
        margin: 12px 0;
      }

    }
    .basket-container {
      flex: 0 0 100%;
      .basket {
        background: $white;
        border: 1px solid $light-grey;
        padding: 3rem 3rem 0;
        .btn-return {
          margin-bottom: 20px;
          background-color: transparent;
          color: black;
          width: 100%;
        }
        .title {
          font-size: $fs-accent;
          font-weight: 800;
          padding-bottom: 3rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 3rem;
        }
        .items {
          padding-bottom: 3rem;
          ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;

            &.loading {
              opacity: 0.6;
            }

            li {
              padding: $margin-medium 0;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              margin-bottom: 1.6rem;
              position: relative;

              .quantity-price {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: $margin-medium 0;

                .quantity {
                  display: flex;
                  flex-direction: row;
                  .btn,
                  input {
                      width: 24px;
                      height: 24px;
                      border: 1px solid $spanish-grey;
                      margin: 0;
                      border-radius: 0;
                      padding: 0;
                  }

                  .btn {
                      background-color: $cultured;
                      color: $spanish-grey;
                      cursor: pointer;
                      text-decoration: none;

                      img {
                          height: 12px;
                      }

                      &.left {
                          border-top-left-radius: $radius-default;
                          border-bottom-left-radius: $radius-default;
                      }
                      &.right {
                          border-top-right-radius: $radius-default;
                          border-bottom-right-radius: $radius-default;
                      }

                      &.disabled {
                          cursor: not-allowed;
                          opacity: 0.6;
                      }
                  }
                  input {
                      width: 36px;
                      margin: 0;
                      border-left: none;
                      border-right: none;
                      background: $white;
                      text-align: center;

                  }
                }
              }

              .btn {
                float: right;
                color: $grey-button;
                font-size: $fs-xsmall;
                border-width: 1px;
              }

              &:before, &:after{
                position: absolute;
                content: '';
                display: block;
                width: 140%;
                height: 100%;
                left: -20%;
                transition: all ease-in-out 0.5s;
                background-repeat: no-repeat;
              }

              &:before{
                  display: none;
                  top: -100%;
                  background-image:
                    radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle,  transparent 20%, $primary-color 20%, transparent 30%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle,  transparent 10%, $primary-color 15%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%);
                background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;

              }

              &:after{
                  display: none;
                  bottom: -100%;
                  background-image:
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle,  transparent 10%, $primary-color 15%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%),
                  radial-gradient(circle, $primary-color 20%, transparent 20%);
                background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;

              }

              &.animate{
                &:before{
                  display: block;
                  animation: topBubbles2 ease-in-out 0.75s 2 forwards;
                }
                &:after{
                  display: block;
                  animation: bottomBubbles2 ease-in-out 0.75s 2 forwards;
                }
              }
            }
          }
        }
        .total {
          ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;
            li {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 3px 6px;

              span {
                display: block;
              }

              strong {
                white-space: nowrap;
              }

              &.discount {
                background-color: #feeded;
                color: #f04646;
                font-weight: 800;
              }
            }
          }
        }
        .actions {
          background: $extra-light-grey;
          margin: 0 -3rem;
          text-align: center;
          padding: 3rem;

          .btn {
            width: 100%;
            text-align: left;

            &:first-child {
              margin-bottom: 2rem;
            }
          }

          small {
            display: inline-block;
            font-size: $fs-xsmall;
            line-height: 1.1rem;
            opacity: 0.4;
          }
          .btn-default-inverted {
            height: 5rem;
          }
        }
      }
    }
  }

  .about,
  .cgv,
  .related {
    margin: 2rem auto;
    h1,
    h2 {
      margin: 0 0 3rem 0;
      font-weight: 800;
      font-size: $fs-huge;
    }
    h2 {
      font-size: $fs-large;
    }

    .description {
      padding: 3rem;
      font-size: $fs-small;
      background-color: $blue-grey;
      border-radius: $radius-default;
      text-align: justify;
      line-height: 2rem;

      h1, h2, h3, h4, h5, h6 {
        text-align: left;
        line-height: initial;
      }
    }

    .title {
      margin-top: 6rem;
    }
  }
  .nos-partenaires {
    .partners-content{
      margin-top: 0px;
    }
  }

  .about {
    order: 2;
  }
  .cgv {
    order: 3;
  }
  .nos-partenaires {
    order: 4;
  }
}

@media (min-width : $layout-xs) {
  .nos-partenaires-details {
    .header {
      img {
        max-width: 30%;
      }
    }
  }
}

@media (min-width : $layout-sm) {
  .nos-partenaires-details {
    .offers-basket {
      .offers-items,
      .basket-container {
        flex: 0 0 50%;
      }
      .offers-items {
        .offer-item {
          margin-bottom: 0;
        }
      }
      .basket-container {
        position: sticky;
        top: 80px;
      }
    }
  }
}

@media (min-width : $layout-lg) {
  .nos-partenaires-details {
    .offers-basket {
      .offers-items {
        flex: 0 0 75%;
        .offer-item {
          width: calc(100% / 3);
        }
      }
      .basket-container {
        flex: 0 0 25%;
        position: initial;
      }
    }
    .about,
    .cgv {
      max-width: 60%;
      margin: 2rem 0;
    }
  }
}

@keyframes blink {
  0% {
    border: 2px solid rgba(252, 202, 3, 0);
  }
  50% {
    border: 2px solid rgb(252, 202, 3);
  }
  100% {
    border: 2px solid rgba(252, 202, 3, 0);
  }
}


@keyframes
topBubbles2 {  0% {
 background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
}
 50% {
 background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
}
 100% {
 background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
 background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
}
}
@keyframes
bottomBubbles2 {  0% {
 background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
}
 50% {
 background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
}
 100% {
 background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
 background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
}
}