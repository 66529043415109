@import './variables';
@import './mixins';

body.error {
  background: $yellow-bg;
  text-align: center;

  .jumbotron {
    margin-top: 6rem;
    margin-bottom: 6rem;

    h1 {
      margin: 100px 0 25px;
      font-size: $fs-large;
      @include from (lg) {
        margin-top: 62px 0 25px;
        font-size: $fs-xhuge;
      }
    }

    a {
      text-decoration: underline;
    }

    img {
      width: 64px;
      margin: 20px 0;
    }

    .lead {
      margin: 100px 0;
    }

    pre {
      margin: 8px;
      font-size: $fs-xsmall;
    }
  }
}