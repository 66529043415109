@import './_variables';

#PaylineWidget {
  .pl-pay-btn {
    cursor: pointer;
    text-decoration: none;
    font-weight: 800;
    text-align: center;
    border: 2px solid;
    padding: 1.6rem 5rem;
    border-radius: $radius-full;
    border-color: $primary-color;
    background-color: $primary-color;

    &:hover {
      border-color: $primary-color-darken;
      background-color: $primary-color-darken;
    }
  }
}