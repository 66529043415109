
@import './variables';
@import './mixins';

.debug {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: $fs-xsmall;
  border: 4px solid #d0112b;
  border-left: none;
  border-right: none;
  background: rgba($white, 0.6);
  opacity: 1;
  z-index: 666;

  height: 8px;
  overflow: hidden;

  .buttons {
    position: absolute;
    top: 6px;
    right: 6px;

    .btn {
      padding: 2px $margin-medium;
    }
  }

  &:hover {
    height: 95vh;
    overflow: auto;
  }

  .grid {
    display: grid;
    grid-template-columns: 100%;

    @include from(md) {
      grid-template-columns: 33% 34% 33%;
    }

    &.full {
      grid-template-columns: 100%;
    }

    .bloc {
      padding: $margin-medium;
      max-height: 100%;
      flex-direction: column;

      span {
        font-weight: 800;
        font-style: italic;
      }

      pre {
        max-height: calc(95vh - 80px);
        overflow-y: auto;
      }
    }
  }
}