@import './variables';
@import './mixins';

#guide-theme {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include from(lg) {
        position: absolute;
    }

    &.active {
        display: block;
        z-index: 130;
    }

    div {
        margin-top: $margin-xs;
        padding: $margin-medium;
        max-width: 90vw;
        background: $primary-color;
        color: $white;
        text-align: center;
        border-radius: 8px;
        position: absolute;
        z-index: 1;
        box-shadow: 0 0 3px rgba(0,0,0,.25);
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            width: 32px;
            height: 32px;

            path {
                stroke: $white;
                fill: $white;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent $primary-color transparent;
        }

        @include from(lg) {
            width: 400px;
        }
    }
}

.tooltip {
    display:none;
    position:absolute;
    cursor: pointer;
    padding: $margin-xs;
    background-color: $yellow-light;
    border: 1px solid $primary-color;
    color: $black;
    z-index: 5000;
    font-size: $fs-xsmall;
}