.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(173 171 162 / 46%);
  backdrop-filter: blur(6px);
  z-index: 1000;
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: $fs-large;

  .loader-text-bloc {
    flex-basis: auto;
    margin: 0 40px;
  }

  img {
    width: 24px;
    animation: spin 4s linear infinite;
  }

  &.show {
    display: flex;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
