@import './variables';
@import './mixins';

.presentation-de-nos-cartes {
  #sled {
    display: none;
  }

  main {
    .navigation-anchors {
      position: fixed;
      top: 90px;
      left: 0;
      right: 0;
      z-index: 101;
      list-style-type: none;
      margin: 0;
      background-color: white;
      padding: 22px 0 0 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: wrap;
      box-shadow: 0 0.25em 0.5em rgba(16, 16, 16, 0.05);
      @include from(md) {
        top: 90px;
      }
      > .anchor-item {
        padding: 12px 0;
        flex: 1;
        text-align: center;

        > .anchor-link {
          color: $disabled;
          text-decoration: none;
          font-size: $fs-xsmall;
          font-weight: 800;
          @include from(md) {
            font-size: $fs-small;
          }
          &.active {
            color: $primary-color;
          }
        }
      }
    }

    .progress-bar-container {
      width: 100%;
      background: $yellow-light;
      height: 22px;
      position: absolute;
      top: 0;
      left: 0;
      .progress-bar {
        max-width: 100%;
        background: $primary-color;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 22px;
      }
    }

    .container {
      background-color: rgb(255, 254, 248);
      box-shadow: 0 0.25em 0.5em rgb(16 16 16 / 5%);
      padding-bottom: $margin-large;

      h1 {
        margin: 100px 0 25px;
        font-size: $fs-huge;
        text-align: center;
        @include from(lg) {
          margin-top: 62px 0 25px;
          font-size: $fs-xhuge;
        }
      }
      .advantages {
        margin: 0 auto 27px;
        max-width: 355px;
        padding: 20px 55px;
        border-radius: $radius-default;
        background-color: $yellow-light;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @include from(md) {
          margin: 0 auto 52px;
          max-width: initial;
          width: 640px;
          padding: 15px 55px;
        }
        @include from(lg) {
          width: 750px;
        }
        .advantage-item {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include from(md) {
            margin-bottom: 0;
          }
          .advantage-title {
            margin-left: 10px;
            color: $davy-grey;
            font-size: $fs-xsmall;
            font-weight: 800;
          }

          svg {
            path {
              fill: var(--primary);
            }
          }
        }
      }
      section {
        background: $white;
        border: 1px solid $border-grey;
        border-radius: $radius-accent;
        margin-bottom: 25px;
        padding: $margin-large 19px 32px;
        @include from(lg) {
          max-width: 850px;
          margin: 0 auto 30px;
          padding: 34px 50px 40px;
        }
        .section-header {
          .section-title {
            margin-bottom: 10px;
            display: flex;
            align-items: flex-end;
            img {
              width: 30px;
              @include from(md) {
                width: 57px;
              }
            }
            svg {
              stroke: $primary-color;
              width: 30px;
              @include from(md) {
                width: 57px;
              }
            }
            .title-point {
              padding-left: $margin-medium;
              display: inline-flex;
              align-items: flex-end;
              h2 {
                margin-bottom: 0;
                padding-right: 5px;
                font-size: $fs-medium;
                line-height: 14px;
                @include from(md) {
                  font-size: $fs-large;
                  line-height: 19px;
                }
              }
              .point {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: $primary-color;
                border-radius: $radius-half;
              }
            }
          }
          .section-description {
            font-size: $fs-xsmall;
            color: $spanish-grey;
            @include from(md) {
              font-size: $fs-small;
            }
            a {
              color: $secondary-color;
              font-weight: 800;
              &hover {
                color: $secondary-color-darken;
              }
            }
          }
        }
      }
      #choice-card {
        .wrapper {
          margin-top: 25px;
          @include from(md) {
            margin-top: 34px;
          }
          .carousel {
            width: 284px;
            margin: 0 auto;
            position: relative;
            @include from(md) {
              width: 597px;
            }
            .viewport {
              margin: 0 auto;
              height: 100px;
              overflow: hidden;
              position: relative;
              @include from(md) {
                height: 158px;
              }
              ul {
                position: absolute;
                list-style: none;
                display: flex;
                align-items: center;
                padding: 0;
                @include from(md) {
                  margin: 0 0 0 9px;
                }
                li {
                  float: left;
                  margin-right: 12px;
                  @include from(md) {
                    margin-right: 18px;
                  }

                  &.selected {
                    .card-visual {
                      cursor: unset;
                      img {
                        width: 120px;
                        border: 4px solid $grey-border;
                        @include from(md) {
                          width: 200px;
                        }
                      }
                      .card-name {
                        color: $independence-grey;
                        @include from(lg) {
                          font-size: $fs-small;
                        }
                      }
                      .card-price {
                        bottom: 65px;
                        right: 11px;
                        font-size: $fs-small;
                        @include from(md) {
                          bottom: 67px;
                          right: 14px;
                          font-size: $fs-medium;
                        }
                      }
                    }
                  }
                  .card-visual {
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                      width: 70px;
                      border-radius: $radius-default;
                      @include from(md) {
                        width: 170px;
                      }
                    }
                    .card-name {
                      margin-top: 6px;
                      font-size: $fs-xxsmall;
                      text-align: center;
                      font-weight: 800;
                      color: $cultured;
                      min-height: 50px;
                      @include from(lg) {
                        font-size: $fs-small;
                      }
                    }
                    .card-price {
                      position: absolute;
                      bottom: 59px;
                      right: 5px;
                      color: $white;
                      @include from(md) {
                        font-size: $fs-default;
                        bottom: 63px;
                        right: 8px;
                      }
                      @include from(lg) {
                        bottom: 64px;
                      }
                    }
                  }
                  .partners {
                    display: none;
                  }
                  a {
                    display: block;
                  }
                }
              }
            }
            .prev,
            .next {
              position: absolute;
              top: 23px;
              padding: 2px 10px;
              @include from(md) {
                top: 48px;
              }
              img {
                width: 8px;
                @include from(md) {
                  width: 15px;
                }
              }
            }
            .prev {
              left: -31px;
              @include from(lg) {
                left: -61px;
              }
            }
            .next {
              right: -31px;
              @include from(lg) {
                right: -61px;
              }
            }
          }
          .partners {
            margin: 40px auto 0;
            padding: 22px 20px 40px;
            display: block;
            border: 1px solid $primary-color;
            width: 100%;
            position: relative;
            @include from(md) {
              padding: 33px 29px 40px;
            }
            .partners-card-name {
              padding: 4px 30px;
              background-color: $primary-color;
              border-radius: $radius-full;
              color: $white;
              text-transform: uppercase;
              font-size: $fs-default;
              font-weight: 800;
              position: absolute;
              top: -16px;
              left: 50%;
              transform: translateX(-50%);
              width: 70%;
              text-align: center;
            }
            .partners-list {
              display: inline-flex;
              align-items: center;
              flex-wrap: wrap;
              img {
                margin: 5px;
                flex: 1 1 auto;
                max-width: 55px;
                width: 52px;
                @include from(md) {
                  width: 55px;
                  max-width: 55px;
                }
                @include from(lg) {
                  width: 104px;
                  max-width: 104px;
                }
              }
            }
            .show-partners {
              padding: 6px 21px;
              background-color: $primary-color;
              border-radius: $radius-small;
              color: $white;
              font-size: $fs-small;
              font-weight: 800;
              position: absolute;
              bottom: -14px;
              left: 50%;
              transform: translateX(-50%);
              display: flex;
              align-items: center;
              cursor: pointer;
              img {
                margin-right: 10px;
              }
            }
          }
        }
      }

      #choice-price {
        .credit-range {
          padding: 10px 10px 60px 10px;
          position: relative;
          @include from(lg) {
            margin-top: 50px;
          }
          .price-range {
            padding-bottom: 14px;
            font-size: $fs-default;
            font-weight: 400;
            @include from(lg) {
              font-size: $fs-accent;
            }
          }
        }
        .noUi-marker-large {
          background: transparent;
        }
        .noUi-target {
          border-radius: $radius-full;
          box-shadow: none;
          background-color: transparent;
          border: 1px solid $primary-color;
        }
        .noUi-connects {
          border-radius: $radius-full;
        }
        .noUi-connect {
          background-color: $primary-color;
        }
        .noUi-horizontal .noUi-handle {
          border-radius: $radius-full;
          width: 30px;
          height: 30px;
          border: none;
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
          top: -7px;
          cursor: pointer;
        }
        .noUi-handle:before {
          background-color: $primary-color;
          width: 18px;
          height: 18px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: $radius-full;
        }
        .noUi-handle:after {
          display: none;
        }
        .noUi-horizontal .noUi-tooltip {
          top: 120%;
          bottom: inherit;
          border: none;
          font-weight: 800;
          font-size: $fs-accent;
        }
        .noUi-value {
          font-size: $fs-small;
        }
      }

      #selected-card {
        .custom-bloc {
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          @include from(md) {
            flex-direction: unset;
          }

          .preview-card {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            order: 2;
            @include from(md) {
              margin-top: 0;
              order: 1;
            }
            .toggle {
              margin-bottom: 12px;
              display: flex;
              align-items: center;
              span {
                margin: 0 10px;
                font-weight: 800;
                font-size: $fs-xsmall;
                color: $disabled;
              }

              .switch {
                display: inline-block;
                margin: 0px;
                position: relative;
                .switch-inner {
                  display: block;
                  margin: 0px;
                  width: 60px;
                  height: 30px;
                  background: $primary-color;
                  border-radius: $radius-xlarge;
                  overflow: hidden;
                  position: relative;
                  transition: all 0.3s ease;
                  &:after {
                    content: '';
                    width: 24px;
                    height: 24px;
                    background: $white;
                    border-radius: $radius-xlarge;
                    position: absolute;
                    left: 3px;
                    top: 3px;
                    text-align: center;
                    transition: all 0.3s ease;
                    padding: 5px 0px;
                  }
                }
                input[type='checkbox'] {
                  cursor: pointer;
                  width: 50px;
                  height: 25px;
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  z-index: 1;
                  margin: 0px;
                  &:checked {
                    + {
                      label.switch-inner {
                        &:after {
                          left: 33px;
                        }
                      }
                    }
                  }
                }
              }

              &.left-selected {
                span:first-child {
                  color: $independence-grey;
                }
              }
              &.right-selected {
                span:last-child {
                  color: $independence-grey;
                }
              }
            }
            .img-card {
              position: relative;
              .img-verso,
              .img-recto {
                width: 245px;
                border-radius: $radius-default;
                display: none;
              }
            }
            &.verso-preview {
              .verso {
                color: $independence-grey;
              }
              .img-verso {
                display: block;
              }
              .card-informations {
                display: block;
                .card-price {
                  position: absolute;
                  right: 11px;
                  top: 3px;
                  font-size: $fs-huge;
                  font-weight: 100;
                  color: $white;
                }
                .card-message {
                  position: absolute;
                  top: 69px;
                  left: 8px;
                  padding-right: 14px;
                  .quote-img {
                    position: absolute;
                    left: 0;
                    top: -12px;
                    opacity: 0.7;
                    width: 30px;
                  }
                  .message {
                    font-style: italic;
                    padding-left: 35px;
                    z-index: 3;
                    display: block;
                    position: relative;
                    font-size: $fs-xsmall;
                    color: $white;
                  }
                  .name {
                    padding-left: 35px;
                    font-weight: 600;
                    font-size: $fs-xsmall;
                    color: $white;
                  }
                }
              }
            }
            &.recto-preview {
              .recto {
                color: $independence-grey;
              }
              .img-recto {
                display: block;
              }
              .card-informations {
                display: none;
              }
            }
          }
          .from {
            flex: 2;
            margin: 0 auto;
            max-width: 307px;
            order: 1;
            @include from(md) {
              order: 2;
            }
            .input-block {
              margin-bottom: 12px;
            }
            textarea {
              background-color: transparent;
              border: 1px solid rgba(0, 0, 0, 0.1);
              font-size: $fs-xsmall;
              line-height: 1.6rem;
            }
          }
        }
      }
      #send-message {
        .checkbox-container {
          display: flex;
          justify-content: space-between;

          @media only screen and (max-width: $layout-xs) {
            flex-direction: column;
          }
        }

        .checkbox {
          font-size: $fs-xsmall;
          display: flex;
          align-items: center;
          input {
            padding: 0;
            -webkit-appearance: none;
            appearance: none;
            background-color: $white;
            margin: 0 12px 0 0;
            color: $primary-color;
            width: 20px;
            height: 20px;
            border: 1px solid $primary-color;
            display: grid;
            place-content: center;
            &:before {
              content: '';
              width: 12px;
              height: 12px;
              transform: scale(0);
              transition: 120ms transform ease-in-out;
              background-color: $primary-color;
            }
            &:checked {
              &::before {
                transform: scale(1);
              }
            }

            &[type="radio"] {
              &::before {
                border-radius: $radius-full;
              }
            }
          }
        }

        .fields-form {
          margin: 23px 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          &.none {
            display: none;
          }
          .field {
            margin-bottom: 12px;
            flex: 1 1 auto;
            @include from(md) {
              margin-bottom: 0;
              flex: 0 0 30%;
            }
            .error {
              font-size: $fs-xsmall;
              color: $error;
            }

            &.error {
              input {
                border-color: $error;
              }
            }
          }
        }
        label.none {
          display: none;
        }
      }
      #total-price {
        max-width: 850px;
        margin: 0 auto 80px;
        .total-wrapper {
          display: flex;
          flex-direction: column;
          @include from(md) {
            width: 50%;
            margin-left: auto;
          }
          .basketPrice {
            margin-bottom: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: $fs-default;
              line-height: 2.6rem;
              color: $black;
              font-weight: 800;
            }
          }
          button {
            padding: 13px 32px;
            background-color: $primary-color;
            border-radius: $radius-small;
            font-size: $fs-default;
            line-height: 2.4rem;
            font-weight: 800;
            color: $white;
            border: none;
          }
        }
      }

      textarea {
        height: 15rem;
      }
    }
  }
}
