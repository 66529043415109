@import './variables';
@import './mixins';


/*
Animations
*/

$leftSide: 60vw;
$middle: -50%;
$rightSide: -180vw;

@keyframes nextFirstSlideMobile {
  from {
    transform: translateX($leftSide);
    opacity: 0.50;
  }

  50%,
  80% {
    opacity: 1;
  }

  to {
    transform: translateX($middle);
  }
}

@keyframes nextSecondSlideMobile {
  from {
    transform: translateX($middle);
    opacity: 1;
  }

  50%,
  80% {
    opacity: 0.50;
  }

  to {
    transform: translateX($rightSide);
    opacity: 0;
  }
}

@keyframes prevFirstSlideMobile {
  from {
    transform: translateX($rightSide);
    opacity: 0;
  }

  50%,
  80% {
    opacity: 0.50;
  }

  to {
    transform: translateX($middle);
    opacity: 1;
  }
}

@keyframes prevSecondSlideMobile {
  from {
    transform: translateX($middle);
    opacity: 1;
  }

  50%,
  80% {
    opacity: 0.50;
  }

  to {
    transform: translateX($leftSide);
    opacity: 0;
  }
}

/*
  Composant banner
*/

.banner {
  z-index: 2;
  min-height: 80px;
  width: 100%;
  position: sticky;

  a,
  p {
    font-size: $fs-default;
    color: revert;
    margin-top: unset;
    margin-bottom: unset;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    vertical-align: middle;
  }

  .banner-size-blocker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: auto;
    height: 100%;
    width: 100%;
  }

  .slider {
    height: 100%;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .promo-container {
    position: absolute;
    top: 0.5rem;
  }

  .next-promotion, .previous-promotion {
    display: none;
  }

  .promo-txt {
    flex: 0 0 100%;
    position: absolute;
    margin: auto;
    z-index: 0;
    display: none;
    width: 90vw;

    &.nextFirstSlide,
    &.nextSecondSlide,
    &.prevFirstSlide,
    &.prevSecondSlide {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 70px;
      text-align: center;
      overflow-x: visible;
      animation-iteration-count: 1;
    }

    &.nextFirstSlide,
    &.prevFirstSlide {
      transform: translateX(-50%);
    }

    &.nextFirstSlide {
      animation: nextFirstSlideMobile 1.5s ease-in-out;
    }

    &.nextSecondSlide {
      animation: nextSecondSlideMobile 1.5s ease-in-out;
      transform: translateX(-150vw);
    }

    &.prevFirstSlide {
      animation: prevFirstSlideMobile 1.5s ease-in-out;
    }

    &.prevSecondSlide {
      animation: prevSecondSlideMobile 1.5s ease-in-out;
      transform: translateX(50vw);
    }
  }

  /*
      Version tablette
    */

  @include from(md) {

    .promo-container {
      height: 100%;
      display: flex;
      align-items: center;
      width: 10px;
      top: 0;
    }

    min-height: 40px;
    height: unset;

    .banner-size-blocker {
      max-width: 960px;
    }

    .promo-txt {

      &.nextFirstSlide,
      &.nextSecondSlide,
      &.prevFirstSlide,
      &.prevSecondSlide {
        min-height: unset;
        height: unset;
      }
    }
  }

  /*
  Version desktop
  */

  @include from(lg) {
    img {
      width: 16px;
    }

    .previous-promotion,
    .next-promotion {
      display: block;
      z-index: 1;

      .btn {
        border: transparent;
        border-radius: 5px;
        cursor: pointer;
        padding: 0;
        background: transparent;
        width: 60px;
        padding: $margin-xs 0 0;
      }
    }

    button:focus {
      outline: none;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        stroke: $banner-color;
        fill: $white;
      }
    }

    .promo-txt {
      height: 100%;
      align-items: center;

      &.nextFirstSlide,
      &.nextSecondSlide,
      &.prevFirstSlide,
      &.prevSecondSlide {
        width: 60vw;
      }
    }
  }


  @include from(xl) {
    .banner-size-blocker {
      max-width: 1140px;
    }

    $leftSide: 50vw;
    $middle: -50%;
    $rightSide: -150vw;

    @keyframes nextFirstSlideDesktop {
      from {
        transform: translateX($leftSide);
        opacity: 0.50;
      }

      50%,
      80% {
        opacity: 1;
      }

      to {
        transform: translateX($middle);
      }
    }

    @keyframes nextSecondSlideDesktop {
      from {
        transform: translateX($middle);
        opacity: 1;
      }

      50%,
      80% {
        opacity: 0.50;
      }

      to {
        transform: translateX($rightSide);
        opacity: 0;
      }
    }

    @keyframes prevFirstSlideDesktop {
      from {
        transform: translateX($rightSide);
        opacity: 0;
      }

      50%,
      80% {
        opacity: 0.50;
      }

      to {
        transform: translateX($middle);
        opacity: 1;
      }
    }

    @keyframes prevSecondSlideDesktop {
      from {
        transform: translateX($middle);
        opacity: 1;
      }

      50%,
      80% {
        opacity: 0.50;
      }

      to {
        transform: translateX($leftSide);
        opacity: 0;
      }
    }

    .promo-txt {
      &.nextFirstSlide {
        animation: nextFirstSlideDesktop 1.5s ease-in-out;
      }

      &.nextSecondSlide {
        animation: nextSecondSlideDesktop 1.5s ease-in-out;
        transform: translateX(-150vw);
      }

      &.prevFirstSlide {
        animation: prevFirstSlideDesktop 1.5s ease-in-out;
      }

      &.prevSecondSlide {
        animation: prevSecondSlideDesktop 1.5s ease-in-out;
        transform: translateX(50vw);
      }
    }

  }
}
