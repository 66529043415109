@import "./variables";

.fc {
	direction: ltr;
	text-align: left;
	margin-bottom: 2rem;
	font-size: $fs-xsmall;
    padding-left: 10px;

	table {
		border-collapse: collapse;
		border-spacing: 0;
		font-size: $fs-xsmall;

        td,
        th {
            padding: 0;
            vertical-align: top;

            .fc-today {
                position: relative;
            }
        }

        &.fc-header {
            margin-bottom: 1em;
	        vertical-align: top;

            td {
                white-space: nowrap;
	            padding: 15px 10px 0px;

                .fc-button {
                    border-color: $primary-color;
                    color: $primary-color;
                    font-weight: 800;
                    margin-bottom: 1em;
	                vertical-align: top;
                    margin-right: -1px;
                    position: relative;
                    display: inline-block;
                    padding: 0 .6em;
                    overflow: hidden;
                    height: 1.9em;
                    line-height: 1.9em;
                    white-space: nowrap;
                    cursor: pointer;
                    border: 1px solid;

                    &.fc-corner-left {
                        border-top-left-radius: $radius-small;
                        border-bottom-left-radius: $radius-small;
                    }

                    &.fc-corner-right {
                        border-top-right-radius: $radius-small;
                        border-bottom-right-radius: $radius-small;
                    }

                    &.fc-state-disabled {
                        cursor: default;
                        background-image: none;
                        background-color: $white;
                        opacity: 0.3;
                        box-shadow: none;
                    }

                    .fc-text-arrow {
                        margin: 0 .4em;
                        font-size: 2em;
                        line-height: 23px;
                    }

                }

                .fc-header-space {
                    padding-left: 10px;
                }
            }

            .fc-header-left {
                width: 25%;
                text-align: left;
            }

            .fc-header-center {
                text-align: center;
            }

            .fc-header-right {
                width: 25%;
                text-align: right;
            }

            .fc-header-title {
                display: inline-block;
                vertical-align: top;
                margin-top: -5px;

                h2 {
                    margin-top: 0;
                    white-space: nowrap;
                    font-size: $fs-huge;
                    font-weight: 100;
                    margin-bottom: 10px;
                }
            }

            .fc-corner-right {
                margin-right: 0;
            }
        }

        .fc-first th{
            background: $primary-color;
            color: $white;
        }

        .fc-widget-header {
            border-bottom: 1px solid #EEE;
        }

        .fc-widget-content {
            border: 1px solid #e5e5e5;
        }

        .fc-state-highlight > div > div.fc-day-number{
            background-color: $primary-color;
            color: $white;
            border-radius: $radius-half;
            margin: 4px;
            width: 24px;
            text-align: center;
        }

        .fc-border-separate {
            border-collapse: separate;

            &th,
            &td {
                border-width: 1px 0 0 1px;

                &.fc-last {
                    border-right-width: 1px;
                    border-right: 0;

                    th {
                        border-bottom-width: 1px;
                        border-color: #cdcdcd;
                        font-size: $fs-default;
                        line-height: 30px;
                    }
                }

                &.fc-first {
                    border-left: 0;

                    td,
                    th {
                        border-top-width: 0;
                    }
                }
                &.fc-first {
                    border-left: 0;
                }
            }
        }
	}

    .fc-event-inner {
        background: var(--variant-accent);
        color: $white;
        padding: 5px 0px!important;
    }

    .fc-content {
        clear: both;
        border: 1px solid #eee;
        background-color: white;

        .fc-view {
            width: 100%;
            overflow: hidden;

            &.fc-grid {
                th {
                    text-align: center;
                }

                .fc-day-number {
                    float: right;
                    padding: 0 2px;
                }


                .fc-other-month {
                    .fc-day-number {
                        opacity: 0.3;
                    }
                }

                .fc-day-content {
                    clear: both;
                    padding: 2px 2px 1px;
                }
            }

            .fc-event-container > * {
                z-index: 8;
            }

            .fc-event-container {
                .fc-event {
                    border: 1px solid $white;
                    background-color: $white;
                    color: #919191;
                    font-size: $fs-xsmall;
                    cursor: default;

                    &:hover{
                        opacity: 0.7;
                    }

                    &.fc-event-hori {
                        border-width: 1px 0;
                        margin-bottom: 1px;

                        &.fc-event-start {
                            border-left-width: 1px;
                        }

                        &.fc-event-end {
                            border-right-width: 1px;
                        }
                    }

                    .fc-event-inner {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        line-height: 15px;

                        .fc-event-title {
                            padding: 0 1px;
                        }
                    }
                }
            }
        }
    }
}