@import './_variables';
@import './mixins';
@import'./tooltip';

body.overflow {
  .button-log {
    .icon {
      margin-right: 16px;
    }
  }
}

.button-log {
  margin-left: 16px;
  padding: 5px 6px 5px 17px;
  background-color: $primary-color;
  border-radius: $radius-full;
  border: none;
  display: flex;
  align-items: center;

  &:focus {
    outline: 2px solid $primary-color-darken;
  }

  &#menuLang {
    padding: 5px;
    margin-left: 4px;

    img {
      object-fit: cover;
      border-radius: $radius-half;
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }

  .icon {
    height: 12px;

    &.with-margin {
      margin-right: 8px;
    }
  }

  .flag {
    height: 14px;
    margin: 0 8px;
  }

  .wallet {
    margin-right: 6px;
    font-size: $fs-default;
    font-weight: 800;
    color: $white;

    @include from(md) {
      font-size: $fs-medium;
    }
  }
}

.header-main {
  position: sticky;
  top: 0;
  z-index: 100;
  min-height: 4rem;
  background: $white;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.02), 0 2px 40px 0 rgba(0, 0, 0, 0.04);
  height: 90px;

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    @include from(md) {
      justify-content: space-between;
    }

    .header-logo {
      .logo {
        max-width: 140px;
        max-height: 50px;

        @include from(md) {
          max-width: 224px;
        }

        &.logo-operation {
          display: none;

          @include from(md) {
            display: block;
          }
        }
      }
    }

    .header-nav {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;

      .nav-item {
        #catalog {
          display: none;

          @include from(md) {
            display: block;
          }

          font-size: $fs-small;
        }

        .cart-container {
          margin-left: 15px;
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-tap-highlight-color: transparent;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .shopping-cart {
            width: 30px;
            height: 40px;
            background-image: url(/icons/shopping-cart.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            .total-articles {
              position: absolute;
              left: 12px;
              padding: 5px;
              background-color: $primary-color;
              display: flex;
              align-items: center;
              width: 30px;
              height: 30px;
              border-radius: $radius-xlarge;
              justify-content: center;

              .total {
                font-size: $fs-default;
                font-weight: 800;
                color: white;
              }
            }
          }

          .cart-dropdown-container {
            position: absolute;
            right: 0;
            top: 14px;
            min-width: 200px;
            padding-top: 12px;
            margin: 16px 0;
            visibility: hidden;
            opacity: 0;
            border-radius: $radius-accent;
            transition: all 0.3 ease;

            .cart-dropdown {
              padding: 12px 8px;
              background: white;
              border-radius: $radius-accent;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04),
                0 20px 50px 0 rgba(0, 0, 0, 0.2);

              .cart-item {
                display: block;
                margin-left: 0;
                text-decoration: none;
                font-size: $fs-default;
                padding: 12px;
                border-radius: $radius-default;
                margin: 0px 8px;
                font-weight: 800;
                transition: all 0.3s ease-in;

                &.blue {
                  color: $secondary-color;

                  &:hover {
                    background-color: $secondary-color-darken;
                    color: white;
                  }
                }

                &.yellow {
                  color: $primary-color;

                  &:hover {
                    background-color: $primary-color-darken;
                    color: white;
                  }
                }
              }
            }

            &.open {
              visibility: visible;
            }

            &.show-animation {
              opacity: 1;
              top: 60%;
            }
          }
        }
      }
    }
  }
}

.header-overlay {
  position: fixed;
  background: $black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  z-index: 119;

  &--open {
    visibility: visible;
    opacity: 0.6;
  }
}

.header-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  background-color: $white;
  opacity: 0;
  transform: translateY(100%);
  z-index: 12;
  transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
  visibility: hidden;
  height: 100%;
  // INFO: Ajour de l'overflow sur le menu suite au bug sur mobile
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 120;

  &.justify {
    justify-content: space-between;
  }

  @include from(md) {
    width: 50%;
    transform: translateY(0) translateX(60px);
  }

  @include from(lg) {
    width: 70%;
    height: 100vh;
  }

  &--open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;

    @include from(md) {
      transform: translateX(0);
    }
  }

  .header-menu-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 24px;
  }

  .button-log {
    margin-left: unset;
    position: relative;
    min-height: 41px;
  }

  .menu-top,
  .menu-content {
    padding: $margin-large $margin-large 34px $margin-large;
    background-color: $yellow-light;

    @include from(md) {
      padding: 20px 40px 33px $margin-large;
    }

    .actions {
      &.hidden {
        display: none;
      }
    }

    .title {
      margin-bottom: 0;
      font-size: $fs-huge;
      color: $black;
      font-weight: 800;
    }

    .baseline,
    .subtitle {
      color: var(--primary-accent);
      font-size: $fs-small;
      font-weight: 800;
    }

    .subtitle {
      font-style: italic;
    }

    .buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      @include from(lg) {
        flex-direction: row;
        align-items: center;
      }

      .or {
        margin: 9px 0;
        text-align: center;

        @include from(lg) {
          margin: 0 20px;
        }
      }
    }

    .section-login,
    .section-signIn {
      display: none;

      &.display {
        display: flex;
        flex-direction: column;

        .operation-form,
        .connexion-form {
          margin-top: 20px;
          margin-bottom: $margin-large;
          width: 100%;
          display: flex;
          flex-direction: column;

          .input-item {
            margin: 10px 0;
            position: relative;
            max-width: 526px;

            .input-block {
              input {
                background-color: $white;

                &:not(:placeholder-shown) {
                  + {
                    .placeholder {
                      background: linear-gradient(
                        182deg,
                        var(--light-background),
                        $white
                      );
                    }
                  }
                }

                &:focus {
                  + {
                    .placeholder {
                      background: linear-gradient(
                        182deg,
                        var(--light-background),
                        $white
                      );
                    }
                  }

                  &:not(:placeholder-shown) {
                    + {
                      .placeholder {
                        background: linear-gradient(
                          182deg,
                          var(--light-background),
                          $white
                        );
                      }
                    }
                  }
                }
              }

              &.no-background {
                input {
                  background-color: $white;

                  &:not(:placeholder-shown) {
                    + {
                      .placeholder {
                        background: $white;
                      }
                    }
                  }

                  &:focus {
                    + {
                      .placeholder {
                        background: $white;
                      }
                    }

                    &:not(:placeholder-shown) {
                      + {
                        .placeholder {
                          background: $white;
                        }
                      }
                    }
                  }
                }
              }
            }

            .hash-password {
              position: absolute;
              top: 61px;
              left: 0;
              display: block;
              padding: 16px;
              background-color: #253e67;
              border-radius: $radius-large;
              width: 257px;
              visibility: hidden;
              z-index: 2;

              &::before {
                display: none;
                content: '';
                position: absolute;
                border-right: 10px solid #253e67;
                border-bottom: 10px solid transparent;
                border-top: 10px solid transparent;
                top: 50%;
                transform: translateY(-50%);
                right: 100%;
              }

              .hash-title {
                margin-bottom: 10px;
                font-size: $fs-small;
                line-height: 1.7rem;
                font-weight: 800;
                color: $white;
              }

              .hash-condition {
                position: relative;
                padding-left: 12px;
                font-size: $fs-small;
                line-height: 1.7rem;
                color: $white;

                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  width: 4px;
                  height: 4px;
                  border-radius: $radius-full;
                  background-color: $white;
                }

                &.condition-ok {
                  color: $primary-color;
                  font-weight: 800;
                }
              }
            }

            .icon-eye {
              padding: 0;
              position: absolute;
              width: 30px;
              height: 30px;
              background: url('/icons/eye.svg') no-repeat center center;
              background-size: contain;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              border: none;
            }

            .error {
              font-size: $fs-xsmall;
              font-style: italic;
              margin: 3px 0;
              color: $error;
            }
          }

          .m-button {
            align-self: flex-end;

            &:focus {
              outline: none;
            }
          }

          .forgot-password {
            color: $independence-grey;
            text-decoration: underline;
          }

          .login-actions {
            margin-top: 36px;
            display: flex;
            align-items: center;

            .cancelLogin,
            .cancelSignIn {
              margin-left: 20px;
              border: none;
              text-decoration: underline;
              color: $independence-grey;
              font-size: $fs-default;
              background-color: transparent;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  .menu-content {
    position: relative;
    padding: 26px $margin-large;
    background-color: $white;
    // min-height: calc(100vh - (171px + 90px));
    // overflow: auto;
    // margin-bottom: 90px;

    @include from(md) {
      padding: 30px $margin-large;
    }

    @include from(lg) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // min-height: calc(100vh - 171px);
    }

    .submenu {
      margin-bottom: 18px;
      font-size: $fs-accent;
      font-weight: 800;

      &::first-line {
        font-weight: 400;
      }
    }

    .subsubmenu {
      margin-bottom: 18px;
      font-size: $fs-accent;

      &::first-line {
        font-weight: 800;
      }
    }

    .logo {
      max-width: 526px;
      max-height: 128px;
    }

    .separator {
      width: 20%;
      height: 8px;
      background-color: $primary-color;
      border-radius: $radius-small;
      margin: 12px 0 32px;
    }
  }

  .service-items {
    display: flex;
    flex-flow: row wrap;

    .service-item {
      flex: 1 1 100%;
      border: 1px solid $border-grey;
      border-radius: $radius-accent;
      padding: $margin-large 16px 12px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
      cursor: pointer;
      position: relative;

      &:hover {
        text-decoration: none;
        border-color: $primary-color;

        .service-icon {
          svg {
            fill: $primary-color;
          }
        }

        .link-item {
          color: $primary-color;
        }
      }

      @include from(lg) {
        flex: 0 0 22%;
        margin: 0 10px 10px 0;
      }

      .service-icon {
        width: 30px;

        svg {
          fill: $independence-grey;
        }
      }

      .link-item {
        margin-top: $margin-large;
        font-size: $fs-small;
        font-weight: 800;
        color: $independence-grey;
      }

      .picto {
        display: none;
      }
    }
  }

  .offer-items {
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 0 -0.5rem;

    .offer-item {
      margin-bottom: 10px;
      flex: 1 1 100%;
      background-color: #fafafa;
      border-radius: $radius-accent;
      padding: 8px 12px;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #e9e9e9;
      }

      @include from(lg) {
        flex: 0 0 22%;
        margin: 0 10px 10px 0;
      }

      .offer-logo-container {
        margin-bottom: 4px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        .offer-logo {
          max-width: 100%;
        }
      }

      .offer-name {
        font-size: $fs-small;
        line-height: 1.7rem;
        color: $independence-grey;
      }

      .offer-duration {
        margin-bottom: 22px;
        font-size: $fs-default;
        line-height: auto;
        font-weight: 800;
        color: $independence-grey;
      }

      .offer-link {
        color: $secondary-color;
        font-size: $fs-xsmall;
      }
    }
  }

  .menu-logout {
    width: 100%;
    padding: 20px $margin-large;
    background-color: $yellow-light;
    display: flex;
    justify-content: flex-end;
    // position: fixed;
    // bottom: 0;

    .m-button {
      &.disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }

  .language-block {
    margin-top: $margin-large;
    border-top: 1px solid #ededed;
    padding-top: 16px;

    @include from(lg) {
      padding-top: 32px;
      width: 50%;
    }
  }

  &.small {
    @include from(xl) {
      width: 50%;
    }

    .menu-content {
      .service-items {
        display: grid;
        grid-template-columns: 100%;

        @include from(md) {
          grid-template-columns: 50% 50%;
          grid-gap: 16px;
        }

        .service-item {
          &.selected {
            text-decoration: none;
            border-color: $primary-color;

            .link-item {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}

#header-menu-lang {
  .button-log {
    align-self: end;
    width: 44px;
    height: 41px;
    margin: 20px 20px 20px 0;
    justify-content: center;
  }

  .button-log .icon {
    width: 12px;
    height: 12px;
    margin-right: 11px;
  }
}


.menu-logged {
  padding: $margin-large $margin-large 34px $margin-large;
  // overflow: auto;
  // margin-bottom: 90px;

  @include from(lg) {
    // min-height: calc(100vh - 160px);
    // overflow: auto;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 14px;
    font-size: $fs-huge;
    color: $black;
    font-weight: 800;
  }

  .submenu {
    margin-bottom: 18px;
    font-size: $fs-accent;
    font-weight: 800;
  }

  .menu-offers {
    margin: 32px 0;
  }
}

.submenu-partners {
  position: absolute;
  display: flex;
  width: 100%;
  background-color: $white;
  left: 0;
  opacity: 1;
  height: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  transition: height 0.4s, visibility 0.6s, box-shadow 0.5s;
  overflow: hidden;
  border-top: 1px solid #c8cacb;
  top: 90px;
  visibility: hidden;
  z-index: 110;

  .top-partners-list {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;

    .top-partners-item {
      margin: 0 20px;
      width: 70px;
      height: 70px;
      border-radius: $radius-small;
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.6s, transform 0.4s;

      &:hover {
        transform: scale(1.1);
      }

      .top-partners-link {
        .top-partners-logo {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.show {
    visibility: visible;
    height: 100px;
    box-shadow: 0 50px 50px rgb(0 0 0 / 25%);

    .top-partners-list {
      .top-partners-item {
        opacity: 1;
      }
    }
  }
}
