@import './_variables';

#modal-soc
{
    .modal-header {
        border-bottom: none;
        padding: $margin-large $margin-large 0;
    }
    .modal-body {
        padding:  $margin-large;
    }
    .modal-footer {
        border-top: none;
    }
}