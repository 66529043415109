#modal-evt {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0;

    @include from(lg) {
      padding: 0 16px;
    }

    h1,
    h2,
    h3 {
      margin: 16px;
      padding: 0;
    }

    .message {
      text-align: center;
    }

    form {
      text-align: right;
      .btn {
        margin-top: $margin-large;
      }
    }
  }
}