@import './_variables';
@import './mixins';

.qui-sommes-nous {
  h1 {
    @include includeH1Header();
    text-align: center;
  }

  section {
    background: $white;
    border: 1px solid $border-grey;
    border-radius: $radius-accent;
    margin: $margin-large auto;
    padding: $margin-large 19px 32px;
    @include from(lg) {
      padding: 34px 50px 40px;
    }

    h2 {
      margin-left: 12px;
      text-transform: uppercase;
    }

    p {
      text-align: center;
      padding: 2rem 0;
      margin: 0;

      &.important {
        color: $primary-color;
        font-weight: 800;
        font-size: $fs-large;
      }
    }

    .person {
      margin: 0 12px 30px 12px;
      background: $grey-dark;
      border: 1px solid $grey-dark;
      color: $white;

      &.primary {
        background: $primary-color;
        border: 1px solid $primary-color;
        color: $black;
      }

      a {
        color: $white;
      }
      .photo {
        img {
          max-width: 100%;
          min-width: 100%;
        }
      }
      .info-person {
        line-height: 2rem;
        padding: 3rem;

        .name {
          font-weight: 800;
        }
        .status,
        .mail {
          font-size: $fs-small;
        }
        .status {
          font-weight: 600;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}