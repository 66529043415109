@import "./variables";

.maintenance {
  padding: 0;
  margin: 0;
  width: 100vw;

  .left {
    width: 100vw;
    height: 250px;
    background: $primary-color;
    display: flex;

    .video-container {
      position: relative;
      flex: 0 0 100%;
      background-color: $primary-color;
      padding:20px;
      display: flex;
      align-items: center;
      .connexion-video{
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
        width: 100%;
        cursor: pointer;
        .iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }

  .right {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin-top: 4rem;
    }

    .header,
    .up-date,
    .wait,
    .action {
      margin: 2rem 3rem;
      text-align: center;
    }

    .up-date {
      font-weight: 800;
    }

    .btn {
      padding-left: 3.4rem;
      padding-right: 3.4rem;
      line-height: 3.6rem;
      display: inline-block;
    }
  }
}

@media only screen and (min-width: $layout-sm) {
  .maintenance {
    .left {
      height: 500px;
    }
  }
}

@media only screen and (min-width: $layout-lg) {
  .maintenance {
    display: flex;
    flex-direction: row;

    .left {
      width: 50vw;
      height: 100vh;
    }

    .right {
      width: 50vw;
      height: 100vh;
      display: flex;

      .header,
      .up-date,
      .wait,
      .action {
        margin: 4rem 6rem;
      }
    }
  }
}