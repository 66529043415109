

.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;



  @include from(md) {
    &.flex-md-row {
      flex-direction: row;
    }
  }
  @include from(lg) {
    &.flex-lg-row {
      flex-direction: row;
    }
  }

  img {
    max-width: 100%;
  }
}