@import './_variables';

#modal-country {
    .modal-header span,
    .modal-body {
        margin: $margin-medium;
    }
    .modal-body {
        display: grid;
        grid-template-columns: 50% 50%;

        div {
            padding: $margin-large;
            cursor: pointer;

            img {
                width: 100%;
            }

            span {
                display: block;
                margin-top: 12px;
                width: 100%;
                text-align: center;
            }
        }
    }
}