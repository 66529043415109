#page-faq,
#page-faq-general {
  .grid-item {
    svg {
      width: 60px;
      height: 60px;
      path {
        stroke: #454b69;
        stroke-width: 32px;
        fill: none;
      }
    }
  }
}