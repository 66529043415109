@import './_variables';
@import './mixins';

footer {
  border-top: 1px solid $border-grey;
  background-color: $white;

  .container {
    padding: 32px;
    background: $white;
  }

  .footer-wrapper {
    .footer-top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid $border-grey;
      margin-bottom: $margin-large;
      padding-bottom: $margin-large;
      @include from(lg) {
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      .footer-logo {
        margin-bottom: 42px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @include from(lg) {
          margin-bottom: 0;
          flex: 1;
        }
        img {
          max-width: 159px;
        }
        .baseline {
          font-size: $fs-accent;
          font-weight: 800;
          color: $black;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include from(lg) {
          flex: 2;
        }
        label {
          font-size: $fs-small;
          font-weight: 400;
        }
        .newsletter-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          @include from(md) {
            flex-wrap: nowrap;
          }
          .newsletter-input {
            flex: 1 1 auto;
            margin: 0 8px 12px 0;
            background-color: transparent;
            border-radius: $radius-small;
            border: 1px solid $light-grey;
            @include from(md) {
              margin: 0 8px 0 0;
            }
          }
        }
      }
    }

    .footer-menus {
      @include from(lg) {
        display: flex;
        flex-flow: row wrap;
        margin: 0 0 0 -0.5rem;
      }
      .footer-nav {
        margin-bottom: 20px;
        @include from(lg) {
          margin-bottom: 0;
          flex: 0 0 30%;
          margin: 0 30px 0 0;
        }
        .nav-title {
          position: relative;
          display: inline-flex;
          align-items: center;
          .title-label {
            margin-bottom: 0;
            font-size: $fs-accent;
            font-weight: 600;
            color: $independence-grey;
          }
          img {
            margin-left: 10px;
            max-width: 30px;
            transform: rotate(180deg);
            transition: all 0.2s ease-in;
            @include from(lg) {
              display: none;
            }
          }
        }

        .nav-list {
          margin: 10px 0 0 0;
          padding: 0;
          list-style: none;

          &.loading {
            animation: blog-loader 750ms infinite;
          }

          .nav-list-item {
            &.with-top-margin {
              margin-top: $margin-small;
            }
            a {
              color: $spanish-grey;
              font-size: $fs-small;
              display: inline-block;
              position: relative;
              overflow: hidden;
              text-decoration: none;
              padding-right: 20px;
              &:after {
                position: absolute;
                right: 6px;
                top: 50%;
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: $radius-default;
                background: $primary-color;
                margin-left: 8px;
                -webkit-transform: translateY(30px);
                -moz-transform: translateY(30px);
                -ms-transform: translateY(30px);
                -o-transform: translateY(30px);
                transform: translateY(30px);
                -webkit-transition: -webkit-transform 0.3s
                  cubic-bezier(0.175, 0.885, 0.32, 1.275);
                transition: -webkit-transform 0.3s
                  cubic-bezier(0.175, 0.885, 0.32, 1.275);
                -o-transition: -o-transform 0.3s
                  cubic-bezier(0.175, 0.885, 0.32, 1.275);
                -moz-transition: transform 0.3s
                    cubic-bezier(0.175, 0.885, 0.32, 1.275),
                  -moz-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                transition: transform 0.3s
                  cubic-bezier(0.175, 0.885, 0.32, 1.275);
                transition: transform 0.3s
                    cubic-bezier(0.175, 0.885, 0.32, 1.275),
                  -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                  -moz-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                  -o-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
              }
              &:hover {
                &:after {
                  -webkit-transform: translateY(-50%);
                  -moz-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  -o-transform: translateY(-50%);
                  transform: translateY(-50%);
                }
              }
            }
          }
        }

        &--close {
          .nav-title {
            img {
              transform: rotate(0);
            }
          }
          .nav-list {
            display: none;
            @include from(lg) {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }

      .footer-blog {
        @include from(lg) {
          width: 30%;
        }
        .nav-list-item {
          @include from(lg) {
            width: 100%;
          }
          .item-blog {
            @include from(lg) {
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              width: 100%;
            }
          }
        }
      }
    }

    .footer-bottom {
      margin-top: 60px;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include from(lg) {
        flex-direction: row;
        justify-content: space-between;

        :first-child {
          display: flex;
          flex-direction: row;
        }
      }
      .footer-socials {
        margin: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 180px;
      }
      .copyright {
        margin: 12px 0;
        font-size: $fs-small;
        color: $independence-grey;
        text-align: center;
        @include from(lg) {
          margin-left: 20px;
          padding-top: 6px;
        }
      }
      .footer-payment {
        margin: 12px 0;
        display: flex;
        flex-direction: row;

        img {
          height: 34px;
          margin: 0 8px;
        }
      }
    }
  }
}

@keyframes blog-loader {
  0% { filter: blur(2px);}
  50% { filter: blur(4px);}
  100% {filter: blur(2px);}
}