@import './_variables';

.xs-only {
  display: none;
}

.sm-only {
  display: none;
}

.lg-only {
  display: none;
}

.xl-only {
  display: none;
}

@media only screen and (max-width: $layout-xs) {
  .xs-hide {
    display: none !important;
  }

  .xs-only {
    display: block;
  }
}

@media only screen and (max-width: $layout-sm) {
  .sm-hide {
    display: none !important;
  }

  .sm-only {
  display: block;
  }
}

@media only screen and (max-width: $layout-lg) {
  .lg-hide {
      display: none !important;
  }

  .lg-only {
      display: block;
  }
}

@media only screen and (max-width: $layout-xl) {
  .xl-hide {
    display: none !important;
  }

  .xl-only {
    display: block;
  }
}

@media only screen and (min-width: $layout-xs) {
  .footer-content,
  .container {
    max-width: 540px;
  }
}

@media only screen and (min-width: $layout-sm) {
  .footer-content,
  .container {
    max-width: 720px;
  }
}

@media only screen and (min-width: $layout-lg) {
  .footer-content,
  .container {
    max-width: 960px;
  }
}

@media only screen and (min-width: $layout-xl) {
  .footer-content,
  .container {
    max-width: 1140px;
  }
}