@import './variables';

.flash {
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid $grey-border;
  text-align: center;
  position: fixed;
  z-index: 1070;
  line-height: 4rem;
  font-weight: 800;

  -moz-animation: autoHide 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: autoHide 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: autoHide 0s ease-in 5s forwards;
  /* Opera */
  animation: autoHide 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  &.success {
    color: $white;
    background: $primary-color;
  }

  &.error {
    background: $danger-color;
    color: $white;
  }
}

@keyframes autoHide {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes autoHide {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}