#modal-event,
.confirmModal {
  .modal-content {
    border-radius: $radius-large;
    text-align: center;
    .modal-header {
      padding: 20px 60px;
      border-bottom: none;
      text-align: center;
      display: block;
    }

    .modal-body {
      &.with-bottom {
        margin-bottom: $margin-large;
      }

      .emoji {
        text-align: center;
        img {
          width: 64px;
        }
        margin-bottom: 12px;
      }

      .inputs {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        input {
          width: 46px;
          margin: 0 5px;
          text-align: center;
          border-radius: 0%;
        }
      }

      .btn {
        margin-top: 22px;
        padding: 4px 32px;
      }

      .sendAgain {
        font-size: $fs-xsmall;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}


#modal-event {
  .modal-header {
    padding: 20px;
  }
  .modal-footer {
    padding: 20px;
    text-align: center;
    display: block;
    border-top: none;
  }
  .modal-body {
    padding: 20px;
    text-align: left;
  }
}