@import './_variables';
@import './mixins';

::-moz-selection,
::selection {
  color: $black;
  background: $primary-color;
}

*:focus {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 62.5%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: $fs-default;
  line-height: 1.5;
  text-align: left;
  background: $white;
  color: $black;
  &.overflow {
    overflow: hidden;
    height: 100vh;
  }

  &.light {
    background-color: rgb(255, 254, 248);
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

b,
strong {
  font-weight: 800;
}

small {
  font-size: 70%;
}

a {
  text-decoration: none;
  font-weight: 400;
  color: $black;

  &:hover {
    text-decoration: underline;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  &.full-width {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}

.hide {
  display: none;
}
.show {
  display: block;
}

.zero-line {
  line-height: 0;
}

img {
  vertical-align: middle;
}

img.fluid {
  max-width: 100%;
}

.pull-right {
  display: flex;
  justify-content: end;
}

.clear{
  clear: both;
}

.hidden {
  display: none !important;
}

.isDesktop {
  display: none;

  @include from(lg) {
    display: block;
  }
}
.isDesktopVisible {
  opacity: 0;

  @include from(lg) {
    opacity: 1;
  }
}
.isMobile {
  display: block;

  @include from(lg) {
    display: none;
  }
}

.datepicker-dropdown {
  position: absolute;
  background: $white;
}

pre {
  text-align: left;
  border: 1px solid $default-grey;
  padding: 8px;
  border-radius: $radius-default;
  background: $yellow-light;
  font-size: $fs-xsmall;
}

.dp__input_icon {
  left: unset !important;
}

.cursor {
  cursor: pointer;
}