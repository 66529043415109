@import './variables';
@import './mixins';

.operation {
  .activate-title {
    margin: 34px 0 $margin-large;
    font-size: $fs-accent;
    line-height: 2.7rem;
    font-weight: normal;
    text-align: center;
    & span {
      font-weight: 800;
    }
    @include from (md) {
      margin: 32px 0;
      font-size: $fs-large;
      line-height: 3.2rem;
    }
  }
  .activate-content {
    @include from(lg) {
      padding-bottom: 100px;
      max-width: 90%;
      margin: 0 auto;
    }
    .activate-code {
      display: flex;
      flex-direction: column;
      .code-label {
        margin-bottom: 7px;
        font-size: $fs-small;
        line-height: 1.7rem;
        font-weight: 800;
        color: #C2C2C2;
      }
      .code {
        border: 1px solid #DCDCDC;
        padding: 12px $margin-large;
        font-size: $fs-accent;
        line-height: 2.7rem;
        font-weight: 800;
        text-transform: uppercase;
        color: $secondary-color;
      }
    }
    .activate-card {
      display: flex;
      flex-direction: column;
      .title-thanks {
        display: block;
        margin-bottom: 8px;
        margin-top: $margin-large;
        font-size: $fs-medium;
        line-height: 2.7rem;
        font-weight: 800;
        text-align: left;
      }
      .card-thanks {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 32px 20px;
        border: 1px solid #DCDCDC;
        background-color: #F5F9FF;
        @include from (lg) {
          justify-content: space-between;
          align-items: flex-start;
        }
        .preview-card {
          margin-top: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
          order: 2;
          @include from (lg) {
            order: 1;
            margin-top: 0;
          }
          .toggle {
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            .recto,
            .verso {
              margin: 0 10px;
              font-weight: 800;
              font-size: $fs-xsmall;
              color: $disabled;
            }

            .switch {
              display: inline-block;
              margin: 0px;
              position: relative;
              .switch-inner {
                display: block;
                margin: 0px;
                width: 60px;
                height: 30px;
                background: $primary-color;
                border-radius: $radius-xlarge;
                overflow: hidden;
                position: relative;
                transition: all 0.3s ease;
                &:after {
                  content: '';
                  width: 24px;
                  height: 24px;
                  background: $white;
                  border-radius: $radius-xlarge;
                  position: absolute;
                  left: 3px;
                  top: 3px;
                  text-align: center;
                  transition: all 0.3s ease;
                  padding: 5px 0px;
                }
              }
              input[type="checkbox"]{
                cursor: pointer;
                width: 50px;
                height: 25px;
                opacity: 0;
                position: absolute;
                top: 0;
                z-index: 1;
                margin: 0px;
                &:checked {
                  + {
                    label.switch-inner {
                      &:after {
                        left: 33px;
                      }
                    }
                  }
                }
              }
            }
          }
          .img-card {
            position: relative;
            .img-verso,
            .img-recto {
              width: 300px;
              border-radius: $radius-default;
              display: none;
              @include from (lg) {
                width: 280px;
              }
              @include from (xl) {
                width: 350px;
              }
            }
          }
          &.verso-preview{
            .verso {
              color: $independence-grey;
            }
            .img-verso {
              display: block;
            }
            .card-informations {
              display: block;
              .card-price {
                position: absolute;
                right:11px;
                top: 12px;
                font-size: $fs-huge;
                font-weight: 400;
                color: $white;
              }
              .card-message {
                position: absolute;
                top: 108px;
                left:8px;
                padding-right: 14px;
                .quote-img {
                  position: absolute;
                  left:0;
                  top:-25px;
                  opacity: 0.7;
                  width: 50px;
                }
                .message {
                  font-style: italic;
                  padding-left: 35px;
                  z-index: 3;
                  display: block;
                  position: relative;
                  font-size: $fs-xsmall;
                  color:$white;
                }
                .name {
                  padding-left: 35px;
                  font-weight: 600;
                  font-size: $fs-xsmall;
                  color:$white;
                }
              }
            }
          }
          &.recto-preview{
            .recto {
              color: $independence-grey;
            }
            .img-recto {
              display: block;
            }
            .card-informations {
              display: none;
            }
          }
          .nota {
            padding: 8px 0;
            font-size: $fs-xsmall;
            width: 300px;
            @include from (lg) {
              width: 280px;
            }
            @include from (xl) {
              width: 350px;
            }
          }
        }

        .card {
          padding: 20px 0;
          padding-top: 27px;
          position: relative;
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          height: 259px;
          border-radius: $radius-large;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3),0 10px 24px 0 rgba(0,0,0,0.3);

          @include from (md) {
            max-width: 380px;
            margin: 0 auto;
          }
          @include from (lg) {
            max-width: 380px;
          }
          .card-header {
            display: flex;
            justify-content: space-between;
            .title {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              min-width: 40%;
              border-top-right-radius: $radius-large;
              border-bottom-right-radius: $radius-large;
              text-transform: uppercase;
            }
            .price-group {
              display: flex;
              align-items: center;
              justify-content: end;
              margin-right: 20px;
              .price {
                margin-right: 10px;
                font-size: $fs-huge;
                line-height: 4rem;
                font-weight: 400;
                color: $white;
              }
            }
          }
          .card-message {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 0 20px;
            font-size: $fs-small;
            line-height: 1.7rem;
            color: $white;
            min-height: 56%;
            .message-title {
              z-index: 3;
              display: block;
              padding-bottom: 6px;
            }
            .message {
              z-index: 3;
              display: block;
            }
            .name {
              font-weight: 800;
              z-index: 3;
              display: block;
            }
          }
        }
        .thanks {
          order: 1;
          @include from (lg) {
            order: 2;
            margin-top: 0;
            width: 50%;
          }
          .thanks-details {
            font-size: $fs-xsmall;
            line-height: 1.7rem;
            font-weight: 800;
            color: #999999
          }
          textarea {
            height: 25rem;
            margin-top: 12px;
            border: 1px solid $grey-border;
            background-color: $white;
            font-size: $fs-xsmall;
            border-radius: $radius-small;
            @include from (lg) {
              height: 200px;
            }
          }
        }
      }


      .summary {
        margin-top: 32px;
        margin-bottom: 32px;
        width: 100%;
        .summary-title {
          display: block;
          margin-bottom: 8px;
          font-size: $fs-medium;
          line-height: 2.7rem;
          font-weight: 800;
          text-align: left;
        }
        ul {
          margin:0;
          list-style: none;
          display: flex;
          flex-direction: column;
          padding: 12px 20px;
          border: 1px solid #DCDCDC;
          background-color: #F5F9FF;
          li {
            padding: 14px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: $fs-small;
            line-height: 1.7rem;
            @include from (md) {
              font-size: $fs-accent;
              line-height: 2.7rem;
            }
            &:last-child {
              padding: 14px 10px;
              background-color:$white;
            }
            .summary-label {
              font-weight: 600;
            }
            .amount {
              font-weight: 800;
            }
          }
        }
      }
      button {
        align-self: flex-end;
      }
    }
  }


}
