@import './_variables';
@import './mixins';

.with-grid {
  background: $yellow-bg;

  .container {
    h1 {
      @include includeH1Header();
    }

    h2 {
      font-size: $fs-large;
      text-align: center;
    }

    .actions {
      margin-top: 82px;
      display: flex;

      &.sides {
        justify-content: space-between;
      }

      &.end {
        justify-content: flex-end;
      }

      &.center {
        justify-content: center;
      }

      a {
        display: flex;
        align-items: center;
        img {
          width: 16px;
          margin-right: 8px;
        }
      }
    }

    .introduction {
      margin: 82px 0 54px;
      color: $black;
    }

    .grid-container {
      display: grid;
      grid-template-columns: 100%;

      @include from(lg) {
        grid-template-columns: 50% 50%;

        &.large-right {
          grid-template-columns: 45% 55%;
        }
        &.large-left {
          grid-template-columns: 55% 45%;
        }
        &.x-large-right {
          grid-template-columns: 25% 75%;
        }
        &.x-large-left {
          grid-template-columns: 75% 25%;
        }
      }

      &.full {
        grid-template-columns: 100%;
      }

      .grid-bloc-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;

        @include from(lg) {
          flex-direction: row;
        }

        a {
          margin: 12px 6px;
        }
      }

      .grid-item {
        margin: $margin-large 0;
        background: $white;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #454B69;
        border-radius: $radius-small;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 34px 12px;

        font-weight: 600;
        font-size: $fs-large;
        color: #454B69;
        text-align: center;

        @include from(lg) {
          &:nth-child(2n) {
            margin-left: $margin-large;
          }
          &:nth-child(2n+1) {
            margin-right: $margin-large;
          }
        }

        img {
          width: 60px;
          height: 60px;
        }

        &:hover {
          text-decoration: none;
        }
      }

      &.full {
        grid-template-columns: 100%;
        .grid-item {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &.margin {
          margin-top: 62px;
      }

      .bloc {
        border-top: 1px solid $primary-color;
        border-bottom: 1px solid $primary-color;
        padding: 21px 40px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: $white;

        &:first-child {
          border-top-left-radius: $radius-default;
          border-bottom-left-radius: $radius-default;
          border-left: 1px solid $primary-color;
          border-bottom: 1px solid $primary-color;
          border-top: 1px solid $primary-color;
          @include isMax(md) {
            border-radius: 0;
            border-top-left-radius: $radius-default;
            border-top-right-radius: $radius-default;
            border-right: 1px solid $primary-color;
            border-bottom: none;
          }
        }
        &:last-child {
          border-top-right-radius: $radius-default;
          border-bottom-right-radius: $radius-default;
          border-right: 1px solid $primary-color;
          border-top: 1px solid $primary-color;
          border-bottom: 1px solid $primary-color;
          @include isMax(md) {
            border-radius: 0;
            border-bottom-left-radius: $radius-default;
            border-bottom-right-radius: $radius-default;
            border-left: 1px solid $primary-color;
            border-top: none;
          }
        }
        &:first-child:last-child {
          @include isMax(md) {
            border-radius: $radius-default;
            border-top: 1px solid $primary-color;
          }
        }

        @include from(md) {
          &.column {
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
          }
        }

        &.row {
          flex-direction: column;
          padding: 0;
          margin: 0;

          @include from(lg) {
            flex-direction: row;
          }

          .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            order: 2;
            overflow: hidden;
            background: $yellow-light;
            border-top-left-radius: $radius-default;
            border-bottom-right-radius: $radius-default;

            @include from(lg) {
              order: 1;
              width: 45%;
              border-top-left-radius: $radius-default;
              border-bottom-right-radius: 0;
            }

            .information {
              margin: 2.8rem;
              padding: 1.3rem 1.6rem;
              border: 1px solid rgba(0,0,0,0.1);
              border-radius: $radius-small;
              background: $white;

              span {
                display: block;
                line-height: 4rem;
              }

              small {
                font-size: $fs-xsmall;
                line-height: 1rem;
                color: #aaa;
              }
            }

            img {
              margin-top: 4rem;
              width: 100%;
              max-height: 100%;
              border-radius: $radius-default;

              @include from(lg) {
                border-bottom-right-radius: 0;
              }
            }
          }
          .right {
            padding:  2.8rem;
            order: 1;

            @include from(lg) {
              border: none;
              order: 2;
              width: 55%;
            }
          }
        }

        @include from(lg) {
          padding: 42px 80px;
        }

        svg.primary {
          path {
            stroke: $primary-color;
          }
        }
        svg.secondary {
          path {
            stroke: $secondary-color;
          }
        }

        &.primary {
          background-color: $primary-color;
        }
        &.light-primary {
          background-color: $yellow-light;


        }
        &.secondary {
          background-color: var(--variant-accent);
          border-color: var(--variant-accent);
          color: $white;

          a {
            color: $white;
          }
        }

        &.square {
          aspect-ratio: 1;
          padding-top: 0;
          padding-bottom: 0;
        }

        &.with-img {
          padding: 0;
        }
        &.with-movie {
          padding: 0;

          iframe {
            border-radius: $radius-default;
          }
        }

        &.right {
          text-align: right;
        }

        &.align-top {
          justify-content: flex-start;
        }

        h2 {
          margin-top: 0;
          margin-bottom: 28px;
          font-weight: 800;
          word-break: break-word;
        }

        h3 {
          font-size: $fs-huge;
          font-weight: 400;

          &.condensed {
            margin-top: -28px;
            margin-bottom: 28px;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &.partners {
          h2 {
            margin-bottom: 0;
          }
          h3 {
            margin-top: 82px;
            margin-bottom: 12px;
          }

          hr {
            margin: 0;
            margin-bottom: 22px;
            border-top-width: 3px;
            border-top-style: solid;
            width: 45%;

            &.yellow {
              border-color: $primary-color;
            }
            &.pink {
              border-color: #FC0388;
            }
            &.blue {
              border-color: #034EFC;
            }
          }
        }

        .logos {
          margin-top: 42px;
          display: grid;
          align-items: center;
          justify-items: center;
          grid-template-columns: auto auto;
          grid-gap: 20px;

          @include from(md) {
            grid-template-columns: auto auto auto auto;
            grid-gap: 80px;

            &.condensed {
              grid-gap: 0;
            }
          }

          &.condensed {
            grid-gap: 10px;
          }
        }

        .pictos-container {
          margin-top: 22px;
          a {
            margin-right: 28px;
            text-decoration: none;
          }
        }

        .wrapper {
          position: relative;


          .actions {
            width: 35px;
            position: absolute;
            top: 35px;

            &.prev {
              left: 0;
            }
            &.next {
              right: 0;
            }
          }

          ul {
            display: flex;
            list-style: none;
            position: absolute;
            align-items: center;
            padding: 0;

            li {
              float: left;
              margin-right: 12px;

              img {
                width: 200px;
              }
            }
          }
        }

        .text-banner {
          text-align: center;
          margin: $margin-large 0;

          @include from(md) {
            margin: 0;
          }

          &::first-line {
            color: $primary-color;
            font-size: $fs-medium;
            text-transform: uppercase;
            font-weight: 800;
          }
        }

        a.btn {
          text-decoration: none;
        }

        .wrapper {
          margin-top: 25px;
          @include from(md) {
            margin-top: 34px;
          }
          .carousel {
            width: 284px;
            margin: 0 auto;
            position: relative;
            @include from(md) {
              width: 597px;
            }
            .viewport {
              margin: 0 auto;
              height: 100px;
              overflow: hidden;
              position: relative;
              @include from(md) {
                height: 140px;
              }
              ul {
                position: absolute;
                list-style: none;
                display: flex;
                align-items: center;
                padding: 0;
                @include from(md) {
                  margin: 0 0 0 9px;
                }
                li {
                  float: left;
                  margin-right: 12px;
                  @include from(md) {
                    margin-right: 18px;
                  }

                  &.selected {
                    cursor: pointer;
                    .card-visual {
                      img {
                        width: 120px;
                        border: 4px solid $grey-border;
                        @include from(md) {
                          width: 200px;
                        }
                      }
                    }
                  }
                  .card-visual {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                      width: 70px;
                      border-radius: $radius-default;
                      @include from(md) {
                        width: 170px;
                      }
                    }
                    .card-name {
                      display: none;
                      margin-top: 6px;
                      font-size: $fs-xxsmall;
                      text-align: center;
                      font-weight: 800;
                      color: $cultured;
                      min-height: 50px;
                      @include from(lg) {
                        font-size: $fs-small;
                      }
                    }
                  }
                }
              }
            }
            .prev,
            .next {
              position: absolute;
              top: 23px;
              padding: 2px 10px;
              @include from(md) {
                top: 48px;
              }
              img {
                width: 8px;
                @include from(md) {
                  width: 15px;
                }
              }
            }
            .prev {
              left: -31px;
              @include from(lg) {
                left: -61px;
              }
            }
            .next {
              right: -31px;
              @include from(lg) {
                right: -61px;
              }
            }
          }
        }

        .card-name {
          text-align: center;
          font-weight: 800;
        }

        .btn-container {
          display: flex;
          justify-content: center;
          margin-top: $margin-large;
        }

        a {
          text-decoration: underline;
        }
      }
    }

    dl {
      margin-bottom: 40px;
      dt {
        margin-top: 40px;
        font-weight: 600;
        color: $black;
      }
      dd {
        margin-top: 26px;
        margin-left: 0;

        a {
          text-decoration: underline;
        }
      }
    }

    .foot {
      text-align: right;
      font-size: $fs-xsmall;
      color: $black;
      text-decoration: underline;
      margin: 16px 0 82px;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 100%;

  &.grid-1 {
    grid-template-columns: 100%;
  }
  &.grid-2 {
    grid-template-columns: 50% 50%;
  }
  &.grid-3 {
    grid-template-columns: 33% 34% 33%;
  }
  &.grid-4 {
    grid-template-columns: 25% 25% 25% 25%;
  }
  &.grid-gap-xs {
    grid-gap: 4px;
  }
  &.grid-gap-sm {
    grid-gap: 12px;
  }
  &.grid-gap-lg {
    grid-gap: 16px;
  }
  &.grid-gap-xl {
    grid-gap: $margin-large;
  }

  @include from('sm') {
    &.grid-sm-2 {
      grid-template-columns: 50% 50%;
    }
    &.grid-sm-3 {
      grid-template-columns: 33% 34% 33%;
    }
  }

  @include from('md') {
    &.grid-md-2 {
      grid-template-columns: 50% 50%;
    }
    &.grid-md-3 {
      grid-template-columns: 33% 34% 33%;
    }
  }

  @include from('lg') {
    &.grid-lg-2 {
      grid-template-columns: 50% 50%;
    }
    &.grid-lg-3 {
      grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
    }
  }

  .bloc {
    margin-top: 62px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}