@import './_variables';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  font-weight: 800;
  text-align: center;
  border: 2px solid;
  padding: 13px 26px;
  font-size: $fs-default;
  line-height: 2.4rem;
  border-radius: $radius-small;
  transition: all 0.3s linear;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }

  &.rounded {
    border-radius: $radius-full;
  }

  &.not-button {
    cursor: default !important;
  }

  &.close {
    border-radius: $radius-full;
    padding: 0  15px;
  }

  &.btn-hover-shadow {
    &:hover {
      box-shadow: 11px 10px 24px 0px rgba(0, 0, 0, 0.19);
    }
  }

  &.small {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &.btn-primary {
    border-color: $primary-color;
    background-color: $primary-color;
    color: $white;

    &.btn-ghost {
      border-color: $white;
    }
    &:hover {
      border-color: $primary-color-darken;
      background-color: $primary-color-darken;
    }

    &.btn-outline {
      color: $black;

      &:hover {
        background-color: $white;
      }
    }
  }

  &.btn-secondary {
    border-color: $secondary-color;
    background-color: $secondary-color;
    color: $white;

    &.btn-hover-darken {
      &:hover {
        border-color: $secondary-color-darken;
        background-color: $secondary-color-darken;
      }
    }
    &:hover {
      border-color: $secondary-color-darken;
      background-color: $secondary-color-darken;
    }
  }

  &.btn-default {
    border-color: transparent;
    background-color: transparent;
    color: $black;
  }

  &.btn-white {
    border-color: $white;
    background-color: $white;
    color: $black;
  }

  &.btn-grey {
    border-color: $grey-button;
    background-color: $white;
    color: $grey-button;
  }

  &.btn-grey-filled {
    border-color: $footer;
    background-color: $footer;
    color: $white;
    &:hover {
      border-color: $spanish-grey;
      background-color: $spanish-grey;
    }
  }

  &.btn-default-inverted {
    border-color: $black;
    background-color: $black;
    color: $white;
  }

  &.btn-danger {
    border-color: $danger-color;
    background-color: $danger-color;
    color: $white;
  }

  &.btn-search {
    border-color: $grey-button;
    background-color: $white;
    color: $grey-button;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='35' height='35'><circle style='fill:%23b2b2b2;fill-opacity:1;stroke:%23b2b2b2;stroke-width:0' cx='17.5' cy='-17.5' transform='scale(1 -1)' r='17.5'/><path style='fill:%23fff;fill-opacity:1;stroke-width:.627041' fill='%230147c3' d='m23.067 18.585-6.01 6.01c-.551.551-1.454.551-2.004 0l-.502-.501a1.42 1.42 0 0 1 0-2.003l4.586-4.587-4.586-4.587a1.42 1.42 0 0 1 0-2.003l.501-.501a1.42 1.42 0 0 1 2.004 0l6.01 6.011c.296.295.428.692.406 1.08.023.389-.109.785-.405 1.08z'/></svg>");
    padding: 0 15px;
    border-radius: $radius-full;
    background-size: 29px;
    background-repeat: no-repeat;
  }

  &.btn-outline {
    background: $white;
  }

  &.btn-google {
    padding-left: 7px;
    border-color: #db4437;
    background-color: #db4437;
    color: $white;

    &:hover {
      border-color: #e57368;
      background-color: #e57368;
    }

    svg {
      width: 32px;
      height: 32px;
      margin: 7px;
      margin-left: 0;
      padding: 0;
      background: $white;
      padding: 4px;
      border-radius: $radius-small;
    }
  }

  &.btn-disabled {
    cursor: not-allowed;
    background-color: $cultured;
    border-color: $cultured;
  }
}

.content {
  .btn {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    line-height: 1.6rem;
    display: inline-block;
  }
}

.m-button {
  display: inline-flex;
  width: auto;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  font-size: $fs-default;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 18px;
  border-radius: $radius-small;
  transition: background 0.2s linear;
  border: none;
  &:hover {
    text-decoration: none;
  }
  &.yellow {
    background: $primary-color;
    color: $white;
    &:hover {
      background: $primary-color-darken;
    }
    &:focus {
      outline: 2px solid $primary-color-darken;
    }
  }
  &.blue {
    background: $secondary-color;
    color: $white;
    &:hover {
      background: $secondary-color-darken;
    }
    &:focus {
      outline: 2px solid $secondary-color-darken;
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
}

#catalog {
  border-radius: $radius-full;
}

.not-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  text-decoration: none;
  font-weight: 800;
  text-align: center;
  border: 2px solid;
  padding: 13px 26px;
  font-size: $fs-default;
  line-height: 2.4rem;
  transition: all 0.3s linear;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: $radius-full;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }


  &.btn-primary {
    border-color: $primary-color;
    background-color: $primary-color;
    color: $white;
  }

  &.btn-secondary {
    border-color: $secondary-color;
    background-color: $secondary-color;
    color: $white;
  }
}