@import './_variables';

input,
textarea {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

main {
  display: block;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-small {
  font-size: $fs-small !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.form-control {
  &::placeholder {
    color: $grey;
    opacity: 0.7;
  }

  &.invalid {
    border: 2px solid $error !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  &.valid {
    border: 2px solid $success !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

input:not([type='checkbox']),
select,
textarea {
  width: 100%;

  background: $extra-light-grey;
  border: 1px solid $extra-light-grey;
  border-radius: $radius-full;
  padding: 1rem;
  &:focus {
    outline: none;
  }
}

textarea {
  border-radius: 0;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;

  &.invalid {
    .form-check-label {
      color: $error !important;
      font-style: italic;
    }
  }
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: $grey;
}

.form-check-label {
  margin-left: 1rem;
  margin-bottom: 0;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.form-inline .form-check {
  width: 100%;
}

.input-group-append {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-append {
  margin-left: -1px;
}

@media (min-width: 576px) {
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: $fs-xsmall;
  line-height: 1.5;
  border-radius: $radius-small;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: white;
  border: 2px solid $primary-color;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: $primary-color;
}

.control input:checked ~ .control_indicator {
  background: $primary-color;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: $primary-color;
}
.control input:disabled ~ .control_indicator {
  background: $light-grey;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-radio .control_indicator {
  border-radius: $radius-half;
}

.control-radio .control_indicator:after {
  left: 3px;
  top: 3px;
  height: 10px;
  width: 10px;
  border-radius: $radius-half;
  background: $white;
  transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
  background: $spanish-grey;
}
.control-radio .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: $primary-color;
  border-radius: $radius-xlarge;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

.input-block {
  position: relative;
  input,
  select {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    font-size: $fs-default;
    line-height: 1.6rem;
    padding: 15px 15px;
    outline: none;

    &:not(:placeholder-shown) {
      + {
        .placeholder {
          transform: scale(0.8) translateY(-40px);
          background: $white;
        }
        color: $grey;
        border-color: $grey;
      }
    }
    &:focus {
      border-color: $primary-color;
      + {
        .placeholder {
          transform: scale(0.8) translateY(-40px);
          background: $white;
        }
      }
      &:not(:placeholder-shown) {
        + {
          .placeholder {
            transform: scale(0.8) translateY(-40px);
            background: $white;
          }
          color: $grey;
          border-color: $grey;
        }
      }
    }
  }
  .placeholder {
    position: absolute;
    padding: 0 4px;
    color: $independence-grey;
    font-size: $fs-default;
    top: 50%;
    transform: translateY(-50%);
    left: 17px;
    transition: all 0.2s;
    transform-origin: 0% 0%;
    background: none;
    pointer-events: none;
  }
}

.input-textarea {
  position: relative;
  textarea {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    font-size: $fs-default;
    line-height: 1.6rem;
    padding: 15px 15px;
    outline: none;

    &:not(:placeholder-shown) {
      + {
        .placeholder {
          transform: scale(0.8) translateY(-40px);
          background: $white;
        }
        color: $grey;
        border-color: $grey;
      }
    }
    &:focus {
      border-color: $primary-color;
      + {
        .placeholder {
          transform: scale(0.8) translateY(-40px);
          background: $white;
        }
      }
      &:not(:placeholder-shown) {
        + {
          .placeholder {
            transform: scale(0.8) translateY(-40px);
            background: $white;
          }
          color: $grey;
          border-color: $grey;
        }
      }
    }
  }
  .placeholder {
    position: absolute;
    padding: 0 4px;
    color: $independence-grey;
    font-size: $fs-default;
    top: 10px;
    left: 17px;
    transition: all 0.2s;
    transform-origin: 0% 0%;
    background: none;
    pointer-events: none;
  }
}


@keyframes s-ripple {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0.01;
    transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.control-radio input {
  .control_indicator::before {
    animation: s-ripple 250ms ease-out;
  }
}
.control-radio input:checked {
  .control_indicator::before {
    animation-name: s-ripple-dup;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
}
