@import './variables';
@import './mixins';

.commande-validee {
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .header-title {
      text-align: center;
      margin-bottom: 32px;
      h1 {
        font-size: $fs-huge;
        line-height: 38px;
        font-weight: 800;
      }
      .subtitle {
        font-size: $fs-large;
      }
    }

    .header-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        margin:8px 0;
      }
      .btn {
        padding:20px 45px;
        font-size: $fs-accent;
      }
      .simple-link {
        font-size: $fs-accent;
        font-weight: 800;
        color:$grey-button
      }
    }
  }

  .our-friends {
    border-radius: $radius-accent;
    background-color: $yellow-light;
    background-image: url('/img/commande-validee/pattern.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: $margin-large;
    margin: 42px 0;
    @include boxShadow();

    @include isMax('sm') {
      margin: 12px;
    }

    .grid {
      .item {
        margin: 12px $margin-large;
        padding: 12px;
        background: $white;
        border-radius: $radius-accent;
        box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);

        .input-item {
          margin-top: $margin-large;

          svg {
            right: 0;
          }

          &:last-child {
            margin-bottom: $margin-large;
          }
        }
      }
    }

    .actions {
      margin-top: 12px;
      div {
        display: flex;
        justify-content: center;

        img {
          cursor: pointer;
          width: 32px;
          height: 32px;
        }

        &:last-child {
          justify-content: end;
          margin-right: 25px;

          button {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .my-friend {
    border-radius: $radius-accent;
    background-color: #d0def8;
    padding: $margin-large;
    margin: 42px 0;
    @include boxShadow();

    @include isMax('sm') {
      margin-left: 12px;
      margin-right: 12px;
    }

    display: flex;
    flex-direction: column;

    h2,
    form {
      margin: 0 25px;
    }

    form {
      margin-top: $margin-large;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include isMax('md') {
        flex-direction: column;
      }

      .input-item {
        width: 33%;
        @include isMax('md') {
          width: 100%;
          margin-bottom: 12px;
        }

        .input-block {
          width: 100%;

          input {
            background: $white;
          }
        }
      }
    }

    button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .grid-container {
    margin-top: 0 !important;
    margin-bottom: 62px;
    h1 {
      background: none !important;
      padding: 16px 0 !important;
      border-bottom: none !important;
    text-transform: uppercase;
    }
  }
}

@media only screen and (min-width: $layout-lg) {
  .commande-validee {
    .header {
      padding: 60px 0;
      h1 {
        font-size: $fs-xhuge;
        line-height: 1.18;
        font-weight: initial;
      }
      .subtitle {
        font-size: $fs-xhuge;
        line-height: 1.18;
      }
    }
  }
}

form.send-documents {
  margin: 42px 0;

  &.with-decorations {
    border-radius: $radius-accent;
    background-color: #d0def8;
    padding: 36px;
    @include boxShadow();
  }

  h2 {
    font-size: $fs-medium;
    margin-bottom: $margin-large;
  }

  p {
    margin-bottom: $margin-large;
  }

  label {
    font-weight: 800;
  }

  .grid .input-item input,
  textarea {
    border-radius: $margin-large;
  }


  .grid {
    @include from(sm) {
      &:first-child {
        padding-right: 16px;
      }
      &:last-child {
        padding-left: 16px;
      }
    }
    .input {
      label {
        // width: 66%;
        .file-picker {
          margin-top: 0.5rem;
          background-color: $secondary-color;
          height: 46px;
          border-radius: $radius-xlarge;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          @include boxShadow();

          .picto,
          .label {
            padding-left: 16px;
            color: $white;
          }

          .label {
            padding: 0 16px;
          }
        }
      }
      input {
        display: none;
      }

    }
  }

  .files {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: flex-start;

    .file {
      border-radius: $radius-default;
      background: $light-grey;
      padding: 16px;
      position: relative;
      margin: 8px;
      cursor: pointer;
      @include boxShadow();

      img {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 12px;
      }

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .input-textarea {
    margin-top: $margin-large;

    textarea {
      background-color: $extra-light-grey;
      height: 180px;
    }
  }

  .btn {
    margin-top: $margin-large;
  }
}

.notification {
  margin: 0 25px;
  color: #00D26A;
  font-weight: 600;
  text-align: right;

  &.error {
    color: $danger-color;
  }
}