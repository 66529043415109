@import './variables';
@import './mixins';

.btn-show-all {
  padding: 15px 34px;
  border-radius: $radius-small;
  background-color: #eef4ff;
  border: none;
  font-size: $fs-small;
  font-weight: 800;
  &:focus {
    outline: none;
  }
}

.mon-compte {
  form {
    padding: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .submit-container {
      display: flex;
      justify-content: start;
      margin-top: 3rem;
      @include from(lg) {
        justify-content: end;
      }
    }
  }

  .input-block {
    margin-bottom: $margin-medium;
  }

  .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: unset;

    @include isMax(md) {
      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      display: flex;
      margin: 0;

      input[type='checkbox'] {
        font-size: $fs-xsmall;
        padding: 0;
        -webkit-appearance: none;
        appearance: none;
        background-color: $white;
        margin: 0 12px 0 0;
        color: $primary-color;
        width: 20px;
        height: 20px;
        border: 1px solid $primary-color;
        display: grid;
        place-content: center;
        &:before {
          content: "";
          width: 12px;
          height: 12px;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          background-color: $primary-color;
        }
        &:checked {
          &::before {
            transform: scale(1);
          }
        }
      }

      span {
        margin-left: 3rem;
      }
    }
  }

  #play-wallet {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fc9e34;
    min-height: 84px;
    color: $white;
    font-weight: 800;

    * {
      margin: 0 6px;
      text-align: center;
    }

    img {
      max-height: 48px;
    }

    button {
      padding: 0 12px;
    }
  }
  .header {
    .topbar {
      margin: 2rem 0 0 0;
      text-transform: uppercase;
      a {
        display: inline-block;
        white-space: nowrap;
        font-weight: 800;
        margin: 0.8rem 1.5rem;
        text-decoration: none;

        &.active {
          border-bottom: 2px solid $black;
        }
        &.primary {
          color: $primary-color;

          &.active {
            border-bottom-color: $primary-color;
          }
        }
        &.secondary {
          color: $secondary-color;

          &.active {
            border-bottom-color: $secondary-color;
          }
        }
      }
    }
  }

  .navigation {
    display: block;
    background-color: $white;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.04);
    .navigation-list {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      height: 88px;
      overflow-x: auto;
      .navigation-list-item {
        padding: 0 12px;
        height: 100%;
        display: flex;
        position: relative;
        .navigation-link {
          position: relative;
          display: flex;
          align-items: center;
          font-weight: 800;
          font-size: $fs-accent;
          color: $grey-button;
          width: 100%;
          cursor: pointer;
          text-decoration: none;

          .navigation-name {
            text-align: center;
          }
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          width: 0;
          transition: all 0.3s ease;
        }
        &.active {
          .navigation-link {
            color: $primary-color;
            border-bottom: 2px solid $primary-color;
          }
        }
        &.hidden-on-mobile {
          display: none !important;
        }
      }
    }
    &.profil-navigation--stick {
      position: fixed;
      width: 100%;
      top: 80px;
      left: 0;
      z-index: 7;
    }
  }

  &.profil {
    padding-bottom: 30px;
    h2 {
      font-size: $fs-medium;
      padding: 3rem;
    }
    .profil-infos,
    .auth-inputs {
      :last-child {
        margin-bottom: 0;
      }
    }

    .profil-container {
      h3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 3rem;

        .label {
          margin-right: 2rem;
        }
        .btn {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
      .profil-wrapper {
        form {
          @include from(lg) {
            .profil-infos {
              padding-right: 24px;
            }
            .profil-interets {
              padding-left: 24px;
            }
          }

          .profil-interets {
            .section-title {
              margin-top: 20px;
              @include from(lg) {
                margin-top: 0;
              }
              font-weight: 800;
              font-size: $fs-medium;
              margin-bottom: $margin-medium;
            }
          }
        }
      }
    }
    .invoices {
      .wallet {
        h3 {
          display: flex;
          flex-direction: row;
          align-items: center;
          .label {
            margin-right: 2rem;
          }
          .btn {
            padding-left: 2rem;
            padding-right: 2rem;
          }
        }
        .wallet-details {
          padding: 12px 12px 3rem;
          border: 1px solid rgba(0, 0, 0, 0.1);
          .btn {
            padding: 12px 32px;
            height: 48px;
            font-size: $fs-small;
            line-height: 19px;
          }
        }
      }
    }

    .connexion-profil {
      .auth {
        .auth-inputs {
          .hash-password {
            position: absolute;
            bottom: 61px;
            left: 0;
            display: block;
            padding: $margin-medium;
            background-color: #253e67;
            border-radius: $radius-large;
            width: 257px;
            visibility: hidden;
            z-index: 9;
            &::before {
              display: none;
              content: '';
              position: absolute;
              border-right: 10px solid #253e67;
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              top: 50%;
              transform: translateY(-50%);
              right: 100%;
            }
            .hash-title {
              margin-bottom: 10px;
              font-size: $fs-small;
              line-height: 1.7rem;
              font-weight: 800;
              color: $white;
            }
            .hash-condition {
              position: relative;
              padding-left: 12px;
              font-size: $fs-small;
              line-height: 1.7rem;
              color: $white;
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: $radius-full;
                background-color: $white;
              }
              &.condition-ok {
                color: $primary-color;
                font-weight: 800;
              }
            }
          }
          .icon-eye {
            padding: 0;
            position: absolute;
            width: 30px;
            height: 30px;
            background: url('/icons/eye.svg') no-repeat center center;
            background-size: contain;
            right: 10px;
            top: 10px;
            border: none;
          }

          .error {
            margin-top: -14px;
            margin-bottom: 16px;
          }
        }
        .logout-other-sessions {
          flex: 0 0 100%;
        }
      }
    }
  }

  &.mes-offres {
    .tab-content {
      margin-bottom: $margin-large;
    }
    .waiting {
      width: 100%;
      background-color: #EFF3FA;
      border-radius: $radius-accent;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
      margin-top: 18px;
      padding: $margin-large;

      .title {
        font-weight: 800;
        color: #507EC9;
      }

      .bloc-timeline {
        background-color: #FCFBFB;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: $radius-default;
        padding: 64px 26px 26px;
        margin-top: $margin-medium;

        .timeline {
          padding: 0 64px;
          display: block;

          .noUi-marker-large {
            background: transparent;
          }
          .noUi-target {
            border-radius: $radius-full;
            box-shadow: none;
            background-color: transparent;
            border: 1px solid $primary-color;

            .noUi-pips-horizontal {
              height: 0;
            }
          }
          .noUi-connects {
            border-radius: $radius-full;
          }
          .noUi-connect {
            background-color: $primary-color;
          }
          .noUi-horizontal .noUi-handle {
            border-radius: $radius-full;
            width: 30px;
            height: 30px;
            border: 1px solid $primary-color;
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
            top: -7px;
            cursor: unset;
          }
          .noUi-handle:before {
            content: url("/icons/play.png");
            width: 18px;
            height: 18px;
            background-color: $white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: $radius-full;
            padding-left: 3px;
          }
          .noUi-handle:after {
            display: none;
          }
          .noUi-horizontal .noUi-tooltip {
            top: 120%;
            bottom: inherit;
            border: none;
            font-weight: 800;
            font-size: $fs-accent;
          }
          .noUi-value {
            font-size: $fs-small;
          }
        }

        .blank {
          height: 46px;
        }

        .form {
          display: flex;
          flex-direction: column;

          .submit {
            margin-top: 12px;
            text-align: center;
          }
        }
      }
    }
    .offers {
      padding: 30px 0 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: $margin-large;
      .offers-item {
        margin: 3px 0;
        flex: 0 0 100%;
        padding: 5px 3px;
        border-radius: $radius-default;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03),
          0 10px 24px 0 rgba(0, 0, 0, 0.08);
        background-color: white;
        transition: all 0.3s ease-in;
        &.hover {
          transform: translateY(-5px);
        }
        .offer {
          padding: 10px;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          .usedAt {
            font-size: $fs-xsmall;
            line-height: 1.8rem;
            color: $grey-button;
          }

          .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
            img {
              max-width: 100%;
              transition: all 0.5s ease;
              &.hover {
                transform: scale(1.1);
              }
            }

            &.medium {
              img {
                max-width: 50%;
              }
            }
          }
          .offer-details {
            text-align: left;
            padding: 10px $margin-large;
            .name {
              font-size: $fs-accent;
            }
            .duration {
              font-size: $fs-medium;
              font-weight: 800;
              color: $secondary-color;
            }
          }
          .offer-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .activated-offer {
              padding: 10px $margin-large;
              border-radius: $radius-small;
              border: none;
              color: white;
            }
            .share-offer {
              width: 30px;
              height: 30px;
              padding: 0;
              background-color: transparent;
              border: none;
            }
          }
        }
      }
    }
  }

  &.mes-cartes {
    padding-bottom: 0;

    .tab-content {
      background: $white;
      margin-top: 3rem;

      &.full {
        padding-bottom: 100px;
      }

      .newCards,
      .usedCards {
        .head {
          background: $light-grey;
          font-size: $fs-xsmall;
          font-weight: 800;
          text-transform: uppercase;
          padding: 1rem 0;
          align-items: center;
        }

        .head,
        .body {
          display: flex;
          flex-wrap: wrap;
          text-align: center;
        }
      }

      .newCards,
      .usedCards {
        .head,
        .body {
          .expiration-date {
            order: 2;
            width: 50%;
          }
          .price {
            order: 3;
            width: 50%;
          }
          .quantity {
            order: 4;
            width: 50%;
          }
          .product {
            order: 1;
            width: 50%;
          }
          .actions {
            order: 5;
            width: 100%;
          }
        }

        .body {
          font-size: $fs-medium;
          border-bottom: 1px solid $light-grey;
          padding-bottom: 2rem;
          margin-bottom: 2rem;

          div {
            padding: 0.6rem 0;
          }
          .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 1rem;
            padding-right: 1rem;

            .btn {
              width: 100%;
              padding: 0.6rem 1.2rem;
            }
          }
        }
      }

      .usedCards {
        .activation-date {
          order: 2;
          width: 50%;
        }
        .code {
          order: 1;
          width: 100%;
        }
        .price {
          order: 3;
          width: 50%;
        }
      }

      .sentCards {
        .head {
          display: none;
        }
        .card-item {
          padding: 8px $margin-medium;
          border-bottom: 1px solid $grey-border;
          display: flex;
          align-items: center;
          cursor: pointer;
          .more-details {
            margin-right: 20px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary-color;
            border-radius: $radius-half;
            flex: 0 0 auto;
            img {
              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
          .code-card {
            font-size: $fs-default;
            font-weight: 800;
            .card-recipient,
            .card-number {
              display: inline-block;
              margin-left: 8px;
              color: $primary-color;
            }
            .card-recipient {
              margin-left: 0;
            }
            .card-price,
            .card-expired {
              display: block;
              font-size: $fs-small;
              color: black;
              font-weight: 400;
            }
          }
          &.active {
            border: none;
            img {
              transform: rotate(180deg);
            }
          }
          .card-body {
            display: none;
          }
        }
        .details {
          padding: $margin-medium;
          display: none;
          border-bottom: 1px solid $grey-border;
          .detail-item {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            .item-title {
              font-size: $fs-default;
              font-weight: 800;
              margin-right: 20px;
              flex: 1;
            }
            .item-info {
              flex: 2;
            }
            .item-actions {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex: 2;
              .item-action {
                margin-right: 20px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary-color;
                border-radius: $radius-half;
                border: none;
                &:focus {
                  outline: none;
                }
                img {
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.mon-parrainage {
    .its-win-bg {
      background: url('/img/home/parrainage-bg.png') center no-repeat;
      background-size: cover;
      width: 100%;
      height: 400px;
    }
    #its-win {
      .its-win-wrapper {
        padding: 20px;
        max-width: 90%;
        margin: -300px auto 0;
        background-color: $white;
        border-radius: $radius-default;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03),
          0 10px 24px 0 rgba(0, 0, 0, 0.08);
        .card-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h1 {
            font-size: $fs-huge;
            line-height: 34px;
            font-weight: 800;
          }
        }
        .card-content {
          margin-top: 20px;
          .card-description {
            display: flex;
            flex-direction: column;
            p {
              color: #727a8e;
            }
            .sponsors-slider {
              .slider {
                position: relative;
                input[type='range'] {
                  --trackBg: $white;
                  --progressBg: #ff9c3b;
                  --webkitProgressPercent: 0%;
                }

                input[type='range'] {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  height: 13px;
                  width: 100%;
                  margin: 0;
                  padding: 0;
                }
                input[type='range']:focus {
                  outline: none;
                }

                input[type='range']::-webkit-slider-thumb {
                  -webkit-appearance: none;
                  appearance: none;
                  width: 26px;
                  height: 26px;
                  border: 5px solid $white;
                  background: $white;
                  margin-top: -7px;
                  box-shadow: inset 4px 5px 20px rgba(255, 156, 59, 1),
                    0 2px 6px rgba(0, 0, 0, 0.2);
                  border-radius: $radius-full;
                }
                input[type='range']::-moz-range-thumb {
                  -webkit-appearance: none;
                  appearance: none;
                  width: 26px;
                  height: 26px;
                  border: 5px solid $white;
                  background: $white;
                  margin-top: -7px;
                  box-shadow: inset 4px 5px 20px rgba(255, 156, 59, 1),
                    0 2px 6px rgba(0, 0, 0, 0.2);
                  border-radius: $radius-full;
                }
                input[type='range']::-ms-thumb {
                  -webkit-appearance: none;
                  appearance: none;
                  width: 26px;
                  height: 26px;
                  border: 5px solid $white;
                  background: $white;
                  margin-top: -7px;
                  box-shadow: inset 4px 5px 20px rgba(255, 156, 59, 1),
                    0 2px 6px rgba(0, 0, 0, 0.2);
                  border-radius: $radius-full;
                }

                input[type='range']::-webkit-slider-runnable-track {
                  height: 13px;
                  background-image: linear-gradient(
                    90deg,
                    var(--progressBg) var(--webkitProgressPercent),
                    var(--trackBg) var(--webkitProgressPercent)
                  );
                  border-radius: $radius-full;
                  border: 1px solid #e5e5e5;
                }
                input[type='range']::-moz-range-track {
                  height: 13px;
                  background-color: $white;
                  border-radius: $radius-full;
                  border: 1px solid #e5e5e5;
                }
                input[type='range']::-ms-track {
                  height: 13px;
                  background-color: $white;
                  border-radius: $radius-full;
                  border: 1px solid #e5e5e5;
                }

                input[type='range']::-moz-range-progress {
                  height: 13px;
                  background: linear-gradient(
                    45deg,
                    var(--gradient-end) 0%,
                    var(--gradient-start) 100%
                  );
                  border-radius: $radius-full;
                }
                input[type='range']::-ms-fill-lower {
                  height: 13px;
                  background: linear-gradient(
                    45deg,
                    var(--gradient-end) 0%,
                    var(--gradient-start) 100%
                  );
                  border-radius: $radius-full;
                }

                .slider-indicator {
                  display: block;
                  font-size: $fs-small;
                  color: #c2c2c2;
                  text-align: center;
                  position: absolute;
                  bottom: -25px;
                  left: 0;
                  .indicator-number {
                    margin-right: 2px;
                  }
                }
              }
            }
          }
          .card-jackpot {
            margin-top: 30px;
            p {
              color: #727a8e;
            }
            .jackpot-total {
              font-size: $fs-xxhuge;
              line-height: 58px;
              font-weight: 800;
              background: linear-gradient(
                45deg,
                var(--gradient-end) 0%,
                var(--gradient-start) 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
    #sponsoring-link {
      margin-top: 50px;
      .sponsoring-link-wrapper {
        h2 {
          font-size: $fs-huge;
          line-height: 34px;
          font-weight: initial;
        }
        .sponsoring-options {
          margin-top: 40px;
          display: flex;
          flex-wrap: wrap;
          .sponsoring-code,
          .sponsoring-socials {
            border-radius: $radius-default;
            flex: 0 0 100%;
            padding: 20px;
            height: 325px;
          }
          .sponsoring-code {
            background: linear-gradient(
              127deg,
              var(--gradient-end) 0%,
              var(--gradient-start) 100%
            );
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03),
              0 10px 24px 0 rgba(0, 0, 0, 0.08);
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .code-header {
              margin-bottom: 32px;
              display: flex;
              flex-direction: column;
              .label {
                font-size: $fs-medium;
              }
              .code {
                font-size: $fs-huge;
                line-height: 38px;
                font-weight: 800;
              }
            }
            .code-link-text {
              font-size: $fs-small;
            }
            .share-input-group {
              position: relative;
              margin-top: 32px;
              .share-input {
                border-radius: 0;
                border: 1px solid $white;
                color: white;
                background: transparent;
                padding: 25px;
                font-size: $fs-small;
                font-weight: 800;
                padding: 14px 70px 14px 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .copy-button {
                position: absolute;
                right: 0;
                top: 0;
                width: 80px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .codecopy {
                  color: white;
                  width: 100%;
                  height: 100%;
                  svg {
                    fill: white;
                    width: 24px;
                    height: auto;
                  }
                }
              }
            }
          }
          .sponsoring-socials {
            margin-top: 20px;
            background-color: #fffdf8;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.02),
              0 1px 20px 0 rgba(0, 0, 0, 0.04);
            display: flex;
            flex-direction: column;
            .choose-option {
              font-size: $fs-medium;
              font-weight: bold;
              background: linear-gradient(
                45deg,
                var(--gradient-end) 0%,
                var(--gradient-start) 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .choose-explication {
              margin-top: 32px;
              font-size: $fs-small;
              color: #727a8e;
            }
            .social-list {
              margin-top: 32px;
              display: flex;
              align-items: center;
              .social-item {
                margin-right: 19px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: $radius-full;
                border: 1px solid $primary-color;
                width: 56px;
                height: 56px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
    #referrals {
      margin-top: 50px;
      .referrals-wrapper {
        padding: 20px 0;
        max-width: 90%;
        margin: 0 auto;
        background-color: $white;
        border-radius: $radius-default;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03),
          0 10px 24px 0 rgba(0, 0, 0, 0.08);
        h2 {
          margin-bottom: 30px;
          padding-left: 20px;
          font-size: $fs-huge;
          line-height: 34px;
        }
        .label {
          padding-left: 20px;
          font-size: $fs-default;
          font-weight: 800;
          color: #959595;
        }
        .referrals-list {
          padding: 0;
          margin: 21px 0 32px;
          list-style: none;
          .referrals-item {
            padding: 12px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:nth-child(odd) {
              background-color: #fffdf8;
            }
            &:nth-child(3n + 1) {
              .referrals-initial {
                color: $primary-color;
              }
            }
            &:nth-child(3n + 2) {
              .referrals-initial {
                color: $secondary-color;
              }
            }
            &:nth-child(3n + 3) {
              .referrals-initial {
                color: #00da91;
              }
            }
            .item-left {
              display: flex;
              align-items: center;
              .referrals-initial {
                margin-right: 10px;
                display: block;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03),
                  0 10px 24px 0 rgba(0, 0, 0, 0.08);
                width: 43px;
                height: 43px;
                text-align: center;
                border-radius: $radius-default;
                background-color: $white;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $fs-medium;
                font-weight: 800;
              }
              .referrals-email {
                text-decoration: none;
                font-weight: 800;
                color: #959595;
                font-size: $fs-default;
              }
            }
          }
        }
        .btn {
          margin-left: 20px;
          background-color: #eef4ff;
          color: black;
          padding: 10px 34px;
          border: none;
          font-size: $fs-small;
        }
      }
    }

    #process {
      margin: 60px 0;
      .process-wrapper {
        .title-point {
          margin-bottom: 20px;
          h2 {
            padding-right: 5px;
            font-size: $fs-huge;
            line-height: 38px;
            display: inline;
          }
          .point {
            display: inline-block;
            width: 14px;
            height: 14px;
            background-color: $primary-color;
            border-radius: $radius-half;
          }
        }
        .parrainage-process {
          list-style: none;
          padding: 0;
          .process-item {
            position: relative;
            margin-top: 50px;

            svg {
              circle {
                fill: $primary-color;
              }
              path {
                stroke: $primary-color;
              }
            }

            .process-title {
              margin-bottom: $margin-medium;
              font-size: $fs-medium;
              font-weight: 800;
            }
            .process-description {
              font-size: $fs-default;
              color: #959595;
            }
            &:after {
              content: '';
              width: 30px;
              height: 30px;
              position: absolute;
              right: 0;
              top: 100px;
              background-size: cover;
            }
            &:nth-child(2),
            &:first-child {
              &:after {
                background: url('/img/mon-compte/add.svg') center no-repeat;
              }
            }
            &:nth-child(3) {
              &:after {
                background: url('/img/mon-compte/equal.svg') center no-repeat;
              }
            }
          }
          .process-finish {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              max-width: 75px;
            }
            .process-finish-text {
              text-align: center;
              font-size: $fs-huge;
              font-weight: 800;
            }
          }
        }
      }
    }
  }

  .container {
    .info {
      padding: 26px 0 0;
      display: block;
      text-align: center;
      font-weight: 800;
      width: 100%;
      span {
        display: block;
        padding-bottom: 26px;
        text-align: left;
      }
      a {
        text-decoration: underline;
      }

      img {
        max-width: 80%;
      }
    }
  }

  .offers {
    .info {
      margin-bottom: -24px;
    }
  }
}

@media only screen and (min-width: $layout-xs) {
  .mon-compte {
    #play-wallet {
      background-image: linear-gradient(315deg, var(--gradient-end) 0%, var(--gradient-start) 74%);
      * {
        margin: 0 $margin-medium;
      }
    }
    .header {
      .topbar {
        margin: 4rem 1.5rem;
      }
    }

    &.mes-cartes {
      .nav {
        margin-bottom: 3rem;
        border-bottom: 1px solid $light-grey;

        .nav-item {
          .nav-link {
            &.active {
              border-bottom: 2px solid $black;
            }
          }
        }
      }

      .tab-content {
        .newCards,
        .sentCards {
          width: 100%;
          margin: 0;

          .head {
            font-size: $fs-xsmall;
          }
        }

        .newCards {
          .head,
          .body {
            .expiration-date {
              order: 1;
              width: 20%;
            }
            .price {
              order: 2;
              width: 14%;
            }
            .quantity {
              order: 3;
              width: 10%;
            }
            .product {
              order: 4;
              width: 20%;
            }
            .actions {
              order: 5;
              width: 36%;
            }
          }

          .body {
            margin-bottom: 0;
            padding-bottom: 0;

            div {
              border: 1px solid $light-grey;
              border-top: none;
            }

            .actions {
              display: block;
              text-align: right;

              .btn {
                width: auto;
                font-size: $fs-small;

                display: inline-block;

                span {
                  display: inline;
                }
              }
            }
          }
        }

        .usedCards {
          .head,
          .body {
            .activation-date {
              order: 1;
              width: 33%;
            }
            .code {
              order: 2;
              width: 33%;
            }
            .price {
              order: 3;
              width: 33%;
            }
          }
        }
      }
    }
  }
}

#modal-share-code,
#modal-offer-details {
  .modal-content {
    border-radius: $radius-accent;
    border: none;
    .modal-header {
      color: white;
      border-radius: $radius-accent $radius-accent 0 0;
      background: linear-gradient(
        180deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(10, 98, 253, 1) 0%,
        rgba(20, 167, 208, 1) 100%
      );
      .close {
        margin: 0;
        &:focus {
          outline: none;
        }
        img {
          max-width: 20px;
        }
      }
    }
    .modal-body {
      margin: 1.5rem;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;
          margin-bottom: 12px;
          &:before {
            margin-right: 8px;
            content: '';
            display: inline-block;
            height: 8px;
            width: 8px;
            border-radius: $radius-half;
            background: linear-gradient(
              180deg,
              rgba(2, 0, 36, 1) 0%,
              rgba(10, 98, 253, 1) 0%,
              rgba(20, 167, 208, 1) 100%
            );
          }
        }
      }

      .modal-details {
        padding-bottom: 10px;
        border-bottom: 1px solid $grey-border;
        font-size: $fs-accent;
      }

      .input-group {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        .codecopy {
          &:focus {
            outline: none;
          }
        }
      }

      #modal-offer-details-link {
        margin-bottom: 1.5rem;
      }

      #modal-offer-details-howto,
      .card-body {
        font-size: $fs-xsmall;

        li {
          margin-bottom: 0;
          &:before {
            display: none;
          }
        }
      }

      #modal-offer-details-expiration-date {
        font-size: $fs-xsmall;
        line-height: 1.8rem;
        padding-top: 2rem;
        color: $grey-button;
        font-weight: 400;
      }

      #headingOne {
        h2 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            @media (max-width : $layout-sm) {
              padding: 15px 22px;
            }
          }
        }
      }
    }
  }
}

#modal-card {
  .modal-content {
    border: 2px solid #fcca03;
    border-radius: $radius-accent;
    .card-action {
      text-align: center;
      margin-bottom: 2rem;

      .btn {
        width: 60%;
        padding: 0.6rem 1.2rem;
      }
    }

    .card-state {
      margin-bottom: 2rem;
    }
    .card-label {
      font-weight: 800;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

#modal-print,
#modal-edit {
  .modal-content {
    border-radius: $radius-accent;
    border: none;
    .modal-header {
      color: white;
      border-radius: $radius-accent $radius-accent 0 0;
      background: linear-gradient(
        180deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(10, 98, 253, 1) 0%,
        rgba(20, 167, 208, 1) 100%
      );
      .close {
        margin: 0;
        &:focus {
          outline: none;
        }
        img {
          max-width: 20px;
        }
      }
    }
    .modal-body {
      margin: 1.5rem;
      .input-block {
        margin-bottom: 20px;
      }
      .copy-mail {
        margin-bottom: 20px;
        .input-block {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: $layout-sm) {
  .mon-compte {
    .navigation {
      .navigation-list {
        padding: 0 89px;
        .navigation-list-item {
          padding: 0 $margin-large;
          &.hidden-on-mobile {
            display: flex;
          }
        }
      }
    }
    &.profil {
      .invoices {
        .wallet {
          .wallet-details {
            padding: 3rem;
          }
        }
      }
    }
    &.mes-offres {
      .offers {
        .offers-item {
          flex: 0 0 calc((100% - 12px) / 2);
          margin: 3px;
        }
      }
    }
    &.mon-parrainage {
      #its-win {
        .its-win-wrapper {
          padding: 20px 20px 50px 20px;
          .card-content {
            display: flex;
            justify-content: space-between;
            .card-description,
            .card-jackpot {
              flex: 1;
            }
            .card-jackpot {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $layout-lg) {
  .mon-compte {
    &.profil {
      .connexion-profil {
        .auth {
          .auth-form {
            .logout-other-sessions {
              text-align: right;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
    &.mes-offres {
      .offers {
        .offers-item {
          flex: 0 0 calc((100% - 24px) / 4);
        }
      }
    }
    &.mes-cartes {
      .tab-content {
        .sentCards {
          .head {
            display: flex;
            align-items: center;
            background: $light-grey;
            font-size: $fs-xsmall;
            font-weight: 800;
            text-transform: uppercase;
            padding: 8px $margin-medium;
            text-align: center;
            .created {
              width: 15%;
            }
            .recipient {
              width: 25%;
            }
            .price {
              width: 10%;
            }
            .card {
              width: 25%;
            }
            .date {
              width: 15%;
            }
            .actions {
              width: 15%;
            }
          }
          .card-item {
            cursor: inherit;
            .more-details,
            .code-card {
              display: none;
            }
            .card-body {
              display: flex;
              align-items: center;
              width: 100%;
              .created-l {
                width: 15%;
              }
              .recipient-l {
                width: 25%;
              }
              .price-l {
                width: 10%;
                text-align: center;
              }
              .card-l {
                width: 25%;
                text-align: center;
                span {
                  color: $secondary-color;
                }
                img {
                  cursor: pointer;
                }
              }
              .date-l {
                width: 15%;
                text-align: center;
              }
              .actions-l {
                width: 15%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .btn {
                  margin: 3px 0;
                }
              }
            }
          }
        }
      }
    }
    &.mon-parrainage {
      #its-win {
        .its-win-wrapper {
          padding: 40px;
          max-width: 80%;
          .card-header {
            h1 {
              font-size: $fs-xhuge;
              line-height: 48px;
            }
          }
          p {
            font-size: $fs-medium;
            line-height: 32px;
          }
          .card-content {
            margin-top: 20px;
            .card-description {
              display: flex;
              flex-direction: column;
              p {
                color: #727a8e;
              }
              .sponsors-slider {
                .slider {
                  input[type='range'] {
                    height: 17px;
                  }
                  input[type='range']::-webkit-slider-thumb {
                    width: 30px;
                    height: 30px;
                    margin-top: -8px;
                  }
                  input[type='range']::-moz-range-thumb {
                    width: 30px;
                    height: 30px;
                    margin-top: -8px;
                  }
                  input[type='range']::-ms-thumb {
                    width: 30px;
                    height: 30px;
                    margin-top: -8px;
                  }

                  input[type='range']::-webkit-slider-runnable-track {
                    height: 17px;
                  }
                  input[type='range']::-moz-range-track {
                    height: 17px;
                  }
                  input[type='range']::-ms-track {
                    height: 17px;
                  }
                  input[type='range']::-moz-range-progress {
                    height: 17px;
                  }
                  input[type='range']::-ms-fill-lower {
                    height: 17px;
                  }
                }
              }
            }
            .card-jackpot {
              margin-top: 30px;
              p {
                color: #727a8e;
              }
              .jackpot-total {
                font-size: $fs-xxhuge;
                line-height: 58px;
                font-weight: 800;
                background: linear-gradient(
                  45deg,
                  var(--gradient-end) 0%,
                  var(--gradient-start) 100%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
        }
      }
      #sponsoring-link {
        margin-top: 60px;
        .sponsoring-link-wrapper {
          h2 {
            font-size: $fs-xhuge;
            line-height: 48px;
            font-weight: initial;
          }
          .sponsoring-options {
            justify-content: space-between;
            .sponsoring-code,
            .sponsoring-socials {
              flex: 0 0 49%;
              padding: 40px;
            }
            .sponsoring-code {
              background: linear-gradient(
                127deg,
                var(--gradient-end) 0%,
                var(--gradient-start) 100%
              );
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03),
                0 10px 24px 0 rgba(0, 0, 0, 0.08);
              color: $white;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .code-header {
                margin-bottom: 32px;
                display: flex;
                flex-direction: column;
                .label {
                  font-size: $fs-medium;
                }
                .code {
                  font-size:$fs-huge;
                  line-height: 38px;
                  font-weight: 800;
                }
              }
              .code-link-text {
                font-size: $fs-small;
              }
            }
            .sponsoring-socials {
              margin-top: 0;
            }
          }
        }
      }
      #referrals {
        .referrals-wrapper {
          max-width: 80%;
          h2 {
            padding-left: 40px;
            font-size: $fs-xhuge;
            line-height: 48px;
          }
          .label {
            padding-left: 40px;
          }
          .referrals-list {
            .referrals-item {
              padding: 12px 40px;
              .item-left {
                .referrals-email {
                  margin-left: 30px;
                }
              }
            }
          }
          .btn {
            margin-left: 40px;
          }
        }
      }
      #process {
        margin: 60px 0;
        .process-wrapper {
          .title-point {
            margin-bottom: 20px;
            h2 {
              padding-right: 5px;
              font-size: $fs-huge;
              line-height: 38px;
              display: inline;
            }
            .point {
              display: inline-block;
              width: 14px;
              height: 14px;
              background-color: $primary-color;
              border-radius: $radius-half;
            }
          }
          .parrainage-process {
            margin-top: 40px;
            list-style: none;
            padding: 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .process-item,
            .process-finish {
              margin-top: 0px;
              flex: 0 0 23%;
              img {
                height: 150px;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}
