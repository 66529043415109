@import './variables';
@import './mixins';

.j-ai-une-carte {
  background-color: $yellow-bg;
  h1 {
    padding: 40px 0 30px;
    font-size: $fs-huge;
    line-height: 38px;
    color: $black;
    text-align: center;
  }
  form {
    padding-bottom: 70px;
    .success-box {
      padding: 20px;
      max-width: 700px;
      margin: 0 auto $margin-large;
      border-radius: $radius-default;
      display: none;
      align-items: center;
      background-color: #eaf9f2;
      .text-info {
        color: #30c67c;
        margin-left: 10px;
        margin-bottom: 0;
        font-size: $fs-small;
        font-weight: 800;
      }
      &.show {
        display: flex;
      }
    }
    .info-box {
      padding: 20px;
      max-width: 700px;
      margin: 0 auto $margin-large;
      border-radius: $radius-default;
      display: none;
      align-items: center;
      background-color: #eef5fd;
      .text-info {
        color: #5a5773;
        margin-left: 10px;
        margin-bottom: 0;
        font-size: $fs-small;
        font-weight: 800;
      }
      &.show {
        display: flex;
      }
    }
    .error-box {
      padding: 20px;
      max-width: 700px;
      margin: 0 auto $margin-large;
      border-radius: $radius-default;
      display: none;
      align-items: center;
      background-color: #feeded;
      .text-info {
        color: #f04646;
        margin-left: 10px;
        margin-bottom: 0;
        font-size: $fs-small;
        font-weight: 800;
      }
      &.show {
        display: flex;
      }
    }
    .code-box-link {
      font-size: $fs-default;
      color: $secondary-color;
      font-weight: 800;
    }
    .card-container {
      background: $white;
      padding: 30px 20px $margin-large;
      border: 1px solid $border-grey;
      border-radius: $radius-accent;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 12px 0 $margin-large;
        color: $spanish-grey;
        font-size: $fs-default;
        line-height: 20px;
        font-weight: 800;
        text-align: center;
      }
      .cardEmpty {
        position: relative;
        .cardEmpty-img {
          margin-bottom: 40px;
          max-width: 100%;
          width: 350px;
        }
        .activate-inputs {
          padding: 0 10px;
          position: absolute;
          top: 80px;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .input-text {
            padding: 10px 4px;
            background: $white;
            height: 35px;
            font-size: $fs-xsmall;
            font-weight: 800;
            border: 1px solid $border-grey;
            border-radius: 0;
            text-align: center;
          }
          .input-dash {
            margin: 0 4px;
            color: $white;
            font-weight: 800;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $layout-lg) {
  .j-ai-une-carte {
    h1 {
      padding: 60px 0 30px;
      font-size: $fs-xhuge;
      line-height: 1.18;
    }
    form {
      .card-container {
        padding: 40px 20px 30px;
        p {
          font-size: $fs-accent;
        }
        .cardEmpty {
          .activate-inputs {
            .input-text {
              font-size: $fs-small;
            }
          }
        }
      }
    }
  }
}
