table {
  width: 100%;
  border-collapse:collapse;

  &.bordered {
    border: 1px solid rgba(0, 0, 0, 0.1);

    td,
    th {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &.border-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    td,
    th {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  th,
  td {
    padding: 1rem;
  }
}