@import './_variables';
@import './mixins';

.with-grid {
  .bloc-advantage-contact.grid-container {
    .bloc {
      h2 {
        margin-bottom: $margin-small;
      }

      p.text-center {
        margin-bottom: $margin-medium;
        font-size: $fs-small;
      }
      .grid {
        .flex-center {
          flex-direction: column;
          display: flex;
          // align-items: center;
          justify-content: center;
        }
        .text {
          line-height: calc($fs-default*2.5);
        }
        img {
          height: 24px;
        }
      }
    }
  }

}

#page-nous-contacter {
  .information {
    a {
      text-decoration: none;
    }
  }
  .form-contact {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 2.6rem;
    margin-bottom: 2.6rem;

    @include from(lg) {
      grid-template-columns: auto auto;
    }

    &.full {
      grid-template-columns: auto;
    }

    textarea {
      height: 240px;
    }
  }

  .success {
    margin: 1.6rem 0;
    color: $success;
  }

  .error {
    margin: 1.6rem 0;
    color: $error;
  }

  .pictos-container {
    svg {
      path.colored {
        fill: var(--primary);
      }
    }
  }
}