@import './variables';
@import './mixins';

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 660;
    width: 100vw;
    height: 100vh;
    background-color: $black;

    &.fade {
        opacity: 0;
    }
    &.show {
        opacity: 0.5;
    }
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 661;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;

    h2 {
        font-size: $fs-default;
    }

    &.fade {
        .modal-dialog {
            transition: -webkit-transform 0.3s ease-out;
            transition: transform 0.3s ease-out;
            transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
            -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px);

            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }
    }

    &.show {
        .modal-dialog {
            transform: none;
        }
    }

    &.modal-static {
        .modal-dialog {
            transform: scale(1.02);
        }
    }

    .modal-dialog {
        position: relative;
        width: auto;
        margin: 0.5rem;
        pointer-events: none;

        @media (min-width: 992px) {
            &.modal-lg,
            &.modal-xl {
                max-width: 800px;
            }
        }

        @media (min-width: 1200px) {
            &.modal-xl {
                max-width: 1140px;
            }
        }

        @media (min-width: 576px) {
            max-width: 500px;
            margin: 1.75rem auto;
        }
        &.modal-dialog-centered {
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            min-height: calc(100% - 1rem);

            &::before {
                display: block;
                height: calc(100vh - 1rem);
                height: -webkit-min-content;
                height: -moz-min-content;
                height: min-content;
                content: "";
            }
        }

        .modal-content {
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: $radius-small;
            outline: 0;

            .modal-header {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: start;
                align-items: flex-start;
                -ms-flex-pack: justify;
                justify-content: space-between;
                padding: 1rem 1rem;
                border-bottom: 1px solid #dee2e6;
                border-top-left-radius: $radius-small;
                border-top-right-radius: $radius-small;
                font-size: $fs-huge;
                font-weight: 800;

                .modal-title {
                    margin-bottom: 0;
                    line-height: 1.5;
                }

                .close {
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03), 0 10px 24px 0 rgba(0,0,0,0.08);
                    background: $primary-color;
                    margin:0;
                    width: 32px;
                    height: 32px;
                    span {
                        line-height: 8px;
                        img {
                            max-width: 16px;
                        }
                    }
                }
            }

            .modal-body {
                position: relative;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                padding: 1rem;
            }

            .modal-footer {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: end;
                justify-content: flex-end;
                padding: 0.75rem;
                border-top: 1px solid #dee2e6;
                border-bottom-right-radius: $radius-small;
                border-bottom-left-radius: $radius-small;
            }

            .modal-footer > * {
                margin: 0.25rem;
            }
        }
    }
}

@media (min-width: 576px) {
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}




// INFO: Modale utilisée sans Bs
.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    justify-content: center;
    background-color: rgb(33, 37, 41, 0.5);
    z-index: 100;

    &.show {
        display: flex;
    }

    .modal-container {
        display: flex;
        align-items: center;
        margin: 1.75rem auto;
        min-height: calc(100% - 3.5rem);
        width: 90%;

        @include from('md') {
            width: 500px;
        }

        &.large {
            @include from('md') {
                width: 700px;
            }

            @include from('lg') {
                width: 800px;
            }

            @include from('xl') {
                width: 1000px;
            }
        }

        &.large-auto {
            width: 65vw;
            max-width: 900px;
        }

        &.auto {
            width: auto;
            max-width: 90%;
        }

        &.full {
            width: 100%;
            min-height: 100%;
            margin: 0;

            .modal-content {
                border-radius: 0;
                height: 100%;
            }
        }

        .modal-content {
            border-radius: $radius-accent;
            background-color: $white;
            padding: 0;
            margin: 0;
            width: 100%;
            max-height: 90%;
            overflow-y: auto;

            position: relative;
            display: flex;
            flex-direction: column;
            text-align: center;

            @include from('md') {
                text-align: left;
            }

            * {
                max-width: 100%;
            }

            .modal-content-header {
                padding: 2.4rem;
                border-bottom: 1px solid #dee2e6;
                font-size: $fs-medium;
                font-weight: 800;
            }
            .modal-content-body {
                padding: 2.4rem;
            }
            .modal-content-footer {
                padding: 2.4rem;
                border-top: 1px solid #dee2e6;

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-around;

                @include from('md') {
                    justify-content: flex-end;
                }

                .btn {
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.btn-primary {
                        color: #3b3f44;
                    }
                }
            }
        }
    }
}

.modal-overlay {
    &.withGrid {
        .title {
            font-size: $fs-large;
            font-weight: 800;
        }
        p {
            margin: $margin-medium;
        }
        .grid {
            margin-bottom: $margin-medium;

            .feat {
                color: $primary-color;
                font-size: $fs-huge;
            }

            .logo {
                flex-direction: column;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
            }

            .control {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: $margin-medium;

                @media (min-width: $layout-sm) {
                    padding: 0 $margin-medium;
                }

                img {
                    margin-bottom: $margin-small;
                    border: 2px solid white;
                    border-radius: 16px;
                    &.selected {
                        border-color: $primary-color;
                    }
                }

                .control_indicator {
                    position: relative;
                }
            }
        }
        .separant {
            width: 60%;
            border-top: 2px solid var(--primary);
            margin: 0 auto;
        }
    }
  }