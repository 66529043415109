@import './_variables';

.content {
  h1 {
    text-align: center;
    line-height: 3.8rem;
    font-size: $fs-huge;
    padding: 8rem 0;
  }
  h2 {
    font-size: $fs-xhuge;
    font-weight: 400;
    line-height: 4rem;
    margin-bottom: 1.5rem;
    margin-top: 4rem;
  }

  img {
    max-width: 100%;
  }

  .btn {
    font-size: $fs-accent;
    font-weight: 800;
  }

  &.large-content {
    margin-bottom: 6rem;
    h1 {
      border-bottom: 1px solid $light-grey;
    }
  }

  &.yellow {
    h1 {
      background-color: $primary-color;
      color: $white;
    }
  }
}

.container {
  .header {
    padding: 8rem 0;
    border-bottom: 1px solid $grey-border;
    text-align: center;

    span {
      font-size: $fs-medium;
      margin-top: 1.5rem;
      font-weight: 800;
      color: $grey-subtitle;
    }
  }

  &.center {
    text-align: center;
  }

  &.padding {
    p {
      padding: 2rem 0;

    }
  }

  .small {
    font-size: $fs-xsmall;
  }
}

@media only screen and (max-width: $layout-xs) {
  .container {
    .header {
      padding: 4rem 0;

      h1 {
        font-size: $fs-xhuge;
        line-height: 1.18;
        font-weight: initial;
      }
    }
  }
}