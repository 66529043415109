@import './_variables';

.connexion {
  margin-top: 0;
  .connexion-wrapper {
    display: flex;
    flex-wrap: wrap;
    .connexion-login {
      padding: 30px 16px;
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .login-logo {
        margin-bottom: 60px;
        max-width: 200px;
      }
      .login-title {
        padding: 10px 0 0 0;
        font-size: $fs-huge;
        line-height: 38px;
      }
      .sign-in {
        margin: 0;
        color: $secondary-color;
        font-style: italic;
        font-weight: 800;
        font-size: $fs-default;
      }
      .connexion-form {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .email {
          text-align: center;
          margin: $margin-large 0;
          .highlight {
            display: inline;
            background: $yellow-light;
            padding: 4px 12px;
            color: #6c757d;
          }
        }
        .input-item {
          margin: 10px 0;
          position: relative;
          .hash-password {
            position: absolute;
            bottom: 61px;
            left: 0;
            display: block;
            padding: 16px;
            background-color: #253e67;
            border-radius: $radius-large;
            width: 257px;
            visibility: hidden;
            z-index: 2;
            &::before {
              display: none;
              content: '';
              position: absolute;
              border-right: 10px solid #253e67;
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              top: 50%;
              transform: translateY(-50%);
              right: 100%;
            }
            .hash-title {
              margin-bottom: 10px;
              font-size: $fs-small;
              line-height: 1.7rem;
              font-weight: 800;
              color: $white;
            }
            .hash-condition {
              position: relative;
              padding-left: 12px;
              font-size: $fs-small;
              line-height: 1.7rem;
              color: $white;
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: $radius-full;
                background-color: $white;
              }
              &.condition-ok {
                color: $primary-color;
                font-weight: 800;
              }
            }
          }
          .icon-eye {
            padding: 0;
            position: absolute;
            width: 30px;
            height: 30px;
            background: url('/icons/eye.svg') no-repeat center center;
            background-size: contain;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
          }
          .error {
            font-size: $fs-xsmall;
            font-style: italic;
            margin: 3px 0;
            color: $error;
          }
        }
        .m-button {
          align-self: flex-end;
        }
        .forgot-password {
          color: $independence-grey;
          text-decoration: underline;
          font-size: $fs-default;
        }
      }
      .divider {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #000;
        line-height: 0.1em;
        margin: 10px 0 20px;
        span {
          background: $white;
          padding: 0 8px;
        }
      }
      .external-provider {
        text-align: left;
        width: 100%;

        button {
          text-align: left;
          justify-content: flex-start;
        }
      }
    }

    .video-container {
      position: relative;
      flex: 0 0 100%;
      .video-content {
        position: relative;
        padding: 16px;
        background: linear-gradient(var(--gradient-start) 0%, var(--gradient-end) 100%);
        display: flex;
        align-items: center;
        height: 100%;
        .connexion-video {
          position: relative;
          overflow: hidden;
          padding-top: 56.25%;
          width: 100%;
          cursor: pointer;
          .iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $layout-lg) {
  .connexion {
    .connexion-wrapper {
      flex-direction: row;
      height: 100vh;
      .video-container,
      .connexion-login {
        flex: 0 0 50%;
      }
      .video-container {
        padding: 20px 20px 20px 0;
      }
      .connexion-login {
        max-width: 500px;
        margin: 0 auto;
        .login-logo {
          margin-top: 60px;
        }
        .connexion-form {
          .input-item {
            .hash-password {
              left: 102%;
              top: 50%;
              transform: translateY(-50%);
              bottom: inherit;
              &::before {
                display: block;
              }
            }
          }
          .m-button {
            margin-top: 50px;
          }
        }
      }
    }
  }
}