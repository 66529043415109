$primary-color: var(--primary);
$secondary-color: var(--variant);
$danger-color: #f03528;

$primary-color-darken: var(--primary-darken);
$secondary-color-darken: var(--variant-darken);
$danger-color-darken: #b92c22;

$red: $danger-color;
$default-red: #ff0000;
$light-red: #fcdddd;

$error: #b92c22;
$success: #28a745;

$black: #212529;
$white: #fff;

$grey-dark: #292927;
$grey: #585858;
$light-grey: #e5e5e5;
$extra-light-grey: #f4f4f4;
$grey-button: #b2b2b2;
$default-grey: #ccc;
$blue-grey: #ecf1f1;
$slider-grey: #e5e5e5;

$grey-border: rgba(0, 0, 0, 0.1);
$grey-subtitle: rgba(0, 0, 0, 0.5);
$banner-color: #0c0c0c;

$footer: #383636;

$yellow-light: var(--light-background);
$davy-grey: #4d535b;
$independence-grey: #454b69;
$border-grey: #ededed;
$cultured: #eeeeee;
$spanish-grey: #999999;
$disabled: #dcdcdc;

$yellow-bg: #fffef8;

$layout-xs: 576px;
$layout-sm: 768px;
$layout-lg: 992px;
$layout-xl: 1200px;

$max-xs: 426px;
$max-sm: 767px;
$max-md: 1024px;
$max-lg: 1240px;
$max-xl: false;

$breakpoints: (
  xs: $max-xs,
  sm: $max-sm,
  md: $max-md,
  lg: $max-lg,
  xl: $max-xl,
);

$christmas-color: rgb(235, 35, 53);
$christmas-color-light: rgb(255, 132, 142);
$christmas-color-darken: rgb(145, 26, 28);

$margin-xs: 6px;
$margin-small: 8px;
$margin-medium: 16px;
$margin-large: 24px;

$fs-default: 1.6rem;
$fs-small: 1.4rem;
$fs-reset: 1rem;
$fs-xxsmall: 0.6rem;
$fs-xsmall: 1.2rem;
$fs-accent: 1.8rem;
$fs-medium: 2rem;
$fs-large: 2.6rem;
$fs-huge: 3.4rem;
$fs-xhuge: 4rem;
$fs-xxhuge: 5.4rem;

$radius-default: 8px;
$radius-small: 4px;
$radius-accent: 12px;
$radius-large: 16px;
$radius-xlarge: 26px;
$radius-half: 50%;
$radius-full: 100px;
