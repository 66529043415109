@import './variables';
@import './mixins';

.credit-card {
  background-color: $yellow-bg;
  h1 {
    padding: 40px 0 30px;
    font-size: $fs-huge;
    line-height: 38px;
    color: $black;
    text-align: center;
  }
  .credit-container {
    display: flex;
    flex-direction: column;
    .card-recap {
      margin-bottom: $margin-large;
      background: $white;
      padding: 30px 20px $margin-large;
      border: 1px solid $border-grey;
      border-radius: $radius-accent;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: $fs-large;
        line-height: 27px;
        font-weight: 800;
        color: $black;
        text-align: center;
      }
      .img-container {
        margin: $margin-large 0;
        position: relative;
        max-width: 330px;
        img {
          max-width: 100%;
        }
        .img-price {
          position: absolute;
          right: 20px;
          top: 91px;
          font-size: $fs-xhuge;
          color: $white;
        }
        .from-message {
          position: relative;
          margin-top: -10px;
          padding: 12px 15px;
          background-color: $white;
          border-radius: 0 0 $radius-default $radius-default;
          box-shadow: 0 0.25em 0.5em rgba(16, 16, 16, 0.05);
          z-index: 2;
          .from-content {
            display: block;
            font-size: $fs-small;
            font-style: italic;
            color: $independence-grey;
          }
          .from-name {
            display: block;
            color: $independence-grey;
            font-size: $fs-small;
            font-weight: 800;
          }
        }
      }
      .restriction-bloc {
        padding: 12px 10px;
        background-color: $yellow-light;
        border-radius: $radius-small;
        display: flex;
        align-items: center;
        justify-content: center;
        .restriction-img {
          padding: 4px;
          margin-right: $margin-medium;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          border-radius: $radius-default;
          img {
            max-width: 30px;
          }
        }
        .restriction-message {
          font-size: $fs-small;
          margin-bottom: 0;
        }
      }
      .total-recap {
        width: 100%;
        list-style: none;
        margin: $margin-large 0 0 0;
        padding: $margin-large 0 0 0;
        border-top: 1px solid $grey-border;
        .total-item {
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .total-text {
            color: #c2c2c2;
            font-size: $fs-default;
            font-weight: 800;
          }
          .montant {
            font-size: $fs-default;
            font-weight: 800;
            color: $black;
          }
          &:last-child {
            padding: 10px $margin-medium;
            background-color: $primary-color;
            border-radius: $radius-small;
            .total-text,
            .montant {
              color: $white;
            }
          }
        }
      }
    }
    .thanks-button {
      padding: 10px $margin-large;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: $white;
      border-left: 1px solid $border-grey;
      border-right: 1px solid $border-grey;
      border-top: 1px solid $border-grey;
      border-bottom: none;
      border-radius: $radius-accent $radius-accent 0 0;
      text-decoration: none;
      cursor: pointer;
      .thanks-label {
        margin-right: 9px;
        font-size: $fs-large;
        line-height: 27px;
        font-weight: 800;
        color: $black;
      }
      .thanks-img {
        transition: transform 0.3s ease-in;
        &.transform {
          transform: rotate(180deg);
        }
      }
      &.collapsed {
        border-radius: $radius-accent;
        border: 1px solid $border-grey;
      }
    }
    .card-thanks {
      margin-bottom: $margin-large;
      background: $white;
      padding: 20px 20px $margin-large;
      border-left: 1px solid $border-grey;
      border-right: 1px solid $border-grey;
      border-bottom: 1px solid $border-grey;
      flex-direction: column;
      &.collapse {
        display: none;
      }
      &.collapsing {
        height: 0;
        position: relative;
        overflow: hidden;
        transition: height 0.3s ease;
      }
      &.show {
        display: flex;
        border-radius: 0 0 $radius-default $radius-default;
      }
      .users-infos {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .infos {
          display: flex;
          align-items: flex-start;
          .avatar {
            margin-right: 20px;
            border-radius: $radius-full;
            background: $yellow-light;
            box-shadow: inset 0px 0px 0px 3px white;
            border: 1px solid $yellow-light;
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            .initial {
              font-size: $fs-medium;
              font-weight: 800;
              color: $primary-color;
            }
          }
          .names {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .user {
              font-size: $fs-default;
              font-weight: 800;
              color: #999999;
            }
            .to {
              font-size: $fs-default;
              font-weight: 800;
              color: #dbd9d9;
              span {
                margin-left: 10px;
              }
            }
          }
          .date {
            font-size: $fs-small;
            font-weight: 800;
            color: #999999;
          }
        }
      }
      .infos-bloc {
        margin: $margin-large 0 18px;
        padding: 17px $margin-large;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $yellow-light;
        border-radius: $radius-small;
        img {
          max-width: 140px;
        }
        .infos-text {
          font-size: $fs-small;
        }
      }
      textarea {
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-size: $fs-xsmall;
        line-height: 1.6rem;
      }
    }
    .m-button {
      margin: 50px 0 100px;
      align-self: flex-end;

      &:disabled {
        background-color: $yellow-light;
      }
    }
  }
}

@media only screen and (min-width: $layout-lg) {
  .credit-card {
    h1 {
      padding: 60px 0 30px;
      font-size: $fs-xhuge;
      line-height: 1.18;
    }
    .credit-container {
      .card-recap {
        padding: 40px 170px 30px;
      }
    }
  }
}
