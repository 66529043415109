@import './variables';
@import './mixins';

@mixin switch-btn-style {
  .switch-btn-bloc {
    display: block;
    position: relative;
    margin: 0 22px;
  }

  .toggle {
    --width: 50px;
    --height: calc(var(--width) / 2.3);
    --border-radius: calc(var(--height) / 2);
    margin: 0;
    display: block;
    cursor: pointer;
  }

  .toggle-input {
    visibility: hidden;
    position: absolute;
    z-index: 2;
  }

  .toggle-fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: var(--primary);
    transform: translateX(0);
    transition: background 0.2s;
  }

  .toggle-fill span {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(var(--height) + 2%);
    width: calc(var(--height) + 2%);
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    outline: 1px solid rgb(0 0 0 / 8%);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
  }
  .toggle-input:not(:checked) + .toggle-fill span {
    transform: translateX(0);
  }
  .toggle-input:checked + .toggle-fill span {
    transform: translateX(150%);
  }
}

/*
  Bouton switch theme 
  */

.switch-btn-bloc {
  display: none;
}

/*
    version bouton mobile sur le header-menu
  */

.header-menu-btn-bloc {
  @include switch-btn-style;

  position: relative;
}

/*
    version bouton desktop
  */

@include from(lg) {
  @include switch-btn-style;

  .header-menu-btn-bloc {
    display: none;
  }
}
