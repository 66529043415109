body.christmas {
  &.presentation-de-nos-cartes {
    background-image: url('/christmas/background.png');
    background-position: center top;
    background-size: cover;
    background-attachment: fixed;

    footer {
      background-color: transparent;
      border: none;
    }
  }
  #sled {
    display: block;
    position: absolute;
    top: -15px;
    right: -69px;

    img {
      height: 44px;
    }
  }

  .j-ai-une-carte {
    #activatedButton {
      background-color: $christmas-color;
      background-image: url('/icons/snowman.svg');
      background-size: auto 60%;
      background-position: right 4px center;
      background-repeat: no-repeat;

      &:hover {
        background-color: $christmas-color-darken;
      }

      &:focus {
        outline: 2px solid $christmas-color-darken;
      }
    }
  }

  main.home {
    .introduction {
      $s1:"";
            $s2:"";
            $s3:"";
            @for $i from 1 through 200 {
                $s1: $s1 + random(1000)*0.1vw + " " + random(1000)*0.1vh + " " + 0 + " " + random(50)*-0.01rem + $white;
                $s2: $s2 + random(1000)*0.1vw + " " + random(1000)*0.1vh + " " + 0 + " " + random(50)*-0.01rem + $white;
                $s3: $s3 + random(1000)*0.1vw + " " + random(1000)*0.1vh + " " + 0 + " " + random(50)*-0.01rem + $white;
                @if $i < 200 {
                    $s1: $s1 + ",";
                    $s2: $s2 + ",";
                    $s3: $s3 + ",";
                }
            }
            .snow {
                border-radius: $radius-half;
                opacity: 0.8;
                position: absolute;
                top:-100vh;
                animation-name: fall;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
            }
            .layer1 {
                width: 8px;
                height: 8px;
                filter:blur(1.5px);
                box-shadow: #{$s1};
                animation-duration: 12s;
            }
            .layer1.a {
                animation-delay: -3s;
            }
            .layer2 {
                width: 6px;
                height: 6px;
                filter:blur(3px);
                box-shadow: #{$s2};
                animation-duration: 12s;
            }
            .layer2.a {
                animation-delay: -4s;
            }
            .layer3 {
                width: 4px;
                height: 4px;
                filter:blur(6px);
                box-shadow: #{$s3};
                animation-duration: 10s;
            }
            .layer3.a {
                animation-delay: -5s;
            }
            @keyframes fall {
                100% {transform: translateY(200vh); }
            }
      .wrapper{
        .left {
          #letsGo {
            background-color: $christmas-color;
            border-color: $christmas-color;


            &:before{
              display: none;
              top: -75%;
              background-image:
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle,  transparent 20%, $christmas-color 20%, transparent 30%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle,  transparent 10%, $christmas-color 15%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%);
              background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;

            }

            &:after{
              display: none;
              bottom: -75%;
              background-image:
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle,  transparent 10%, $christmas-color 15%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%),
                radial-gradient(circle, $christmas-color 20%, transparent 20%);
              background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
            }
          }
        }
      }
    }

    .experience {
      background-image: url('/christmas/snowice.svg');
      background-repeat: repeat-x;
      background-position: top left;
      background-color: transparent;
      width: 100%;
      margin-top: -18px;
    }
  }

  #choice-price {
    .noUi-horizontal .noUi-handle {
      background-color: transparent;
      box-shadow: none;
    }
    .noUi-handle:before {
      background-image: url('/christmas/snowman.svg');
      background-color: transparent;
      width: 100%;
      height: 100%;
    }
  }
  #total-price {
    .total-wrapper {
      button {
        background-image: url('/icons/snowman.svg');
        background-size: auto 60%;
        background-position: right 4px center;
        background-repeat: no-repeat;
      }
    }
  }

  #submitCard {
    background-color: $christmas-color;
    background-image: url('/icons/snowman.svg');
    background-size: auto 60%;
    background-position: right 4px center;
    background-repeat: no-repeat;

    &:hover {
      background-color: $christmas-color-darken;
    }
    &:focus {
      outline: 2px solid $christmas-color-darken;
    }
  }

  .header-menu {
    .service-items {
      .service-item {
        position: relative;
        .picto {
          display: block;
          position: absolute;
          top: -15px;
          right: -21px;
          width: 50px;
          z-index: 2;
        }
      }
    }
  }
}