@import './variables';

@mixin respond-to($media) {
  @if $media == sm {
    @media only screen and (min-width: $max-xs + 1) and (max-width: $max-sm) { @content; }
  }
  @else if $media == md {
    @media only screen and (min-width: $max-sm + 1) and (max-width: $max-md) { @content; }
  }
  @else if $media == lg {
    @media only screen and (min-width: $max-md + 1) and (max-width: $max-lg) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: $max-lg + 1) { @content; }
  }
}

@mixin isMax($media) {
  @if $media == sm {
    @media only screen and (max-width: $max-sm - 1) { @content; }
  }
  @else if $media == md {
    @media only screen and (max-width: $max-md - 1) { @content; }
  }
  @else if $media == lg {
    @media only screen and (max-width: $max-lg - 1) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: $max-lg - 1) { @content; }
  }
}


@mixin from($media) {
  @if $media == sm {
    @media only screen and (min-width: $max-xs + 1) { @content; }
  }
  @else if $media == md {
    @media only screen and (min-width: $max-sm + 1) { @content; }
  }
  @else if $media == lg {
    @media only screen and (min-width: $max-md + 1) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: $max-lg + 1) { @content; }
  }
}

@mixin includeH1Header() {
  padding: 109px 0;
  border-bottom: 1px solid #454B69;
  margin: 0;
  text-transform: uppercase;
  background: url('/img/faq/bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin boxShadow() {
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
}