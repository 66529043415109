@import './variables';
@import './mixins';

.operation {
  height: auto;
  display: flex;
  flex-direction: column;
  @include from(lg) {
    height: 100vh;
    flex-direction: row;
  }
  .sidebar {
    width: 100vw;
    height: 255px;
    background-repeat: no-repeat;
    background-size: cover;
    @include from(lg) {
      width: 30vw;
      height: 100%;
    }
  }
  .onBoarding {
    padding-bottom: 100px;
    width: 100vw;
    flex: 1 0 auto;
    @include from(lg) {
      padding-bottom: 0;
      width: 70vw;
      overflow-x: auto;
    }
    .steps {
      padding: 34px 46px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(194,194,194,0.3);
      max-width: 350px;
      margin: 0 auto;
      @include from (md) {
        max-width: 700px;
        padding: 34px 100px;
      }
      @include from (xl) {
        max-width: 700px;
        padding: 60px 100px 46px;
      }
      .step-item {
        display: flex;
        align-items: center;
        .step-number {
          width: 40px;
          height: 40px;
          background-color: #FAF9FB;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $fs-accent;
          font-weight: 400;
          border-radius: $radius-default;
        }
        .step-text {
          margin-left: $margin-medium;
          font-size: $fs-small;
          font-weight: 800;
          color: #C2C2C2;
        }

      }
    }
    .content {
      display: flex;
      flex-direction: column;
      @include from(lg) {
        max-width: 70%;
        margin: 0 auto;
      }
      .logos {
        margin: 32px 0 60px;
        display: flex;
        align-items: center;
        @include from(lg) {
          margin: 32px 0 40px;
        }
        .logo {
          max-width: 40%;
          @include from(lg) {
            max-width: 30%;
            max-height: 100px;
          }
          &:nth-child(2) {
            margin-left: 20px;
            @include from(lg) {
              margin-left: 100px;
            }
          }
        }
      }
      .introduction {
        margin-top: -20px;
        @include from (lg) {
          margin-top: 0;
        }
        .welcome {
          display: flex;
          align-items: center;
          .welcome-text {
            font-size: $fs-medium;
            font-weight: 800;
            line-height: 2.7rem;
            @include from(lg) {
              font-size: $fs-huge;
              line-height: 3.7rem;
            }
          }
          .welcome-logo {
            margin: 5px 0 0 5px;
            max-height: 35px;
            @include from(lg) {
              margin: 8px 0 0 5px;
              max-height: 50px;
            }
          }
        }
        .discount {
          margin-top: 18px;
          background-color: $yellow-light;
          padding: 8px $margin-large;
          border-radius: $radius-default;
          display: flex;
          align-items: center;
          .discount-img {
            width: 60px;
            margin-right: 14px;
          }
          .discount-text {
            font-size: $fs-default;
            font-weight: 800;
            color: #4D535B;
          }
        }
        .offer {
          margin-top: 23px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          @include from (md) {
            justify-content: space-between;
          }
          .offer-text {
            margin-bottom: 23px;
            font-size: $fs-default;
            line-height: 2.7rem;
            @include from(md) {
              margin-bottom: 0px;
            }
            span {
              font-weight: 800;
            }
          }
        }
      }
    }
    .onBoarding-steps {
      margin-top: 48px;
      padding-left: 15px;
      text-align: center;
      @include from (lg) {
        max-width: 70%;
        margin: 48px auto 0;
      }
      .title-point {
        margin-bottom:20px;
        text-align: left;
        h2 {
          padding-right: 5px;
          font-size: $fs-large;
          line-height: 2.7rem;
          font-weight: 800;
          display: inline;
          @include from (lg) {
            font-size: $fs-xhuge;
            line-height: 4.6rem;
          }
        }
        .point {
          width:10px;
          height: 10px;
          display: inline-block;
          background-color:$primary-color;
          border-radius: $radius-half;
          @include from (lg) {
            width:14px;
            height: 14px;
          }
        }
      }
      .steps-list {
        margin: 42px 0;
        display: flex;
        overflow: auto;
        @include from (md) {
          justify-content: space-between;
        }
        .step-item {
          min-width: 230px;
          height:265px;
          padding-right: 20px;
          @include from (md) {
            min-width: 200px;
            padding-right: 0;
            width: calc((100% - 100px) / 3);
          }
          @include from(lg) {
            min-width: inherit;
          }
          .step-content {
            padding: $margin-large 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height:100%;
            border-radius: $radius-default;
            .step-img {
              height: 108px;
            }
            .step-title {
              margin-top: 27px;
              font-size: $fs-small;
              line-height: 2.4rem;
              font-weight: 800;
              text-align: center;
            }
            .step-baseline {
              font-size: $fs-xsmall;
              font-weight: 400;
              color: #C2C2C2;
              text-align: center;
            }
          }
        }

      }
    }
  }
  .see-partners {
    display: flex;
    align-items: center;
    padding: 13px 20px;
    border-radius: $radius-full;
    border: 2px solid $primary-color;
    font-size: $fs-small;
    font-weight: 800;
    background-color: initial;
    transition: all .3s ease-in-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $black;
    &:hover {
      border-color: $primary-color-darken;
    }
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }

  .noUi-target {
    border-radius: $radius-full;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid $primary-color;
  }
  .noUi-connects {
    border-radius: $radius-full;
  }
  .noUi-connect {
    background-color: $primary-color;
  }
  .noUi-horizontal .noUi-handle {
    border-radius: $radius-full;
    width: 30px;
    height: 30px;
    border: none;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.2);
    top: -7px;
  }
  .noUi-handle:before {
    width: 18px;
    height: 18px;
    background-color: $primary-color;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: $radius-full;
  }
  .noUi-handle:after {
    display: none;
  }
  .noUi-horizontal .noUi-tooltip {
    top:120%;
    bottom: inherit;
    border: none;
    font-weight: 800;
    font-size: $fs-accent;
  }
  .noUi-value {
    font-size: $fs-small;
  }
  &.credit {
    .sidebar {
      background-image: url("/img/home/offrir-bg.png");
      background-position: 50% 0;
    }
    .onBoarding {
      .steps {
        .step-item {
          &.active {
            .step-text {
              color: $primary-color;
            }
            .step-number {
              background-color: $primary-color;
              color: $white
            }
          }
        }
      }
      .onBoarding-steps {
        .steps-list {
          .step-item {
            .step-content {
              border: 1px solid $primary-color;
            }
          }
        }
      }
    }
  }
  &.active {
    .sidebar {
      background-image: url("/img/home/activer-bg.png");
      background-position: 50% 60%;
    }
    .onBoarding {
      @include from (lg) {
        height: 100vh;
        overflow-y: scroll;
      }
      .steps {
        .step-item {
          &.active {
            .step-text {
              color: $secondary-color;
            }
            .step-number {
              background-color: $secondary-color;
              color: $white
            }
          }
        }
      }
      .onBoarding-steps {
        .steps-list {
          .step-item {
            .step-content {
              border: 1px solid #CEE0FF;
            }
          }
        }
      }
    }
  }
}

.modal-partners {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 27px 25px 0 25px;
        border: none;
        display: flex;
        align-items: center;
        .modal-title {
          font-size: $fs-medium;
          line-height: 2.4rem;
        }
        .modal-close {
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background: none;
          &:focus {
            outline: none;
          }
        }
      }
      .modal-body {
        padding: 0 25px 10px 25px;
        .partners-list {
          margin-top: 30px;
          display: inline-flex;
          flex-wrap: wrap;
          .partner-item {
            flex: 1 1 auto;
            margin: 5px;
            padding:8px;
            border-radius: $radius-small;
            height: 80px;
            cursor: pointer;
            transition: all .3s ease-in;
            max-width: 80px;
            &:hover {
              transform: scale(1.1);
            }
            .partner-card {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 60px;
              }
            }
          }
        }
        .partner-detail {
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .offer-detail {
            margin-bottom: 30px;
            --bg: #000;
            padding: $margin-large 27px 60px;
            flex:0 0 100%;
            background-color: #F9FAFB;
            border-radius: $radius-accent;
            position: relative;
            order:2;
            @include from (lg) {
              margin-bottom: 0;
              flex:0 0 60%;
              order: 1
            }
            img {
              max-height: 40px;
              margin-bottom: 40px;
            }
            .decoration {
              position: absolute;
              width: 39px;
              height: 39px;
              top:27px;
              left:24px;
              .deco1 {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: $radius-small;
                background-color: var(--bg);
                opacity: 0.4;
              }
              .deco2,
              .deco3 {
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: $radius-small;
                z-index: 999;
              }
              .deco2 {
                bottom:7px;
                right:7px;
                background-color: var(--bg);
              }
              .deco3 {
                background-color: var(--bg);
                opacity: 0.6;
                top:7px;
                left:7px;
              }
            }
            .description {
              list-style: none;
              p {
                position: relative;
                font-size: $fs-accent;
                line-height: 2.7rem;
                font-weight: 800;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .checkmark {
                  width: 22px;
                  height: 22px;
                  border-radius: $radius-half;
                  border: 2px solid var(--bg);
                  position: relative;
                  overflow: hidden;
                  background-color: var(--bg);
                  flex: 0 0 auto;
                  &:before,
                  &:after {
                    content: "";
                    position: absolute;
                    background-color: white;
                    height: 2px;
                  }
                  &:before {
                    top: 10px;
                    left: 2px;
                    transform: rotate(45deg);
                    width: 7px;
                  }
                  &:after {
                    top: 8px;
                    left: 6px;
                    transform: rotate(-45deg);
                    width: 11px;
                  }
                }
              }
            }
          }
          .offers-list {
            padding: 0;
            flex:0 0 100%;
            flex-direction: column;
            order:1;
            cursor: pointer;
            @include from (lg) {
              flex:0 0 calc((40% - 32px));
              order:2;
            }
            .offer-item {
              margin-bottom: $margin-medium;
              padding: 27px $margin-large;
              border-radius: $radius-accent;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              transition: transform .3s ease-in-out;
              &:hover {
                transform: translateX(-4px);
              }
              p {
                margin-bottom: 0;
                &.offer-name {
                  font-size:2rem;
                  line-height: 2.4rem;
                  font-weight: 400;
                  color: #454B69;
                }
                &.offer-price {
                  font-size:2.7rem;
                  line-height: 3.2rem;
                  font-weight: 800;
                  text-align: right;
                }
              }

              &.selected {
                background-color: var(--bg);
                p {
                  color: var(--color);
                }
              }
            }
          }
        }
      }
      .modal-footer {
        padding: 0 25px 25px 25px;
        border:none;
        justify-content: flex-start;
        .modal-back {
          align-items: center;
          border: none;
          display: inline-flex;
          justify-content: center;
          cursor: pointer;
          text-decoration: none;
          font-weight: 800;
          text-align: center;
          padding: 4px 15px;
          transition: all .3s linear;
          -webkit-tap-highlight-color: rgba(0,0,0,0);
          -webkit-tap-highlight-color: transparent;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background: none;
          background-image: url(/img/arrow-previous.svg);
          background-position: center left 6px;
          background-repeat: no-repeat;
          background-size: 20px;
          padding-left: 30px;
        }
      }
    }
  }
}
