@import './variables';
@import './mixins';

.operation{
  .credit-content {
    .noUi-marker-large {
      background: transparent;
    }

    @include from (lg) {
      max-width: 70%;
      margin: 0 auto;
    }
    .credit-header {
      margin-bottom: 22px;
      padding: 34px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      @include from (lg) {
        padding: $margin-large 0;
      }
      .credit-title {
        margin-bottom: 22px;
        font-size: $fs-accent;
        line-height: 2.7rem;
        font-weight: 400;
        & span {
          font-weight: 800;
        }
        @include from (md) {
          margin-bottom: 0;
          font-size: $fs-large;
          line-height: 3.2rem;
        }
      }
    }
    .credit-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      .credit-range {
        padding: 10px 10px 60px 10px;
        position: relative;
        @include from (lg) {
          padding: 10px 50px 80px 50px;
        }
        .price-range {
          padding-bottom: 14px;
          font-size: $fs-default;
          font-weight: 400;
          @include from (lg) {
            font-size: $fs-accent;
          }
        }
      }
      .credit-summary {
        padding:28px;
        border: 1px solid $primary-color;
        border-radius: $radius-default;
        .summary-title {
          margin-bottom: 40px;
          display: flex;
          font-size: $fs-xsmall;
          color: #C2C2C2;
          @include from (lg) {
            margin-bottom: 20px;
            font-size: $fs-small;
          }
        }
        .detail-item {
          padding-bottom: 18px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .detail-label {
            font-weight: 800;
            font-size: $fs-small;
            line-height: 1.8rem;
            @include from (lg) {
              font-size: $fs-default;
            }
          }
          &:nth-child(2) {
            padding-bottom: 27px;
          }
          &:last-child {
            border-top: 1px solid rgba(194,194,194,0.3);
            padding-top: 27px;
            padding-bottom: 0;
          }
          .final-price {
            font-size: $fs-medium;
            font-weight: 800;
            background: -webkit-linear-gradient(#FF9C3B, #FFBE3E);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .credit-legal {
        padding: 17px $margin-large;
        margin-top: $margin-large;
        border-radius: $radius-default;
        background-color: #EEF5FD;
        .control-radio {
          font-size: $fs-xsmall;
          line-height: 1.7rem;
          color: #5A5773;
          font-weight: 800;
          input {
            position: absolute;
            z-index: -1;
            opacity: 0;
            width: 0;
          }
          .control_indicator {
            background: none;
            border:1px solid #6D9BE0;
          }
          .control_indicator:after {
            top:4px;
            left:4px;
          }
        }
        .control input:not([disabled]):checked ~ .control_indicator,
        .control input:checked:focus ~ .control_indicator {
          background: #6D9BE0;
        }

        &.invalid {
          background-color: #ffdcdc;
          .control_indicator {
            border-color: $error;
          }
        }
      }
      button {
        margin-top: 34px;
        border:none;
        align-self: flex-end;
      }
    }
  }
}
