@import './variables';
@import './mixins';

.alert {
  border-radius: $radius-default;
  text-align: center;
  margin: 12px 0;
  padding: 8px 0;

  &.success {
    color: $white;
    background: $primary-color;
  }

  &.error {
    background: $danger-color;
    color: $white;
  }

  a {
    color: $white;
    font-weight: 800;
    text-decoration: underline;
  }

  &.info {
    margin: 0 auto 27px;
    max-width: 355px;
    padding: 20px 55px;
    border-radius: $radius-default;
    background-color: $yellow-light;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-weight: 800;
    font-size: $fs-xsmall;
    @include from(md) {
      margin: 0 auto 52px;
      max-width: initial;
      width: 640px;
      padding: 15px 55px;
    }
    @include from(lg) {
      width: 750px;
    }

    &.error {
      background: $danger-color;
      color: $white;
    }

    &.success {
      color: $white;
      background: $primary-color;
    }
  }
}

.light-alert {
  padding: 6px;
  margin: 6px 0;
  font-size: small;

  &.error {
    background-color: $light-red;
    border-color: $error;
  }
}