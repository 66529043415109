@import './variables';
// FIXME: À déplacer dans mon compte ?

.copyMailContainer {
  position: relative;
}

#recipient {
  .modal-content {
    border-radius: $radius-accent;
    border: none;
    .modal-header {
      color: white;
      border-radius: $radius-accent $radius-accent 0 0;
      background: linear-gradient(
        180deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(10, 98, 253, 1) 0%,
        rgba(20, 167, 208, 1) 100%
      );
      .close {
        margin: 0;
        &:focus {
          outline: none;
        }
        img {
          max-width: 20px;
        }
      }
    }
    .modal-body {
      margin: $margin-medium;
    }
  }
}
