@import './variables';
@import './mixins';

.operation{
  .signInSignUp-link {
    display: block;
    margin-bottom: 17px;
    font-size: $fs-default;
    line-height: 2.5rem;
    font-weight: 800;
    color:$secondary-color;
    @include from (md) {
      margin-bottom: 0;
    }
  }
  .signInSignUp-content {
    @include from (lg) {
      max-width: 70%;
      margin: 0 auto;
    }
    .signInSignUp-header {
      padding: 34px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .signInSignUp-titles {
        display: flex;
        flex-direction: column;
        .signInSignUp-title {
          font-size: $fs-large;
          line-height: 3.2rem;
          font-weight: 800;
        }
      }
    }
    .signInSignUp-form {
      display: flex;
      flex-direction: column;
      .input-item {
        margin-bottom: 20px;
        &:nth-child(5),
        &:nth-child(6) {
          margin-bottom: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      input:not([type='checkbox']) {
        border: 1px solid #E3E3E3;
        border-radius: $radius-small;
        background-color: transparent;
      }
      button {
        margin-top: 34px;
        border:none;
        align-self: flex-end;
      }
    }
  }

  .error {
    background-color: #ffdcdc;
    border-color: $error;
    padding: 6px;
    margin: 6px;
  }
}
