@import './variables';
.invitation {
  .invitation-bg {
    background: url("/img/home/parrainage-bg.png") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 400px;

  }
  .invitation-gift {
    padding:20px;
    max-width: 90%;
    margin: -300px auto 0;
    background-color: $white;
    border-radius: $radius-default;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 10px 24px 0 rgba(0, 0, 0, 0.08);
    .invitation-card {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .card-text {
        flex:4;
        h1 {
          font-size: $fs-huge;
          line-height: 34px;
          font-weight: 800;
        }
        h2 {
          margin-bottom: 27px;
          font-size: $fs-huge;
          line-height: 34px;
          font-weight: 800;
          .total {
            font-size: $fs-huge;
            line-height: 34px;
            font-weight: 800;
            background: linear-gradient(45deg, rgba(255,156,59,1) 0%, rgba(255,190,62,1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .text-gradient {
          background: linear-gradient(45deg, rgba(255,156,59,1) 0%, rgba(255,190,62,1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        p {
          font-size: $fs-default;
          color: #727A8E;;
        }
        .btn {
          margin-top: 22px;
          padding:10px 34px;
          font-size: $fs-default;
        }
      }
      img {
        display: none;
      }
    }
  }
  .invitation-win {
    margin-top:50px;
    text-align: center;
    h2 {
      margin-bottom: 22px;
      font-size: $fs-huge;
      line-height: 34px;
      font-weight: initial;
      text-align: center;
    }
    .invitation-win-text {
      font-size: $fs-default;
      color: #727A8E;
      text-align: center;
    }
    .btn {
      margin-top: 22px;
      padding:10px 34px;
      font-size: $fs-default;
    }
  }
  #process {
    margin: 60px 0;
    .process-wrapper {
      .title-point {
        margin-bottom:20px;
        h2 {
            padding-right: 5px;
            font-size: $fs-huge;
            line-height: 38px;
            display: inline;
        }
        .point {
            display: inline-block;
            width:14px;
            height: 14px;
            background-color:$primary-color;
            border-radius: $radius-half;
        }
      }
      .parrainage-process {
        list-style: none;
        padding:0;
        .process-item {
          position: relative;
          margin-top: 50px;
          .process-title {
            margin-bottom: $margin-medium;
            font-size: $fs-medium;
            font-weight: 800;
          }
          .process-description {
            font-size:16px;
            color: #959595;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $layout-lg) {
  .invitation {
    .invitation-gift {
      max-width: 80%;
      padding: 40px;
      .invitation-card {
        justify-content: space-between;
        .card-text {
          max-width: 80%;
          h1 {
            font-size: $fs-xhuge;
            line-height: 48px;
          }
          p {
            font-size: $fs-medium;
            line-height: 30px;
          }
        }
        img {
          display: block;
          max-width: 150px;
        }
      }
    }
    .invitation-win {
      max-width: 80%;
      margin: 50px auto 0;
      h2 {
        font-size: $fs-xhuge;
        line-height: 48px;
        font-weight: initial;
      }
      .invitation-win-text {
        font-size: $fs-medium;
        line-height: 30px;
      }
    }
    #process {
      margin: 60px 0;
      .process-wrapper {
        .title-point {
          margin-bottom:20px;
          h2 {
              padding-right: 5px;
              font-size: $fs-huge;
              line-height: 38px;
              display: inline;
          }
          .point {
              display: inline-block;
              width:14px;
              height: 14px;
              background-color:$primary-color;
              border-radius: $radius-half;
          }
        }
        .parrainage-process {
          margin-top: 40px;
          list-style: none;
          padding:0;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .process-item,
          .process-finish {
            margin-top: 0px;
            flex: 0 0 23%;
            img {
              height: 150px;
              width: auto;
            }
          }
        }
      }
    }
  }
}