@import './variables';

.container {
  .header {
    background-color: $primary-color;
    color: $white;
    padding: 3rem 0;

    h1 {
      font-size: $fs-xhuge;
      line-height: 4rem;
    }

    &.white {
      background: $white;
      color: $black;
    }

    .subtitle {
      color: $white;
      line-height: 4rem;
      font-size: $fs-xhuge;
    }
  }

  .subtitle-page {
    margin-top: 6.8rem;
    font-size: $fs-huge;
    height: 10rem;
    line-height: 10rem;
    font-weight: 800;
    padding-left: 15px;
  }
}

@media only screen and (min-width: $layout-xs) {
  .container {
    .header {
      padding: 4rem 0;
      h1 {
        line-height: 7rem;
      }
    }
  }
}

@media only screen and (max-width: $layout-sm) {
  .container {
    .header {
      .subtitle {
        color: $white;
        line-height: 2rem;
        font-size: $fs-medium;
      }
    }

    .subtitle-page {
      margin-top: 3rem;
      font-size: $fs-large;
      line-height: 120%;
      height: auto;
      margin-bottom: 3rem;
    }
  }
}

#halloween {
  .modal-content {
    padding: 0;
    margin: 0;
    .modal-header {
      border: none;
      justify-content: end;
      z-index: 1;
    }
    .modal-body {
      padding: 0;
    }
  }
}