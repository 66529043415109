// Source: https://fonts.bunny.net/family/raleway

// 400
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/fonts/raleway-latin-400-normal.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/fonts/raleway-latin-400-italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// 600
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(/fonts/raleway-latin-600-normal.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// 800
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(/fonts/raleway-latin-800-normal.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}