@import './variables';

.block-input-primary {
  margin-top: 1.2rem;
  .checkbox,
  .radio {
      font-size: $fs-xsmall;
      display: flex;
      align-items: center;
      input {
          padding: 0;
          -webkit-appearance: none;
          appearance: none;
          background-color: $white;
          margin: 0 12px 0 0;
          color: $primary-color;
          width: 20px;
          height: 20px;
          border: 1px solid $primary-color;
          display: grid;
          place-content: center;
        &:before {
          content: "";
          width: 12px;
          height: 12px;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          background-color: $primary-color;
        }
        &:checked {
          &::before {
            transform: scale(1);
          }
        }
      }
  }
  .radio {
      input {
          border-radius: $radius-large;

          &:before {
              border-radius: $radius-accent;
          }
      }
  }

  &.disabled {
      opacity: 0.6;

      input {
          border-color: $cultured;

          &::before {
              background-color: $cultured;
          }
      }
  }
}

.input-item {
  &.error {
    input {
      background-color: #feeded !important;
      color: #f04646;
      border-color: #f04646;
    }
    span {
      background-color: #feeded;
      color: #f04646;
    }

    ::placeholder {
      color: #f04646;
    }
  }
}