@import './variables';
@import './mixins';

#basket {
    background: $yellow-bg;
    padding: 34px 0 225px;
    @include from (md) {
        padding: 80px 0 225px;
    }

    .error {
        font-size: $fs-xsmall;
        color: $danger-color;
        font-weight: 800;
        font-style: italic;
    }

    .payment-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        @include from (lg) {
            justify-content: space-between;
        }
        h2 {
            font-size: $fs-medium;
            @include from (lg) {
                font-size: $fs-large;
            }
        }
        #payment {
            flex: 1 1 100%;
            @include from (lg) {
                flex: 0 0 65%;
            }
            .basket {
                .card-summary {
                    margin-bottom: 12px;
                    .card-details {
                        padding:12px $margin-medium;
                        background: $white;
                        border: 1px solid $border-grey;
                        border-radius: $radius-accent;
                        display: flex;
                        &.more {
                            border-radius: $radius-accent $radius-accent 0 0;
                        }
                        .container-img {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                        .card-img {
                            width: 150px;
                            border-radius: $radius-default;
                            border: 2px solid $grey-border;
                        }
                        .card-infos {
                            padding: 10px 0;
                            margin-left: 12px;
                            flex: 2;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .card-infos-header {
                                display: flex;
                                flex-direction: column;
                                .card-name {
                                    font-size: $fs-xsmall;
                                    font-weight: 800;
                                    color: $independence-grey;
                                }
                                .card-price {
                                    font-size: $fs-accent;
                                    line-height: 1.6rem;
                                    font-weight: 800;
                                }
                            }
                            .card-actions {
                                display: flex;
                                align-items: center;
                                button {
                                    padding: 0;
                                    margin-right: $margin-medium;
                                    border: none;
                                    background: none;
                                    font-size: $fs-xsmall;
                                    line-height: 1.6rem;
                                    font-weight: 800;
                                    color: $primary-color;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                                a {
                                    font-size: $fs-xsmall;
                                    line-height: 1.6rem;
                                    font-weight: 800;
                                    color: $primary-color
                                }

                                .quantity {
                                    display: flex;
                                    flex-direction: row;
                                    margin-top: $margin-medium;
                                    .btn,
                                    input {
                                        width: 24px;
                                        height: 24px;
                                        border: 1px solid $spanish-grey;
                                        margin: 0;
                                        border-radius: 0;
                                        padding: 0;
                                    }

                                    .btn {
                                        background-color: $cultured;
                                        color: $spanish-grey;
                                        cursor: pointer;
                                        text-decoration: none;

                                        img {
                                            height: 12px;
                                        }

                                        &.left {
                                            border-top-left-radius: $radius-default;
                                            border-bottom-left-radius: $radius-default;
                                        }
                                        &.right {
                                            border-top-right-radius: $radius-default;
                                            border-bottom-right-radius: $radius-default;
                                        }

                                        &.disabled {
                                            cursor: not-allowed;
                                            opacity: 0.6;
                                        }
                                    }
                                    input {
                                        width: 36px;
                                        margin: 0;
                                        border-left: none;
                                        border-right: none;
                                        background: $white;
                                        text-align: center;

                                    }
                                }
                            }
                        }
                        .delete-card {
                            border: none;
                            background: none;
                            align-self: center;
                            img {
                                width: 17px;
                            }
                        }
                    }
                    .details-informations {
                        background-color: #FCFCFC;
                        border-left: 1px solid $border-grey;
                        border-right: 1px solid $border-grey;
                        border-bottom: 1px solid $border-grey;
                        .more-body {
                            padding: 13px $margin-large;
                            display: flex;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            .to {
                                flex: 0 0 100%;
                                @include from (lg) {
                                    flex: 1;
                                }
                            }
                            .message {
                                flex: 0 0 100%;
                                @include from (lg) {
                                    flex: 2;
                                    margin-left: $margin-large;
                                }
                            }
                            .bloc {
                                display: flex;
                                align-items: flex-start;
                                font-weight: 800;
                                font-size: $fs-xsmall;

                                .label {
                                    flex: 0 0 auto;
                                    color: #D1D0D3
                                }
                                .text {
                                    margin-left: 8px;
                                    color: #454B69
                                }
                            }
                        }
                        &.collapse {
                            display: none;
                        }
                        &.collapsing {
                            height: 0;
                            position: relative;
                            overflow: hidden;
                            transition: height 0.3s ease;
                        }
                        &.show {
                            display: block;
                        }
                    }
                    .more-informations {
                        padding: 10px $margin-large;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $white;
                        border: 1px solid $border-grey;
                        border-top: none;
                        border-radius: 0 0 $radius-default $radius-default;
                        cursor: pointer;
                        .more-label {
                            margin-right: 9px;
                            color: $independence-grey;
                            font-size: $fs-xsmall;
                            line-height: 1.7rem;
                        }
                    }
                }
            }
            .add-card {
                margin-top: $margin-large;
                display: flex;
                align-items: center;
                font-size: $fs-default;
                line-height: 1.9rem;
                font-weight: 800;
                color: $primary-color;
                svg {
                    margin-right: 12px;

                    path {
                        fill: var(--primary);
                    }
                }
            }
            .payment-type {
                margin-top: 52px;
                .payment-method {
                    margin-top: 22px;
                    padding:36px $margin-large;
                    background: $white;
                    border: 1px solid $border-grey;
                    border-radius: $radius-accent;
                    .method {
                        margin-bottom: $margin-large;
                        display: flex;
                        align-items: center;
                        input:not([type='checkbox']) {
                            width: 20px;
                            height: 20px;
                            background-color: $primary-color
                        }
                        label {
                            margin-left: 20px;
                            flex: 2;
                        }
                        img {
                            max-height: 50px;
                        }
                    }
                }
            }
        }
        #summary {
            margin-top: 32px;
            flex: 1 1 100%;
            @include from (lg) {
                margin-top: 0;
                flex: 0 0 350px;
            }
            .summary-wrapper {
                padding:34px $margin-medium;
                background: $white;
                border: 1px solid $border-grey;
                border-radius: $radius-accent;
                .code-promo {
                    padding-bottom: 34px;
                    margin-bottom: 34px;
                    border-bottom: 1px solid rgba(194,194,194,0.3);
                    display: flex;
                    flex-direction: column;
                    label {
                        font-size: $fs-small;
                        color: #C2C2C2
                    }
                    .code-input {
                        margin-top: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        input {
                            background: none;
                            padding:12px 17px;
                            font-size: $fs-small;
                            color: $black;
                            font-weight: 800;
                            border: 1px solid rgba(0,0,0,0.1);
                            border-radius: 0;
                            width: 70%;
                            height: 40px;
                        }
                        button {
                            width: 25%;
                            background-color: $primary-color;
                            border-radius: $radius-small;
                            font-size: $fs-xsmall;
                            line-height: 1.7rem;
                            font-weight: 800;
                            height: 40px;
                            color: $white;
                            border:none;
                            &:disabled {
                                background-color: $cultured;
                            }
                        }
                    }
                    .code-enabled {
                        margin-top: 8px;
                        display: flex;
                        align-items: center;
                        .code-information {
                            margin-left: 8px;
                            font-size: $fs-xsmall;
                            color: #C2C2C2
                        }
                    }
                }
                .summary-total {
                    .roundPrice {
                        cursor: pointer;
                        width: 20px;

                        path {
                            fill: var(--primary);
                        }
                    }
                    .pictoSpace {
                        padding-right: 20px;
                    }
                    & > div {
                        margin-bottom: 17px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-weight: 800;
                        span {
                            &:first-child {
                                font-size: $fs-small;
                                color: #C2C2C2;
                            }
                            &:last-child {
                                font-size: $fs-default;
                            }

                            &.total {
                                background: linear-gradient(45deg, var(--gradient-end) 0%, var(--gradient-start) 100%);
                                // background: linear-gradient(45deg, #ff9c3b 0%, #ffbe3e 100%);
                                -webkit-background-clip: text;
                                background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }

                    .partners {
                        display: flex;
                        flex-direction: column;

                        div.partner {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin: $margin-xs 0 0;
                            cursor: pointer;

                            div:first-child {
                                display: flex;
                                flex-direction: row;
                                img {
                                    max-width: 30%;
                                    margin-right: $margin-xs;
                                }

                                div {
                                    margin: 0;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;

                                    span {
                                        font-size: $fs-xsmall;

                                        &.info {
                                            text-decoration: underline;
                                            font-weight: 400;
                                            color: $black;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }

                            div.no {
                                align-items: center;

                                span {
                                    font-size: $fs-small;
                                }
                            }
                        }
                    }
                }
                .payment-cgv {
                    display: flex;
                    flex-direction: column;

                    .block-input-primary {
                        input {
                            flex: 1 0 20px;
                        }
                    }

                    .payment-method {
                        padding: $margin-large 0;
                        background: $white;
                        display: flex;
                        .method {
                            width: 33%;
                            margin-bottom: $margin-large;
                            display: flex;
                            align-items: center;
                            padding: 0 8px;
                            input:not([type='checkbox']) {
                                width: 12px;
                                height: 12px;
                                background-color: $primary-color;
                            }
                            label {
                                margin-left: 4px;
                                flex: 2;
                            }
                            img {
                                max-height: 50px;
                                max-width: 100%;
                            }

                            &.disabled {
                                opacity: 0.6;
                                cursor: not-allowed;

                                input,
                                label {
                                    cursor: not-allowed;
                                }
                            }
                        }

                        &.disabled {
                            opacity: 0.6;
                        }
                    }

                    button {
                        margin-top: 18px;
                        padding: 5px $margin-large;
                        background-color: $primary-color;
                        border-radius: $radius-small;
                        font-size: $fs-default;
                        line-height: 2.4rem;
                        font-weight: 800;
                        height: 40px;
                        color: $white;
                        border:none;
                        &:disabled {
                            background-color: $cultured;
                        }
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
    }

    .modal-overlay {
        h1 {
            font-size: $fs-huge;
        }
        img {
            margin-top: $margin-large;
            max-width: 60%;
        }
    }
}

#roundPrice {
    border: 0;
    border-radius: $radius-default;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;

    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    transition: all .35s ease-in-out;

    &.display {
        height: auto;
        padding: $margin-medium $margin-small;
        margin: $margin-small (-$margin-small);
        border: 1px solid $primary-color;

        @include from (lg) {
            margin-left: -20vw;
        }
    }

    .title {
        font-size: $fs-medium;
        font-weight: 800;
    }

    .content {
        padding: $margin-medium 0;
        font-size: $fs-small;

        strong {
            font-weight: 800;
            color: $primary-color;
            background: linear-gradient(45deg, var(--gradient-end) 0%, var(--gradient-start) 100%);
            // background: linear-gradient(45deg, #ff9c3b 0%, #ffbe3e 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .remember {
        width: 100%;
        display: flex;
        padding: 0 8px;
    }
    .actions {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;

        @include from (md) {
            flex-direction: row;
        }

        button {
            margin: 0 $margin-small;
            height: auto !important;
        }
    }
}

#modal-paiement {
    .modal-dialog {
        .modal-content {
            border-radius: $radius-accent;
            padding: 20px $margin-medium;
            position: relative;
            @include from (lg) {
                padding: 30px $margin-large;
            }
            .modal-close {
                padding: 8px;
                position: absolute;
                right:14px;
                top:18px;
                border:none;
                background: none;
                z-index: 2;
                &:focus {
                    outline: none;
                }
            }
            .modal-body {
                .signInSignUp-link {
                    padding: 0;
                    display: block;
                    margin-bottom: 17px;
                    font-size: $fs-default;
                    line-height: 2.5rem;
                    font-weight: 800;
                    color:$secondary-color;
                    background: none;
                    border:none;
                    @include from (md) {
                      margin-bottom: 0;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                .signInSignUp-header {
                    padding: 34px 0;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .signInSignUp-title {
                        flex: 0 0 100%;
                        font-size: $fs-large;
                        line-height: 3.2rem;
                        font-weight: 800;
                    }

                }
                .signInSignIn-form,
                .signInSignUp-form,
                .signInSignUpConfirm-form {
                    display: flex;
                    flex-direction: column;
                    .input-item {
                      margin-bottom: 20px;
                      &:nth-child(5),
                      &:nth-child(6) {
                        margin-bottom: 0;
                      }
                      &:last-of-type {
                        margin-bottom: 0;
                      }
                    }
                    input:not([type='checkbox']) {
                        border: 1px solid $border-grey;
                        border-radius: $radius-small;
                        background-color: transparent;
                    }
                    button {
                      margin-top: 34px;
                      border:none;
                      align-self: flex-end;
                    }
                    .forgot {
                        font-size: $fs-small;
                    }

                    .error {
                        font-size: $fs-xsmall;
                        color: $error;
                    }
                }
                .modal-signIn,
                .modal-signUp {
                    &.hidden {
                        display: none;
                    }
                }
            }
        }
    }
}