@import './variables';

.mot-de-passe-oublie,
.nouveau-mot-de-passe {
  background-color: $yellow-bg;
  padding: 50px 0 100px;
  h1 {
    text-align: center;
    font-size: $fs-medium;
    margin-bottom: 50px;
  }
  .password-wrapper {
    form {
      .error-box {
        padding: 20px;
        max-width: 700px;
        margin: 0 auto $margin-large;
        border-radius: $radius-default;
        display: flex;
        align-items: center;
        background-color: #feeded;
        .text-info {
          color: #f04646;
          margin-left: 10px;
          margin-bottom: 0;
          font-size: $fs-small;
          font-weight: 800;
        }
      }
      .success-box {
        padding: 20px;
        max-width: 700px;
        margin: 0 auto $margin-large;
        border-radius: $radius-default;
        display: flex;
        align-items: center;
        background-color: #eaf9f2;
        .code-box-link {
          font-size: $fs-default;
          color: $secondary-color;
          font-weight: 800;
        }
        .text-info {
          color: #30c67c;
          margin-left: 10px;
          margin-bottom: 0;
          font-size: $fs-small;
          font-weight: 800;
        }
      }
      .form-input {
        margin: 0 auto;
        max-width: 850px;
        border: 1px solid $grey-border;
        background-color: $white;
        border-radius: $radius-accent;
        padding: 3rem;
        .input-item {
          margin-bottom: $margin-large;
          position: relative;
          .hash-password {
            position: absolute;
            top: 61px;
            left: 0;
            display: block;
            padding: $margin-medium;
            background-color: #253e67;
            border-radius: $radius-large;
            width: 257px;
            visibility: hidden;
            z-index: 2;
            &::before {
              display: none;
              content: '';
              position: absolute;
              border-right: 10px solid #253e67;
              border-bottom: 10px solid transparent;
              border-top: 10px solid transparent;
              top: 50%;
              transform: translateY(-50%);
              right: 100%;
            }
            .hash-title {
              margin-bottom: 10px;
              font-size: $fs-small;
              line-height: 1.7rem;
              font-weight: 800;
              color: $white;
            }
            .hash-condition {
              position: relative;
              padding-left: 12px;
              font-size: $fs-small;
              line-height: 1.7rem;
              color: $white;
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: $radius-full;
                background-color: $white;
              }
              &.condition-ok {
                color: $primary-color;
                font-weight: 800;
              }
            }
          }
          .icon-eye {
            padding: 0;
            position: absolute;
            width: 30px;
            height: 30px;
            background: url('/icons/eye.svg') no-repeat center center;
            background-size: contain;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
          }
          .error {
            font-size: $fs-xsmall;
            font-style: italic;
            margin: 3px 0;
            color: $error;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $layout-lg) {
  .mot-de-passe-oublie {
    h1 {
      font-size: $fs-xhuge;
    }
  }
}

@media (min-width: $layout-xl) {
  .mot-de-passe-oublie {
    .password-wrapper {
      form {
        .form-input {
          .input-item {
            .hash-password {
              left: 102%;
              top: 50%;
              transform: translateY(-50%);
              bottom: inherit;
              &::before {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
