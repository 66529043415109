@import './_variables';
@import './mixins';

.landing {
  width: 100%;
  color: $white;
  background-color: #ccd6e5;

  .viewport {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @include from (md) {
      max-width: 720px;
    }
    @include from (lg) {
      max-width: 960px;
    }
    @include from (xl) {
      max-width: 1140px;
    }
  }

  .viewbox {
    min-height: calc(100vh - 90px);

    &.presentation {
      background-color: #7999ca;
      clip-path: ellipse(110% 60% at 50% 40%);

      .viewport {
        min-height: calc(100vh - 90px);
        display: grid;
        grid-template-columns: 100%;

        @include from(md) {
          grid-template-columns: 50% 50%;
        }

        .bloc {
          display: grid;
          justify-content: center;

          &.text {
            padding: 0 32px;
            display: flex;
            flex-direction: column;

            @include from (md) {
              padding: 0;
            }

            h1,
            p,
            .btn {
              margin: 0 0 32px 0;
            }
            h1 {
              margin-bottom: 64px;
            }
          }

          img {
            margin: 48px auto;
            max-width: 90%;

            @include from(md) {
              margin: auto;
            }
          }
        }
      }
    }

    &.customers {
      background-color: #ccd6e5;

      .viewport {
        min-height: calc(100vh - 90px);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        min-height: calc(100vh - 90px - 10vh);

        padding-top: 64px;
        padding-bottom: 64px;

        @include from(md) {
          padding: 0;
        }

        h2 {
          text-align: center;
          color: #7999ca;
        }

        .testimoniaux {
          display: grid;
          grid-template-columns: 100%;
          margin: 32px;

          @include from(md) {
            grid-template-columns: 50% 50%;
          }

          @include from(lg) {
            grid-template-columns: 25% 25% 25% 25%;
          }

          .testimonial {
            background: $white;
            border-radius: $radius-xlarge;
            padding: $margin-medium;
            color: #000;
            margin: $margin-medium 0;
            box-shadow: rgb(0 0 0 / 3%) 0px 24px 32px, rgb(0 0 0 / 6%) 0px 8px 32px;

            font-size: $fs-small;

            @include from(md) {
              margin: $margin-medium;
            }

            .title {
              margin-bottom: 8px;
            }

            .text {
              font-weight: 800;
              font-style: italic;
              text-align: center;
            }

            .who {
              text-align: right;
            }
          }
        }
      }
    }

    &.how-it-works {
      background-color: #7999ca;
      clip-path: ellipse(110% 60% at 50% 60%);
      background-image: url('/img/landings/noel-2022/cards-fr.png');
      background-position: top right;
      background-repeat: no-repeat;
      background-size: contain;

      .viewport {
        min-height: calc(100vh - 90px);

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: center;
        padding: 64px;

        @include from(md) {
          justify-content: center;
        }

        h2 {
          padding-bottom: $margin-large;

          @include from(md) {
            padding-bottom: 64px;
          }
        }
      }
    }
  }
}