@import './_variables';
@import './mixins';

main.home {
    overflow: hidden;
    a {
        padding: 12px 32px;
        height: 48px;
        font-size: $fs-small;
        line-height: 19px;
    }
    .introduction {
        min-height: initial;
        width: 100vw;
        max-width: 100%;
        z-index: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;

        background-image: radial-gradient(circle at bottom center, var(--gradient-end), var(--gradient-start));
        clip-path: ellipse(140% 60% at 50% 40%);

        .wrapper {
            padding-top: 20px;

            &.flex-template {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: row;
            }

            &.grid-template {
                display: grid;
                grid-template-columns: 100%;
            }

            .left {
                flex: 0 0 100%;
                margin: 50px auto 120px;
                text-align: center;
                color:white;
                h1 {

                    font-size: $fs-huge;
                    line-height: 38px;
                    position: relative;

                    span {
                        font-weight: 400;
                        display: block;
                        &:last-child {
                            font-weight: 800;
                            display: inline;
                            position: relative;
                        }
                    }

                    &:after {
                        content: "";
                        width: 14px;
                        height: 14px;
                        background-color:$primary-color;
                        border-radius: $radius-half;
                        position: absolute;
                        bottom: 5px;
                        margin-left: 4px;
                    }
                }
                p {
                    margin: 2rem 0;
                }
                .letsGo,
                #letsGo {
                    position: relative;
                    font-size: $fs-medium;
                    line-height: 27px;
                    padding:20px 40px;
                    height: initial;
                    margin-top: $margin-medium;
                    &:focus {
                        outline: 0;
                    }

                    &:before, &:after{
                        position: absolute;
                        content: '';
                        display: block;
                        width: 140%;
                        height: 100%;
                        left: -20%;
                        z-index: -1000;
                        transition: all ease-in-out 0.5s;
                        background-repeat: no-repeat;
                    }

                    &:before{
                        display: none;
                        top: -75%;
                        background-image:
                          radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle,  transparent 20%, $secondary-color 20%, transparent 30%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle,  transparent 10%, $secondary-color 15%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%);
                      background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;

                    }

                    &:after{
                        display: none;
                        bottom: -75%;
                        background-image:
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle,  transparent 10%, $secondary-color 15%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%),
                        radial-gradient(circle, $secondary-color 20%, transparent 20%);
                      background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
                    }

                    &:active{
                        transform: scale(0.9);
                        box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
                    }

                    &.animate{
                        &:before{
                          display: block;
                          animation: topBubbles ease-in-out 0.75s forwards;
                        }
                        &:after{
                          display: block;
                          animation: bottomBubbles ease-in-out 0.75s forwards;
                        }
                    }
                }
            }
            .right {
                align-self: center;
                justify-self: center;
                margin: $margin-large 0;
                margin-bottom: $margin-large * 2;
                img {
                    max-width: 100%;
                }
            }
            .animation {
                position: relative;
                flex: 0 0 100%;
                height: 380px;
                margin: 50px auto;
                .computer {
                    width: 100%;
                    height: 100%;

                    &.link {
                        cursor: pointer;
                    }
                    .slider {
                      .slide {
                        opacity: 0;

                        img {
                            top: 11px;
                            left: 6px;
                            position: absolute;
                            width: calc(100% - 15px);
                            height: auto;
                            transition: all 1s ease-in-out;
                        }
                        &.current{
                            opacity: 1;
                        }
                      }

                    }
                    .background {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: url('/img/home/computer_iMac.png') no-repeat;
                        background-size: contain;
                        z-index: 11;
                    }
                }
                .smartphone {
                    z-index: 20;
                    position: absolute;
                    right: 0;
                    bottom: 40px;
                    width: 130px;
                    height: 247px;

                    &.link {
                        cursor: pointer;
                    }
                    .slider {
                        position: absolute;
                        top: 12px;
                        left: 11px;
                        background: #212529;
                        width: calc(100% - 21px);
                        height: calc(100% - 23px);
                        border-radius: $radius-large;
                        .slide-smartphone {
                            position: absolute;
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: $radius-large;
                            transition: all 1s ease-in-out;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                max-width: 80%;
                                max-height: 80%;
                            }

                            &.current {
                                opacity: 1;
                            }
                        }
                    }
                    .background {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: url('/img/home/smartphone.png') no-repeat;
                        background-size: cover;
                    }
                }
                .buttons {
                    position: absolute;
                    bottom: -20px;
                    width: 100%;
                    margin: 0 auto;

                    .dots {
                        margin: 0 auto;
                        display: flex;
                        .dot {
                            background: $light-grey;
                            border-radius: $radius-small;
                            width: 8px;
                            height: 8px;
                            margin: 0 4px;
                            border: 1px solid $white;
                            cursor: pointer;
                            &.current {
                                background: $primary-color;
                                width: 10px;
                                height: 10px;
                                border: 1px solid $white;
                            }
                        }
                    }


                }
            }
        }

    }
    .experience {
        .experience-content {
            padding: 50px 0;
            .title-point {
                text-align: center;
                margin-bottom:20px;
                .point {
                    display: inline-block;
                    width:14px;
                    height: 14px;
                    background-color:$primary-color;
                    border-radius: $radius-half;
                }
            }
            .experience-baseline {
                text-align: center;
                font-size:20px;
            }
            .experience-items {
                display: flex;
                flex-wrap: wrap;
                margin: 50px 0;
                .experience-item {
                    margin-bottom: 10px;
                    padding: 10px;
                    height: 400px;
                    flex: 100%;
                    .experience-bg {
                        position: relative;
                        border-radius: $radius-default;
                        height: 100%;
                        width: 100%;
                        background-size: cover;
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        display: flex;
                        align-items: flex-end;
                        padding: 20px;
                        overflow: hidden;
                        .experience-bigtitle {
                            display: none;
                        }
                        .experience-item-content {
                            z-index: 1;
                            color: $white;

                            .experience-title {
                                display: flex;
                                align-items: flex-start;
                                flex-direction: column;
                                .title1 {
                                    font-size: $fs-medium;
                                    font-weight: 800;
                                    color: $white;
                                }
                                .title2 {
                                    position: relative;
                                    font-size:24px;
                                    font-weight: initial;
                                    color: $white;
                                    display: inline-block;
                                    .point {
                                        margin-left: 5px;
                                        display: inline-block;
                                        width:10px;
                                        height: 10px;
                                        background-color:$primary-color;
                                        border-radius: $radius-half;
                                    }
                                }

                            }
                        }
                    }
                    .experience-offer {
                        background-image: url("/img/home/offrir-bg.png");
                        &:after {
                            position: absolute;
                            content: "";
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(0deg, rgba(128,102,0,1) 0%, rgba(128,102,0,0) 100%);
                        }
                    }
                    .experience-activated {
                        background-image: url("/img/home/activer-bg.png");
                        &:after {
                            position: absolute;
                            content: "";
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(0deg, rgba(0,37,101,1) 0%, rgba(0,37,101,0) 100%);
                        }
                    }
                    .experience-enjoy {
                        background-image: url("/img/home/profiter-bg.png");
                        &:after {
                            position: absolute;
                            content: "";
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                        }
                    }
                }
            }
        }
        .experience-items-container {
            display: flex;
            flex-direction: row;

            .space {
                width: 40px;
                display: flex;
                align-items: center;
                padding: 0 6px;

                svg {
                    path {
                        fill: var(--primary);
                    }
                }
            }
            .card {
                border: 2px solid $primary-color;
                border-radius: $radius-large;
                width: calc((100% - 80px)/3);
                padding: 32px 0;
                text-align: center;

                .image {
                    height: 120px;
                    margin-bottom: 32px;
                }

                .info {
                    width: 100%;
                    background-color: #feeded;
                    color: #f04646;
                    font-weight: 800;
                    padding: 4px 0;
                }
            }

            @media only screen and (max-width: $layout-sm) {
                flex-direction: column;
                .space {
                    width: 100%;
                    justify-content: center;
                    transform: rotate(90deg);
                    svg {
                        height: 40px;
                    }
                }
                .card {
                    width: 100%;
                }
            }
        }

        .experience-footer {
            display: flex;
            padding: 64px 0;
            justify-content: center;
            align-items: center;
        }
    }
    .promotionnal {
        background: rgb(246, 246, 246);
        .promotionnal-content {
            padding: 50px 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            .left-side,
            .right-side{
                flex: 0 0 100%;
            }
            .left-side {
                max-width: 600px;
            }
            .right-side {
                .title-point {
                    text-align: center;
                    margin-bottom:20px;
                    h2 {
                        padding-right: 5px;
                    }
                    .point {
                        display: inline-block;
                        width:14px;
                        height: 14px;
                        background-color:$primary-color;
                        border-radius: $radius-half;
                    }
                }
                .content {
                    h3 {
                        font-size: $fs-large;
                    }
                    ul {
                        margin:0;
                        padding:0;
                        text-align: center;
                    }
                    #straplines li {
                        display: none;
                        list-style: none;
                    }
                    .typewriter {
                        width: max-content;
                        margin: 0 auto;
                    }
                    .typewriter h3 {
                        overflow: hidden;
                        border-right: .15em solid $primary-color;
                        white-space: nowrap;
                        width: max-content;
                        margin: 0 auto;
                        letter-spacing: 2.9px;
                        animation:
                        typing 3s steps(40, end),
                        blink-caret .75s step-end infinite;
                      }


                      @keyframes typing {
                        from { width: 0 }
                        to { width: 100% }
                      }


                      @keyframes blink-caret {
                        from, to { border-color: transparent }
                        50% { border-color: $primary-color; }
                      }
                }
                .buttons {
                    padding-top: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    .button-item {
                        margin:5px;
                    }
                }
            }
        }

    }
    .how-it-works {
       padding:50px 0;
       .works-wrapper {
           text-align: center;
            .steps {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 6rem;
                .step-item {
                    position: relative;
                    margin: $margin-medium 0;
                    padding: $margin-large;
                    background-color: white;
                    box-shadow: rgba(0, 0, 0, 0.03) 0px 24px 32px, rgba(0, 0, 0, 0.06) 0px 8px 32px;
                    border-radius: $margin-medium;
                    overflow: hidden;
                    h3 {
                        padding-right: 5px;
                        font-size: $fs-medium;
                        line-height: 24px;
                        position: relative;
                        display: inline;
                        &:after {
                            content: "";
                            width: 14px;
                            height: 14px;
                            background-color:$primary-color;
                            border-radius: $radius-half;
                            position: absolute;
                            bottom: 3px;
                            left: 100%;
                        }
                    }
                    p {
                        z-index: 1;
                        position: relative;
                    }
                    &:first-child {
                        &:before {
                            position: absolute;
                            top: -100px;
                            left: -40px;
                            content:"";
                            background-color: $primary-color;
                            width: 200px;
                            height: 200px;
                            border-radius: $radius-half;
                        }
                        &:after {
                            position: absolute;
                            bottom: -100px;
                            right: -100px;
                            content:"";
                            background-color: $primary-color;
                            width: 200px;
                            height: 200px;
                            border-radius: $radius-half;
                            opacity: 0.6;
                        }
                    }
                    &:nth-child(2){
                        &:after {
                            position: absolute;
                            bottom: -120px;
                            left: -50px;
                            content:"";
                            background-color: $primary-color;
                            width: 200px;
                            height: 200px;
                            border-radius: $radius-half;
                        }
                    }
                }
                .question {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .question-title {
                        margin-bottom:20px;
                        font-weight: 800;
                        font-size: $fs-large;
                    }
                }
            }
       }
       .payment {
            padding-top: 150px;
            text-align: center;

            p {
                margin-top: 60px;
            }

            .method {
                margin-top: 40px;
                img {
                    height: 46px;
                    margin: 0 $margin-large;
                }
            }
       }
       .testimonials {
           padding:60px 0;
           .title-point {
            text-align: center;
            margin-bottom:20px;
                h2 {
                    padding-right: 5px;
                }
                .point {
                    display: inline-block;
                    width:14px;
                    height: 14px;
                    background-color:$primary-color;
                    border-radius: $radius-half;
                }
            }
            .testimonials-wrapper {
                margin-top:30px;
                display: flex;
                align-items: center;
                flex-direction: column-reverse;
                .testimonials-logo {
                    margin-top:30px;
                    text-align: center;
                    .logos {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        text-align: center;
                        .logo-item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 50%;
                            margin-bottom: 20px;
                            .logo {
                                max-width: 60%;
                            }
                        }
                    }
                }

               .testimonials-cards {
                    padding: 10px;
                    width: 350px;
                    position: relative;

                    .cards-content {
                        display: flex;
                        width: 400%;
                        .card-item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-right: 20px;
                            max-width: 350px;
                            width: 350px;
                            padding: 10px $margin-large;
                            background-color: white;
                            background-color: white;
                            border-radius: $radius-accent;
                            box-shadow: rgba(0, 0, 0, 0.04) 0px 12px 20px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
                            svg {
                                width: 100px;
                                height: 100px;
                                path {
                                    fill: var(--primary);
                                }
                            }
                            .quote-img {
                                max-width: 100px;
                            }
                            .card-name {
                                padding-top:10px;
                                display: flex;
                                align-items: center;
                                .card-bg-img {
                                    margin-right: 12px;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: $radius-half;
                                    display: flex;
                                    flex: 0 0 auto;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        max-width: 80%;
                                    }
                                }
                                .card-infos-perso {
                                    display: flex;
                                    flex-direction: column;
                                    .name {
                                        font-weight: 800;
                                    }
                                }
                            }
                            .card-infos {
                                height: 100%;
                                border-bottom: 1px solid $light-grey;
                            }
                        }
                    }
               }
            }
        }
        .support {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            .logos {
                margin: 10px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                width: 100%;
            }
            img {
                max-width: 70px;
            }
        }
    }

    h2 {
        font-size: $fs-huge;
        line-height: 38px;
        text-align: center;
        display: inline;

        @include from('md') {
            font-size: $fs-xxhuge;
            line-height: 1.16;
        }

    }
}

@media only screen and (max-width: 425px)  {
    main.home {
        .introduction {
            .wrapper {
                .animation {
                    height: 380px;
                    max-width: 524px;
                }
            }
        }
    }
 }

@media only screen and (min-width: 426px) {
    main.home {
        .introduction {
            .wrapper {
                .animation {
                    height: 463px;
                    max-width: 524px;
                }
            }
        }
    }
}

@media only screen and (min-width: $layout-xl) {
    main.home {
         .introduction {
            clip-path: ellipse(110% 60% at 50% 40%);
            .animation {
                .smartphone {
                    width: 160px;
                    height: 304px;
                    right:-33px;
                    bottom:20px;
                    .slider {
                        top: 12px;
                        left: 13px;
                        width: calc(100% - 26px);
                        height: calc(100% - 23px);
                    }
                }
             }
         }
     }
 }

@media only screen and (max-width: $layout-lg) {
    main.home {
        .introduction {
           .wrapper {
               .left {
                    margin-top: 30px;
                    margin-bottom: 0px;
               }
           }
        }
    }
}

@media only screen and (min-width: $layout-xl) {
    main.home {
        .introduction {
            .wrapper {
                &.grid-template {
                    grid-template-columns: 60% 40%;
                }

                .right {
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (min-width: $layout-lg) {
    main.home {
        a {
            padding: 6px 24px;
            height: 50px;
            font-size: $fs-accent;
            line-height: 24px;
        }
        .introduction {
            .wrapper {
                padding-top: 70px;

                .left {
                    text-align: left;
                    flex: 0 0 60%;
                    padding-right:20px;

                    h1 {
                        margin-bottom:50px;
                        font-size: $fs-xxhuge;
                        line-height: 1.18;

                        span {
                            &:nth-child(2) {
                                &:after {
                                    bottom: 10px
                                }
                            }
                        }

                        &:after {
                            bottom: 12px;
                        }
                    }
                    p {
                        font-size: $fs-accent;
                        line-height: 30px;
                    }

                }
                .animation {
                    flex:0 0 40%;
                }
            }
        }
        .experience {
            padding-top:50px;
            .experience-content {
               .experience-baseline {
                font-size:24px;
                }
                .experience-items {
                    display: flex;
                    flex-wrap: nowrap;
                    margin: 30px 0 50px;
                    .experience-item {
                        cursor: pointer;
                        margin-bottom: 10px;
                        padding: 10px;
                        height: 500px;
                        flex: 1;
                        transition: 0.6s;
                        overflow: hidden;
                        &:hover {
                            flex:4;
                            .experience-bg {
                                .experience-item-content {
                                    opacity: 1;
                                }
                                .experience-bigtitle {
                                    opacity: 0;
                                }
                            }
                        }
                        .experience-bg {
                            .experience-bigtitle {
                                display: block;
                                font-size: $fs-xhuge;
                                line-height: 42px;
                                color: $white;
                                z-index: 2;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                transition: opacity .1s ease-in;
                            }
                            .experience-item-content {
                                transition: all .2s ease-in;
                                width: 500px;
                                position: absolute;
                                left: 20px;
                                bottom: 20px;
                                opacity: 0;

                                .experience-title {
                                    .title1 {
                                        font-size: $fs-xhuge;
                                        line-height: 42px;
                                        color: $white;
                                    }
                                    .title2 {
                                        margin-bottom: 10px;
                                        font-size: $fs-huge;
                                        line-height: 38px;
                                        .point {
                                            width:14px;
                                            height: 14px;
                                        }
                                    }
                                }
                                .experience-description {
                                    display: block;
                                    margin-bottom: 25px;
                                    font-size: $fs-accent;
                                }
                            }
                        }
                        .experience-offer {
                            &:after {
                                transition: all .3s ease-in;
                            }
                        }
                        .experience-activated {
                            &:after {
                                transition: all .3s ease-in;
                            }
                        }
                        .experience-enjoy {
                            &:after {
                                transition: all .3s ease-in;
                            }
                        }
                    }
                }
            }
        }
        .promotionnal {
            .promotionnal-content {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .left-side{
                    flex: 0 0 50%;
                    margin:0
                }
                .right-side {
                    flex: 0 0 50%;
                    text-align: left;
                    .title-point {
                        text-align: left;
                        h2 {
                            text-align: left;
                            &:after {
                                bottom:10px;
                            }
                        }
                    }

                    .content {
                        margin-top:30px;
                        h3 {
                            font-size: $fs-huge;
                        }
                        p {
                            margin-top:20px;
                            font-size: $fs-accent;
                            line-height: 30px;
                        }
                        .typewriter {
                            margin:initial
                        }
                        ul {
                            text-align: left;
                        }
                    }
                    .buttons {
                        padding-top: 30px;
                        justify-content: space-between;
                        max-width: 500px;
                        .button-item {
                            margin: 5px 0;
                            width: inherit;
                        }
                    }
                }
            }
        }
        .how-it-works {
            padding: 90px 0;
            position: relative;
            .background-top {
                position: absolute;
                background-image: url(/assets/animation_background.svg);
                background-position: top left;
                background-repeat: no-repeat;
                background-size: contain;
                width: 50%;
                height: 700px;
                left:0;
                top:0
            }
            .background-bottom {
                position: absolute;
                width: 800px;
                height: 800px;
                left: -400px;
                top: 600px;
                background-color:$primary-color;
                border-radius: $radius-half;
                z-index: -1;
            }
            .works-wrapper {
                .steps {
                    justify-content: space-between;
                    .step-item {
                        flex: 0 0 calc((100% - 24px) / 3);
                        p {
                            font-size: $fs-xsmall;
                        }
                    }
                }
            }
            .testimonials {
                padding-top:150px;
                position: relative;

                .testimonials-wrapper {
                    margin-top:60px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .testimonials-logo {
                        margin-top:0px;
                        max-width: 400px;
                        margin-left: -50px;
                        .btn-secondary {
                            margin-top: 40px;
                        }
                    }
                    .testimonials-cards {
                        width: 700px;
                         overflow: hidden;
                         .mask {
                            display: flex;
                            height: 100%;
                            position: absolute;
                            width: 100%;
                            z-index: 1;
                            pointer-events: none;
                            max-width: 1440px;

                            .mask-right {
                                position: absolute;
                                width: 100px;
                                height: 100%;
                                right:0;
                                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
                            }
                        }
                         .cards-content {
                             width: calc(350px * 4);
                             .card-item {
                                 margin-right: 20px;
                                 max-width: 350px;
                             }
                         }
                    }
                 }
            }
            .support {
                h2 {
                    font-size: $fs-huge;
                    line-height: 1.16;
                }
                .logos {
                    flex:2;

                }
                img {
                    margin:10px;
                    max-width: 100px;
                }
            }
        }
    }
}


@keyframes topBubbles {
    0%{
      background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }
      50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;}
   100% {
      background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
    }
  }

  @keyframes bottomBubbles {
    0%{
      background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
    50% {
      background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;}
   100% {
      background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
    }
  }

#modal-onboarding,
#modal-operation {
    .modal-content {
        border-radius: $radius-accent;
        border:none;
        .modal-body {
            padding: 40px;
            .hideMe {
                display: none!important;
            }
            #step1 {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    max-width: 100px;
                }
                .step1-title {
                    margin: 20px 0;
                    font-size:40px;
                    font-weight: 800;
                    .title1,
                    .title3 {
                        text-transform: uppercase;
                    }
                    .title2 {
                        margin: 0 5px;
                    }
                    .title1,
                    .title2 {
                        color: black;
                    }
                    .title3 {
                        display: block;
                        color: $primary-color
                    }
                }
                .step1-content {
                    text-align: center;
                }
                #btnEndStep1 {
                    margin-top: 30px;
                    padding: 15px 64px;
                    background-color: $primary-color;
                    border-radius: $radius-full;
                    color: $white;
                    font-size: $fs-default;
                    font-weight: 800;
                    border:none;
                }
            }
            #step2 {

                .step2-title {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 30px;
                    border-bottom: 2px solid $primary-color;
                    .title {
                        font-size: $fs-huge;
                        margin-right: 30px;
                    }
                    img {
                        max-width: 70px;
                    }
                }
                .step2-items {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    .step2-form-item {
                        &:nth-child(2),
                        &:nth-child(3) {
                            flex: 1 1 auto;

                        }
                        &:last-child {
                            margin-top: 10px;
                        }
                        label {
                            font-weight: 800;
                            margin-bottom: 8px;
                        }
                    }
                }
                .step2-form-hobbies {
                    margin-top: 20px;
                    .section-title {
                        font-weight: 800;
                        margin-bottom: 8px;
                    }
                    .step2-hobbies-list {
                        display: flex;
                        flex-wrap: wrap;
                        .form-group {
                            margin: 5px 0;
                            flex: 0 0 50%;
                            padding-left: $margin-large;
                            [type="checkbox"] {
                                visibility: hidden;
                            }
                            [type="checkbox"]:not(:checked) + label::before,
                            [type="checkbox"]:checked + label::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 24px;
                                height: 24px;
                                border: 1px solid $primary-color;
                                background: $white;
                                border-radius: $radius-small;
                                box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
                                transition: all .275s;
                            }

                            [type="checkbox"]:not(:checked) + label::after,
                            [type="checkbox"]:checked + label::after {
                                content: '';
                                width: 14px;
                                height: 8px;
                                position: absolute;
                                top: 6px;
                                left: 5px;
                                border: 3px solid $primary-color;
                                border-top: none;
                                border-right: none;
                                background: transparent;
                                opacity: 0;
                                transform: rotate(-45deg);
                                transition: all .2s;
                            }

                            [type="checkbox"]:not(:checked) + label::after {
                                opacity: 0;
                                transform: scale(0) rotate(45deg);
                            }

                            [type="checkbox"]:checked + label::after {
                                opacity: 1;
                            }
                        }
                    }
                }
                .step2-actions {
                    margin-top: 30px;
                    text-align: right;
                    .btn {
                        padding:10px 34px;
                    }
                    #btnEndStep2 {
                        margin-left: 20px;
                        border:none;
                        background-color: transparent;
                        color:$grey-button;
                        font-size:16px;
                        font-weight: 800;
                    }
                }
            }
            #step3 {
                .step3-title {
                    margin-bottom: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 30px;
                    border-bottom: 2px solid $primary-color;
                    .title {
                        font-size: $fs-huge;
                        margin-right: 30px;
                        text-align: center;
                    }
                    img {
                        max-width: 70px;
                    }
                }
                .btn {
                    display: block;
                    margin: 0 auto;
                    padding:10px 34px;
                }
                .step3-items {
                    display: flex;
                    flex-wrap: wrap;
                    .step3-item {
                        margin-bottom: 20px;
                        padding: 20px 10px;
                        flex: 0 1 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: space-between;
                        box-shadow: rgba(0, 0, 0 , 0.06) 0px 8px 32px;
                        border-radius: $radius-default;
                        .step3-item-header {
                            margin-bottom: 12px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            .step3-item-title {
                                margin-top: 8px;
                                font-weight: 800;
                            }
                        }
                        .step3-item-description {
                            text-align: center;
                        }
                        &:first-child {
                            .step3-item-title {
                                color: $primary-color;
                            }
                        }
                        &:nth-child(2) {
                            .step3-item-title {
                                color: $secondary-color;
                            }
                        }
                        &:last-child {
                            .step3-item-title {
                                color: #383636;
                            }
                            .btn {
                                background-color: #383636;
                                border-color: #383636;
                                color: $white;
                                &:hover {
                                    background-color: #999;
                                    border-color: #999;
                                }
                            }
                        }
                    }
                }
                #btnEndStep3 {
                    float: right;
                    margin-top: 30px;
                    margin-right: 10px;
                    border:none;
                    background-color: transparent;
                    color:$grey-button;
                    font-size:16px;
                    font-weight: 800;
                }
            }
        }
    }
}

@media only screen and (min-width: $layout-lg) {
    #modal-onboarding,
    #modal-operation {
        .modal-content {
            .modal-body {
                #step2 {
                    .step2-items {
                        .step2-form-item {
                            &:nth-child(2),
                            &:nth-child(3) {
                                margin: 0 10px;

                            }
                        }
                    }
                }
                #step3 {
                    .step3-items {
                        .step3-item {
                            margin-bottom: 0;
                            padding: 0 10px;
                            flex: 0 1 33%;
                            border-bottom: none;
                            border-radius: 0;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}